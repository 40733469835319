import React from 'react';
import { MenuOutlined } from '@ant-design/icons';
import { arrayMoveImmutable } from 'array-move';
import { EditorState } from 'draft-js';
import moment from 'moment';
import {
  Button,
  Input,
  Select,
  Checkbox,
  DatePicker,
  Modal,
  Table,
  Radio,
  Descriptions,
  Typography,
  Space,
  Collapse,
  Divider,
  Row,
  Col,
  Upload,
  Form,
  Spin,
} from 'antd';
import produce from 'immer';
import { shallowEqual, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import * as XLSX from 'xlsx';
import Postcode from '@actbase/react-daum-postcode';
import CategorySelectItem from '#components/CategorySelectItem';
import InputMoney from '#components/Input/InputMoney';
import Editor, { draftToHTML, HTMLToDraft } from '#components/Editor';
import FileUpload from '#components/datalist/FileUpload';
import handleError from '#utils/handleError';
import { getFileHost, restApi } from '#apis/index';
import formatComma from '#utils/formatComma';
import useFetch from '#hooks/useFetch';
import usePreventLeave from '#hooks/usePreventLeave';
import RequiredOrNotLabel from '#components/RequiredOrNotLabel';
import { downloadExcel } from '../../../utils/downloadExcel';
import { checkBeforeUpload } from '#utils/beforeUpload';

const CATEGORY_TYPE = {
  SEARCH: 'Search',
  SELECT: 'Select',
};

const DISCOUNT_TYPE = {
  AMOUNT: 'AMOUNT',
  RATE: 'RATE',
  NONE: 'NONE',
};

const SURTAX = {
  TAXATION: 'VAT001',
  EXEMPTION: 'VAT002',
  SMALL: 'VAT003',
};

const OPTION_TYPE = {
  SELF: 'SELF',
  EXCEL: 'EXCEL',
};

const OPTION_CONFIG_TYPE = {
  EXCLUSIVE: 'POT001',
  COMBINATION: 'POT002',
  NONE: 'POT003',
};

const OPTION_ORDER_TYPE = {
  CREATED_AT: 'CREATED_AT',
  NAME: 'NAME',
  LOW_PRICE: 'LOW_PRICE',
  HIGH_PRICE: 'HIGH_PRICE',
};

const DELIVERY_TYPE = {
  PARCEL: '택배',
  SELF: '직접배송',
};

const DELIVERY_METHOD_TYPE = {
  DEFAULT: 'DEFAULT',
};

const DELIVERY_PRICE_TYPE = {
  FREE: 'DFT001',
  CHARGED: 'DFT002',
  CONDITIONALLY_FREE: 'DFT003',
  BY_COUNT: 'DFT004',
};

const DELIVERY_PAYMENT_TYPE = {
  COLLECTIBLE: 'DPT001',
  ADVANCE: 'DPT002',
};

function formatInputOptionsToExclusiveOptions(options) {
  return options
    .reduce((p, c) => {
      p.push(...c.value?.split(',').map((value) => ({ name: c.name, value: value.trim(), isActive: false })));
      return p;
    }, [])
    ?.filter((item) => item?.name !== 'optionId');
}

function formatExclusiveOptionsToCombinationOptions(options) {
  return Object.entries(
    options.reduce((p, c) => {
      if (p[c.name]) {
        p[c.name].push(c.value);
      } else {
        p[c.name] = [c.value];
      }
      return p;
    }, {}),
  )
    .reduce((p, [k, v], i) => {
      const a = [...p];
      if (p.length > 0) {
        a.forEach((e, i2) => {
          p.splice(
            i2 * v.length,
            i,
            ...v.map((e2) => {
              return { ...e, [k]: e2 };
            }),
          );
        });
      } else {
        v.forEach((e) => {
          p.push({ [k]: e, count: 0, price: 0, isActive: false });
        });
      }
      return p;
    }, [])
    ?.filter((item) => item?.name !== 'optionId');
}

function formatCombinationToExclusiveOptions(options) {
  return options
    .reduce((p, { count, price, isActive, ...rest }) => {
      Object.entries(rest).forEach(([key, value]) => {
        const arr = p.filter(({ name }) => name === key);
        if (arr.length > -1) {
          if (arr.findIndex((e) => e.value === value) > -1) {
          } else {
            p.push({ name: key, value });
          }
        } else {
          p.push({ name: key, value });
        }
      });
      return p;
    }, [])
    ?.filter((item) => item?.name !== 'optionId')
    .sort((a, b) => (a.name > b.name ? 1 : a.name < b.name ? -1 : 0));
}

function formatExclusiveOptionsToInputOptions(options) {
  return options
    .reduce((p, c) => {
      if (c.name) {
        const _index = p.findIndex((e) => e.name === c.name);
        if (_index > -1) {
          p[_index].value = `${p[_index].value}, ${c.value}`;
        } else {
          p.push({ name: c.name, value: c.value });
        }
      }
      return p;
    }, [])
    ?.filter((item) => item?.name !== 'optionId');
}

function formatCombinationOptionsToInputOptions(options) {
  return formatExclusiveOptionsToInputOptions(formatCombinationToExclusiveOptions(options));
}

const Categories = React.memo(({ category, onChangeCategory }) => {
  const { tree: categoriesTree } = useSelector((selector) => selector?.categories?.product, shallowEqual);
  const [selectedCategories, setSelectedCategories] = React.useState([]);

  React.useEffect(() => {
    // if (selectedCategories[selectedCategories.length - 1]?.code !== category) {
      setSelectedCategories(() => {
        const arr = [];
        function fn(c) {
          if (category?.startsWith(c?.code)) {
            arr.push(c);
            if (c?.items?.length > 0) {
              for (let i = 0; i < c.items.length; i++) {
                const item = c.items[i];
                if (fn(item)) {
                  return;
                }
              }
            }
            return true;
          }
          return false;
        }
        (() => {
          const items = Object.values(categoriesTree);
          for (let i = 0; i < items.length; i++) {
            const item = items[i];
            if (fn(item)) {
              return;
            }
          }
        })();
        return arr;
      });
    // }
  }, [category]);

  return (
    <Row gutter={0} wrap={false} style={{ border: '1px solid #cccccc', width: '100%', overflow: 'auto' }}>
      <Col style={{ borderRight: '1px solid #cccccc' }}>
        <Space direction="vertical" size={0}>
          <Typography.Paragraph style={{ textAlign: 'center', marginBottom: 5, marginTop: 5 }}>
            카테고리
          </Typography.Paragraph>
          <Space direction="vertical" style={{ height: 280, overflow: 'auto' }}>
            {Object.values(categoriesTree).map((category) => {
              const isActive = selectedCategories[0]?.code === category?.code;
              return (
                <CategorySelectItem
                  key={category?.code}
                  selected={isActive}
                  onSelect={() => {
                    setSelectedCategories([category]);
                    // if (!(category?.items?.length > 0)) {
                      onChangeCategory(category);
                    // }
                  }}
                  isShowIcon={category?.items?.length > 0}
                >
                  {category?.label}
                </CategorySelectItem>
              );
            })}
          </Space>
        </Space>
      </Col>
      {selectedCategories?.map((category, index, array) => {
        if (!(category?.items?.length > 0)) {
          return null;
        }
        return (
          <Col key={category?.code} style={index < array?.length ? { borderRight: '1px solid #cccccc' } : {}}>
            <Space direction="vertical" size={0}>
              <Typography.Paragraph style={{ textAlign: 'center', marginBottom: 5, marginTop: 5 }}>
                {category?.label}
              </Typography.Paragraph>
              <Space direction="vertical" style={{ height: 280, overflow: 'auto' }}>
                {category?.items?.map((category) => {
                  return (
                    <CategorySelectItem
                      key={category?.code}
                      selected={selectedCategories[index + 1]?.code === category?.code}
                      onSelect={() => {
                        setSelectedCategories(
                          produce((draft) => {
                            draft[index + 1] = category;
                            draft.splice(index + 2, draft.length - index + 1);
                          }),
                        );
                        // if (!(category?.items?.length > 0)) {
                          onChangeCategory(category);
                        // }
                      }}
                      isShowIcon={category?.items?.length > 0}
                    >
                      {category?.label}
                    </CategorySelectItem>
                  );
                })}
              </Space>
            </Space>
          </Col>
        );
      })}
    </Row>
  );
});

const SortableTable = React.memo(({ dataSource: initialDataSource = [], columns = [], onOk, onCancel }) => {
  const [dataSource, setDataSource] = React.useState(initialDataSource.map((e, index) => ({ ...e, index })));
  const DragHandle = React.useMemo(
    () => SortableHandle(() => <MenuOutlined style={{ cursor: 'grab', color: '#999' }} />),
    [],
  );
  const SortableItem = React.useMemo(() => SortableElement((props) => <tr {...props} />), []);
  const SortableBody = React.useMemo(() => SortableContainer((props) => <tbody {...props} />), []);

  return (
    <Space direction="vertical" style={{ width: '100%' }}>
      <Table
        pagination={false}
        dataSource={dataSource}
        columns={[
          {
            title: '정렬',
            width: 45,
            render: () => <DragHandle />,
          },
          ...columns,
        ]}
        rowKey="index"
        components={{
          body: {
            wrapper: (props) => (
              <SortableBody
                useDragHandle
                disableAutoscroll
                helperClass="row-dragging"
                onSortEnd={({ oldIndex, newIndex }) => {
                  if (oldIndex !== newIndex) {
                    setDataSource(arrayMoveImmutable([].concat(dataSource), oldIndex, newIndex).filter((e) => !!e));
                  }
                }}
                {...props}
              />
            ),
            row: ({ className, style, ...props }) => {
              return <SortableItem index={dataSource.findIndex((e) => e.index === props['data-row-key'])} {...props} />;
            },
          },
        }}
      />
      <Row justify="end" style={{ marginBottom: -15 }}>
        <Col>
          <Space>
            <Button
              onClick={() => {
                Modal.destroyAll();
                onCancel?.(dataSource);
              }}
            >
              취소
            </Button>
            <Button
              type="primary"
              onClick={() => {
                Modal.destroyAll();
                onOk?.(dataSource);
              }}
            >
              저장
            </Button>
          </Space>
        </Col>
      </Row>
    </Space>
  );
});

function GoodsItemsCreate() {
  const params = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const productId = React.useMemo(() => params?.productId, [params?.productId]);
  const isEdit = React.useMemo(
    () => location.pathname.split('/').includes('modify') && Boolean(productId),
    [location, productId],
  );
  const isCopy = React.useMemo(
    () => location.pathname.split('/').includes('copy') && Boolean(productId),
    [location, productId],
  );

  const [defaultOptionValuesWhenEdit, setDefaultOptionValuesWhenEdit] = React.useState(null);

  const product = useFetch();
  const { tree: categoriesTree } = useSelector((selector) => selector?.categories?.product ?? {}, shallowEqual);
  const { tree: codesTree } = useSelector((selector) => selector?.codes ?? {}, shallowEqual);
  const { enablePrevent, disablePrevent } = usePreventLeave();
  const initialForm = React.useMemo(
    () => ({
      partner: null,
      tags: [],
      isDirectPurchase: null,
      isQuotation: null,
      isNotReturn: null,
      isNotExchangeable: null,
      isNotCancel: null,
      price: null,
      discountRate: null,
      discountAmount: null,
      count: null,
      productName: '',
      category: '',
      categoryType: CATEGORY_TYPE.SEARCH,
      discountType: DISCOUNT_TYPE.RATE,
      surtax: SURTAX.TAXATION,
      isOption: false,
      isSetSalesPeriod: null,
      optionType: OPTION_TYPE.SELF,
      optionConfigType: OPTION_CONFIG_TYPE.EXCLUSIVE,
      optionOrderType: OPTION_ORDER_TYPE.CREATED_AT,
      inputOptions: new Array(1).fill({ name: '', value: '' }),
      options: [],
      optionsSelectedRowKeys: [],
      isOptionsActive: true,
      isOptionsPriceAdd: true,
      optionsPrice: '',
      optionsCount: '',
      images: [],
      additionImages: [],
      detailImages: [],
      details: EditorState.createEmpty(),
      detailsInfo: EditorState.createEmpty(),
      manufacturingAt: null,
      isCustom: false,
      custom: '',
      productInformationAnnouncement: EditorState.createEmpty(),
      template: null,
      templateName: '',
      isTemplateSave: false,
      deliveryType: DELIVERY_TYPE.PARCEL,
      isVisit: false,
      isQuick: false,
      deliveryDetails: '',
      deliveryMethodType: DELIVERY_METHOD_TYPE.DEFAULT,
      isCheckOrder: false,
      deliveryDate: null,
      deliveryPriceType: DELIVERY_PRICE_TYPE.FREE,
      deliveryCompany: null,
      isDefaultDeliveryCompany: false,
      deliveryPrice: '',
      deliveryPriceSum: null,
      deliveryPriceRepeat: null,
      deliveryPaymentType: '',
      deliverySpecialPrice: '',
      isInstallationAmount: null,
      forwardingLocation: null,
      returnDeliveryCompany: null,
      returnDeliveryPrice: '',
      exchangeDeliveryPrice: '',
      asPhone: '',
      asInfo: '',
      sellerDetails: '',
      sellerAddr: null,
      returnAddr: null,
      startAt: moment().format('YYYY-MM-DD'),
      endAt: moment().add(60, 'day').format('YYYY-MM-DD'),
      isDiscount: null,
      returnInfo: '',
      modelName: '',
      brand: '',
      manufacturer: '',
      isKc: true,
    }),
    [],
  );
  const [form, setForm] = React.useState(initialForm);
  const template = useFetch();
  const submit = useFetch();

  const categoriesOptions = React.useMemo(
    () =>
      Object.values(categoriesTree)?.reduce((p, c) => {
        function fn(x, y) {
          const s = `${y} > ${x.label}`;
          if (x?.items?.length > 0) {
            x.items.forEach((a) => fn(a, s));
            return;
          }
          p.push({ label: s, value: x.code });
        }
        fn(c, c.label);
        return p;
      }, []) ?? [],
    [categoriesTree],
  );
  const [searchValue, setSearchValue] = React.useState('');
  const searchValueRegExp = React.useMemo(() => new RegExp(`(${searchValue})`, 'g'), [searchValue]);
  const [discountPrice, discount] = React.useMemo(() => {
    switch (form.discountType) {
      case DISCOUNT_TYPE.AMOUNT:
        return [form.price - form.discountAmount || 0, form.discountAmount];
      case DISCOUNT_TYPE.RATE:
        const _discountPrice = Math.floor((form.price * (1 - form.discountRate / 100)) / 10) * 10;
        return [_discountPrice ?? 0, form.price - _discountPrice];
      default:
        return [0, 0];
    }
  }, [form.discountType, form.price, form.discountAmount, form.discountRate]);
  const selectedCategoryLabel = React.useMemo(
    () => form?.category && categoriesOptions?.find(({ value }) => value === form?.category)?.label,
    [form?.category, categoriesOptions],
  );
  const depth = React.useMemo(()=>{
    if(!form?.category||!categoriesTree) return;
    let str = '';
    function fn (x){
      const d = x.find((item)=>
          form?.category.startsWith(item.code)
      );
      if(d){
        if(str.length===0){
          str = `${d.label} > ${d.label}`
        }else {
          str = `${str} > ${d.label}`
        }
        // if(d.items.length>0){
        return fn(d.items)
        // }
      }else {
        return str;
      }
    }
    return fn(categoriesTree)
  },[categoriesTree,form?.category])
  const optionsCount = React.useMemo(() => form.options?.reduce((p, c) => p + c.count, 0), [form.options]);
  const templates = useFetch([]);
  const templatesOptions = React.useMemo(
    () => templates.data.map((template) => ({ label: template.name, value: template.id })),
    [templates.data],
  );
  const deliveryCompanyOptions = React.useMemo(
    () => codesTree?.DV?.items?.map(({ code, label }) => ({ label, value: code })) ?? [],
    [codesTree],
  );
  const disabledOptionsApplyButton = React.useMemo(
    () => form.inputOptions.some(({ name, value }) => !name || !value),
    [form.inputOptions],
  );
  const init = React.useCallback(async () => {
    enablePrevent();
    setForm(initialForm);

    // TODO :: 나중에 임시저장한 데이터 어떻게 진행되는지 기획 정해지면 아래 코드 완성시키기
    // const checkTempResponse = await restApi.get('partners/products/save');

    if (isEdit || isCopy) {
      product
        .fetch(restApi.get(`/partners/products/${productId}`))
        .then(({ data: responseData }) => {
          const options =
            responseData.optionTypeCode === OPTION_CONFIG_TYPE.EXCLUSIVE
              ? responseData.singleOptions.map(({ optionId, optionGroup, optionName, isActive, count }) => ({
                  name: optionGroup,
                  value: optionName,
                  isActive,
                  count,
                  optionId,
                }))
              : responseData.optionTypeCode === OPTION_CONFIG_TYPE.COMBINATION
              ? responseData.combinationOptions.map(
                  ({ optionId, extra1, extra2, extra3, count, addPrice, isActive }) => ({
                    ...responseData.combineOptionKeys?.reduce(
                      (p, c, i) => ({ ...p, [c]: [extra1, extra2, extra3][i] }),
                      {},
                    ),
                    count,
                    price: addPrice,
                    isActive,
                    optionId,
                  }),
                )
              : [];
          const inputOptions =
            responseData.optionTypeCode === OPTION_CONFIG_TYPE.EXCLUSIVE
              ? formatExclusiveOptionsToInputOptions(options)
              : responseData.optionTypeCode === OPTION_CONFIG_TYPE.COMBINATION
              ? formatCombinationOptionsToInputOptions(options)
              : [];

          setDefaultOptionValuesWhenEdit({ optionTypeCode: responseData.optionTypeCode, options });

          setForm((prevState) => ({
            ...prevState,
            partner: responseData.store,
            tags: responseData.tags?.map((tag) => `#${tag}`) ?? [],
            isDirectPurchase: responseData.isDirect,
            isQuotation: responseData.isQuotation,
            isNotReturn: responseData.isReturnable !== null ? !responseData.isReturnable : null,
            isNotExchangeable: responseData.isNotExchangeable !== null ? !responseData.isNotExchangeable : null,
            isNotCancel: responseData.isRefundable !== null ? !responseData.isRefundable : null,
            price: responseData.price,
            discountRate: responseData.discountRate,
            discountAmount: responseData.discountAmount,
            count: responseData.count,
            productName: responseData.productName,
            category: responseData.categoryCode,
            categoryType: responseData.categorySelectType,
            discountType: responseData.discountType === 'NONE' ? DISCOUNT_TYPE.RATE : responseData.discountType,
            surtax: responseData.vatCode,
            isOption: responseData.optionTypeCode !== OPTION_CONFIG_TYPE.NONE,
            isSetSalesPeriod: responseData.hasSalesPeriod,
            optionType: OPTION_TYPE.SELF,
            optionConfigType: responseData.optionTypeCode !== OPTION_CONFIG_TYPE && responseData.optionTypeCode,
            optionOrderType: responseData.optionOrderType,
            inputOptions,
            options,
            optionsSelectedRowKeys: [],
            isOptionsActive: true,
            isOptionsPriceAdd: true,
            optionsPrice: '',
            optionsCount: '',
            images: responseData.thumbnail ? [responseData.thumbnail] : [],
            additionImages: responseData.images ?? [],
            detailImages: responseData.detailImages ?? [],
            details: HTMLToDraft(responseData.introduction),
            detailsInfo: HTMLToDraft(responseData.detailIntroduction),
            manufacturingAt: responseData.manufacturingAt ? moment(responseData.manufacturingAt) : null,
            isCustom: responseData.isCustomize,
            custom: responseData.customizeInfo,
            productInformationAnnouncement: HTMLToDraft(responseData.productInformationAnnouncement),
            template: responseData.template,
            templateName: responseData.templateName,
            isTemplateSave: responseData.isSaveTemplate,
            deliveryType: responseData.methods,
            isVisit: responseData.isVisit,
            isQuick: responseData.hasQuick,
            deliveryDetails: responseData.deliveryInfo,
            deliveryMethodType: DELIVERY_METHOD_TYPE.DEFAULT,
            isCheckOrder: responseData.isMakeAfterOrder,
            deliveryDate: responseData.periods,
            deliveryPriceType: responseData.deliveryFreeTypeCode,
            deliveryCompany: responseData.courierCode,
            isDefaultDeliveryCompany: responseData.isDefaultDeliveryCompany,
            deliveryPrice: responseData.deliveryAmount,
            deliveryPriceSum: responseData.deliveryFreeAmount,
            deliveryPriceRepeat: responseData.deliveryFreeCount,
            deliveryPaymentType: responseData.deliveryPaymentTypeCode,
            deliverySpecialPrice: responseData.extraAmount,
            isInstallationAmount: responseData.isInstallationAmount,
            forwardingLocation: responseData.cargoAddress
              ? { jibunAddress: responseData.cargoAddress, zonecode: responseData.cargoZipcode }
              : null,
            returnDeliveryCompany: responseData.refundCourierCode,
            returnDeliveryPrice: responseData.refundDeliveryAmount,
            exchangeDeliveryPrice: responseData.changeDeliveryAmount,
            asPhone: responseData.asPhone,
            asInfo: responseData.asGuide,
            sellerDetails: responseData.significant,
            sellerAddr: responseData.sellerAddress
              ? {
                  jibunAddress: responseData.sellerAddress,
                  zonecode: responseData.sellerZipcode,
                }
              : null,
            sellerZipcode: responseData.sellerZipcode,
            returnAddr: responseData.refundAddress
              ? {
                  jibunAddress: responseData.refundAddress,
                  zonecode: responseData.refundZipcode,
                }
              : null,
            startAt: responseData.startAt ? moment(responseData.startAt) : null,
            endAt: responseData.endAt ? moment(responseData.endAt) : null,
            isDiscount: responseData.discountType !== DISCOUNT_TYPE.NONE,
            returnInfo: responseData.returnInfo,
            modelName: responseData.modelName,
            brand: responseData.brand,
            manufacturer: responseData.manufacturer,
            isKc: responseData.isKc,
          }));
        })
        .catch(handleError);
    }

    templates
      .fetch(
        restApi
          .get('/templates', { params: { limit: 100 } })
          .then(({ data, ...props }) => ({ ...props, data: data.results })),
      )
      .catch(handleError);
  }, [isEdit, isCopy]);

  const handleCancel = React.useCallback(() => {
    Modal.confirm({
      title: '알림',
      content: '입력했던 내용이 전부 사라집니다.\n계속 하시겠습니까?',
      centered: true,
      onOk: () => {
        navigate(-1);
      },
    });
  }, []);

  // const handleTempSave = React.useCallback(async () => {
  //   try {
  //     const { data: responseData } = await submit.fetch(
  //       restApi.post('/partners/products/save', {
  //         categorySelectType: form?.categoryType,
  //         categoryCode: form.category,
  //         productName: form.productName,
  //         tags: form.tags.map((value) => value.substring(1)),
  //         isDirect: form.isDirectPurchase,
  //         isQuotation: form.isQuotation,
  //         isReturnable: form.isNotReturn !== null ? !form.isNotReturn : null,
  //         isExchangeable: form.isNotExchangeable !== null ? !form.isNotExchangeable : null,
  //         isRefundable: form.isNotCancel !== null ? !form.isNotCancel : null,
  //         price: form.price,
  //         discountType: form.isDiscount ? form.discountType : DISCOUNT_TYPE.NONE,
  //         discountRate: form.discountRate,
  //         discountAmount: form.discountAmount,
  //         hasSalesPeriod: form.isSetSalesPeriod,
  //         startAt: moment(form.startAt).format('YYYY-MM-DD'),
  //         endAt: moment(form.endAt).format('YYYY-MM-DD'),
  //         vatCode: form.surtax,
  //         count: form.isOption ? 0 : form.count,
  //         optionTypeCode:
  //           form.isOption !== null ? (form.isOption ? form.optionConfigType : OPTION_CONFIG_TYPE.NONE) : null,
  //         optionOrderType: form.optionOrderType,
  //         singleOptions:
  //           form.optionConfigType === OPTION_CONFIG_TYPE.EXCLUSIVE
  //             ? form.options.map(({ name, value, isActive }) => ({
  //                 optionGroup: name,
  //                 optionName: value,
  //                 isActive,
  //               }))
  //             : null,
  //         combinationOptions:
  //           form.optionConfigType === OPTION_CONFIG_TYPE.COMBINATION
  //             ? form.options.map(({ count, price, isActive, ...restOptions }) => ({
  //                 ...Object.entries(restOptions).reduce(
  //                   (p, [key, value], i) => ({
  //                     ...p,
  //                     [`extra${i + 1}`]: value,
  //                   }),
  //                   {},
  //                 ),
  //                 count,
  //                 addPrice: price,
  //                 isActive,
  //               }))
  //             : null,
  //         combineOptionKeys:
  //           form.optionConfigType === OPTION_CONFIG_TYPE.COMBINATION
  //             ? Object.keys(form.options[0]).filter((key) => !['count', 'price', 'isActive'].includes(key))
  //             : null,
  //         thumbnail: form.images[0] || null,
  //         images: form.additionImages?.map((image) => image) ?? [],
  //         detailImages: form.detailImages || [],
  //         introduction: draftToHTML(form.details),
  //         detailIntroduction: draftToHTML(form.detailsInfo),
  //         manufacturingAt: form.manufacturingAt ? moment(form.manufacturingAt).format('YYYY-MM-DD') : null,
  //         isCustomize: form.isCustom,
  //         customizeInfo: form.custom,
  //         productInformationAnnouncement: draftToHTML(form.productInformationAnnouncement),
  //         methods: form.deliveryType,
  //         isVisit: form.isVisit,
  //         sellerAddress: form.isVisit ? form.sellerAddr?.jibunAddress ?? '' : null,
  //         sellerZipcode: form.isVisit ? form.sellerAddr?.zonecode ?? '' : null,
  //         template: form.template ? form.template : null,
  //         isSaveTemplate: form.isTemplateSave,
  //         templateName: form.templateName,
  //         hasQuick: form.isQuick,
  //         deliveryInfo: form.deliveryDetails,
  //         isMakeAfterOrder: form.isCheckOrder,
  //         periods: form.deliveryDate,
  //         deliveryFreeTypeCode: form.deliveryPriceType,
  //         extraAmount: form.deliverySpecialPrice,
  //         deliveryPaymentTypeCode: form.deliveryPaymentType,
  //         courierCode: form.deliveryCompany,
  //         refundCourierCode: form.returnDeliveryCompany,
  //         isInstallationAmount: form.isInstallationAmount,
  //         cargoAddress: form.forwardingLocation?.jibunAddress || form.forwardingLocation?.address,
  //         cargoZipcode: form.forwardingLocation?.zonecode,
  //         deliveryAmount: form.deliveryPriceType !== DELIVERY_PRICE_TYPE.FREE ? form.deliveryPrice : null,
  //         deliveryFreeCount: form.deliveryPriceType === DELIVERY_PRICE_TYPE.BY_COUNT ? form.deliveryPriceRepeat : null,
  //         deliveryFreeAmount:
  //           form.deliveryPriceType === DELIVERY_PRICE_TYPE.CONDITIONALLY_FREE ? form.deliveryPriceSum : null,
  //         refundDeliveryAmount: form.returnDeliveryPrice,
  //         changeDeliveryAmount: form.exchangeDeliveryPrice,
  //         refundAddress: form.returnAddr?.jibunAddress || form.returnAddr?.address,
  //         refundZipcode: form.returnAddr?.zonecode,
  //         asPhone: form.asPhone,
  //         asGuide: form.asInfo,
  //         significant: form.sellerDetails,
  //       }),
  //     );
  //     if (responseData) {
  //       Modal.info({
  //         title: '알림',
  //         content: '상품을 임시저장 했습니다.',
  //         centered: true,
  //       });
  //     }
  //   } catch (error) {
  //     handleError(error);
  //   }
  // }, []);

  // console.log('form.options :::: ', form);

  const handleSubmit = async (isTemp) => {
    if (isTemp) {
      function exception(error) {
        Modal.warn({
          title: '알림',
          content: error,
          centered: true,
        });
      }

      if (form.isTemplateSave) {
        if (!form.templateName) return exception('템플릿을 저장할 경우 템플릿 이름을 입력해야 합니다.');
      }

      // 카테고리
      if (!form.category) return exception('카테고리를 입력하세요.');

      // 상품명
      if (!form.productName) return exception('상품명을 입력하세요.');

      // 해시태그
      if (!(form.tags.length > 0)) return exception('해시태그를 1개 이상 입력하세요.');

      // 판매방식
      if (!form.isDirectPurchase && !form.isQuotation) return exception('판매방식을 선택하세요.');

      // 교환환불정책
      // if (!form.isNotExchangeable && !form.isNotReturn && !form.isNotCancel)
      //   return exception('교환환불정책을 선택하세요.');

      // 판매가
      if (!(form.price > 0)) return exception('판매가를 1원 이상 입력하세요.');

      // 할인 설정 되어있을때
      if (form.isDiscount) {
        switch (form.discountType) {
          case DISCOUNT_TYPE.AMOUNT:
            if (!(form.discountAmount > 0)) return exception('할인가를 입력하세요.');
            break;
          case DISCOUNT_TYPE.RATE:
            if (!(form.discountRate > 0)) return exception('할인율을 입력하세요.');
            break;
          default:
        }
      }

      // 판매기간
      if (form.isSetSalesPeriod === null) return exception('판매기간을 선택하세요.');

      // 판매기간 설정 되어있을때
      if (form.isSetSalesPeriod) {
        if (!form.startAt || !form.endAt) return exception('판매기간을 설정하세요.');
      }

      // 부가세
      if (!form.surtax) return exception('부가세를 선택하세요.');

      // 옵션 선택되어있지 않을때
      if (form.isOption === false) {
        if (!(form.count > -1)) return exception('재고수량을 입력하세요.');
      }

      // 옵션 설정되어있을때
      if (form.isOption === true) {
        if (!(form.options.length > 0)) return exception('옵션을 등록하세요.');
        for (let i = 0; i < form.options.length; i++) {
          const option = form.options[i];
          switch (form.optionConfigType) {
            case OPTION_CONFIG_TYPE.EXCLUSIVE:
              if (!option.name) return exception(`옵션 ${i + 1}번째 옵션명을 입력하세요.`);
              if (!option.value) return exception(`옵션 ${i + 1}번째 옵션값을 입력하세요.`);
              break;
            case OPTION_CONFIG_TYPE.COMBINATION:
              const { price, count, isActive, ...restOptions } = option ?? {};
              const restOptionsArr = Object.entries(restOptions);
              for (let j = 0; j < restOptionsArr.length; j++) {
                const [key, value] = restOptionsArr[j];
                if (!value) return exception(`옵션 ${i + 1}번째 ${key}의 옵션값을 입력하세요.`);
              }
              break;
            default:
          }
        }
      }

      // 대표이미지
      if (form.images.length !== 1) return exception('상품 대표이미지를 등록하세요.');

      if (form.modelName === '') return exception('모델명을 입력하세요.');
      if (form.brand === '') return exception('브랜드를 입력하세요.');
      if (form.manufacturer === '') return exception('제조사를 입력하세요.');
      if (typeof form.isKc !== 'boolean') return exception('kc 인증여부를 선택하세요.');

      // 방문수령
      if (form.isVisit === true) {
        if (!form.sellerAddr) return exception('판매자 주소를 등록하세요.');
      }

      // 택배사
      if (!form.deliveryCompany) return exception('배송 택배사를 선택하세요.');

      // 상품별 배송비
      switch (form.deliveryPriceType) {
        // 무료
        case DELIVERY_PRICE_TYPE.FREE:
          break;
        // 유료
        case DELIVERY_PRICE_TYPE.CHARGED:
          if (form.deliveryPrice === '') return exception('기본 배송비를 입력하세요.');
          if (form.deliveryPaymentType === '') return exception('결제방식을 선택하세요.');
          break;
        // 조건부 무료
        case DELIVERY_PRICE_TYPE.CONDITIONALLY_FREE:
          if (form.deliveryPrice === '') return exception('기본 배송비를 입력하세요.');
          if (form.deliveryPriceSum === '') return exception('배송비 조건을 입력하세요.');
          if (form.deliveryPaymentType === '') return exception('결제방식을 선택하세요.');
          break;
        // 수량별
        case DELIVERY_PRICE_TYPE.BY_COUNT:
          if (form.deliveryPrice === '') return exception('기본 배송비를 입력하세요.');
          if (form.deliveryPriceRepeat === '') return exception('배송비 조건을 입력하세요.');
          if (form.deliveryPaymentType === '') return exception('결제방식을 선택하세요.');
          break;
      }

      // 제주/도서상간 추가배송비
      if (form.deliverySpecialPrice === '') return exception('제주/도서상간 추가배송비를 입력하세요.');

      // 별도 설치비
      if (form.isInstallationAmount === null) return exception('별도 설치비를 선택하세요.');

      // 출고지
      if (
        !form.forwardingLocation?.jibunAddress &&
        !form.forwardingLocation?.address &&
        !form.forwardingLocation?.roadAddress
      )
        return exception('출고지를 선택하세요.');

      // 반품/교환 택배사
      if (!form.returnDeliveryCompany) return exception('반품 택배사를 선택하세요.');

      // 반품 배송비(편도)
      if (form.returnDeliveryPrice === '') return exception('반품 배송비(편도)를 입력하세요.');

      // 교환 배송비(편도)
      if (form.exchangeDeliveryPrice === '') return exception('교환 배송비(편도)를 입력하세요.');

      // 반품/교환지
      if (!form.returnAddr?.jibunAddress && !form.returnAddr?.address && !form.returnAddr?.roadAddress)
        return exception('반품/교환지를 입력하세요.');

      if (form.returnInfo === '') return exception('반품교환 안내를 입력하세요.');

      // A/S 전화번호
      if (form.asPhone === '') return exception('A/S 전화번호를 입력하세요.');

      // A/S 안내
      if (form.asInfo === '') return exception('A/S 안내정보를 입력하세요.');
    }

    try {
      const fetch = (data) =>
        isEdit ? restApi.put(`/partners/products/${productId}`, data) : restApi.post('/partners/products', data);

      const { data: responseData } = await submit.fetch(
        fetch({
          categorySelectType: String(form.categoryType),
          categoryCode: String(form.category),
          productName: String(form.productName),
          tags: form.tags.map((value) => value.substring(1)),
          isDirect: Boolean(form.isDirectPurchase),
          isQuotation: Boolean(form.isQuotation),
          isReturnable: Boolean(!form.isNotReturn),
          isExchangeable: Boolean(!form.isNotExchangeable),
          isRefundable: Boolean(!form.isNotCancel),
          price: Number(form.price),
          discountType: String(form.isDiscount ? form.discountType : DISCOUNT_TYPE.NONE),
          discountRate: Number(form.discountRate),
          discountAmount: Number(form.discountAmount),
          hasSalesPeriod: Boolean(form.isSetSalesPeriod),
          startAt: form.isSetSalesPeriod ? moment(form.startAt).format('YYYY-MM-DD') : null,
          endAt: form.isSetSalesPeriod ? moment(form.endAt).format('YYYY-MM-DD') : null,
          vatCode: String(form.surtax),
          count: Number(form.isOption ? 0 : form.count),
          optionTypeCode: String(form.isOption ? form.optionConfigType : 'POT003'),
          singleOptions:
            form.optionConfigType === OPTION_CONFIG_TYPE.EXCLUSIVE
              ? form.options.map(({ name, value, isActive, count, optionId }) => ({
                  optionGroup: String(name),
                  optionName: String(value),
                  isActive: Boolean(isActive),
                  count,
                  optionId,
                }))
              : null,
          combinationOptions:
            form.optionConfigType === OPTION_CONFIG_TYPE.COMBINATION
              ? form.options.map(({ count, price, isActive, optionId, ...restOptions }) => ({
                  ...Object.entries(restOptions).reduce(
                    (p, [key, value], i) => ({
                      ...p,
                      [`extra${i + 1}`]: String(value),
                    }),
                    {},
                  ),
                  count: Number(count),
                  addPrice: Number(price),
                  isActive: Boolean(isActive),
                  optionId,
                }))
              : null,
          combineOptionKeys:
            form.optionConfigType === OPTION_CONFIG_TYPE.COMBINATION
              ? Object.keys(form.options[0]).filter((key) => !['count', 'price', 'isActive', 'optionId'].includes(key))
              : null,
          thumbnail: Number(form.images[0]) || null,
          images: form.additionImages?.map((image) => Number(image)) ?? [],
          detailImages: form.detailImages || [],
          introduction: String(draftToHTML(form.details)),
          detailIntroduction: String(draftToHTML(form.detailsInfo)),
          manufacturingAt: form.manufacturingAt ? moment(form.manufacturingAt).format('YYYY-MM-DD') : null,
          isCustomize: Boolean(form.isCustom),
          customizeInfo: String(form.custom),
          productInformationAnnouncement: String(draftToHTML(form.productInformationAnnouncement)),
          methods: String(form.deliveryType),
          isVisit: Boolean(form.isVisit),
          sellerAddress: form.isVisit ? form.sellerAddr?.jibunAddress || form.sellerAddr?.address : '테스트',
          sellerZipcode: form.isVisit ? String(form.sellerAddr?.zonecode ?? '') : '테스트',
          template: form.template ? Number(form.template) : null,
          isSaveTemplate: Boolean(form.isTemplateSave),
          templateName: String(form.templateName),
          hasQuick: Boolean(form.isQuick),
          deliveryInfo: String(form.deliveryDetails ?? ''),
          isMakeAfterOrder: Boolean(form.isCheckOrder),
          periods: Number(form.deliveryDate ?? 0),
          deliveryFreeTypeCode: String(form.deliveryPriceType),
          extraAmount: Number(form.deliverySpecialPrice),
          deliveryPaymentTypeCode: form.deliveryPaymentType ? String(form.deliveryPaymentType) : null,
          courierCode: String(form.deliveryCompany),
          refundCourierCode: String(form.returnDeliveryCompany),
          isInstallationAmount: Boolean(form.isInstallationAmount),
          cargoAddress: String(
            form.forwardingLocation?.jibunAddress ||
              form.forwardingLocation?.address ||
              form.forwardingLocation?.roadAddress,
          ),
          cargoZipcode: String(form.forwardingLocation?.zonecode ?? ''),
          deliveryAmount: form.deliveryPriceType !== DELIVERY_PRICE_TYPE.FREE ? Number(form.deliveryPrice) : null,
          deliveryFreeCount:
            form.deliveryPriceType === DELIVERY_PRICE_TYPE.BY_COUNT ? Number(form.deliveryPriceRepeat) : null,
          deliveryFreeAmount:
            form.deliveryPriceType === DELIVERY_PRICE_TYPE.CONDITIONALLY_FREE ? Number(form.deliveryPriceSum) : null,
          refundDeliveryAmount: Number(form.returnDeliveryPrice),
          changeDeliveryAmount: Number(form.exchangeDeliveryPrice),
          refundAddress: String(
            form.returnAddr?.jibunAddress || form.returnAddr?.address || form.returnAddr?.roadAddress,
          ),
          refundZipcode: String(form.returnAddr?.zonecode ?? ''),
          asPhone: String(form.asPhone ?? ''),
          asGuide: String(form.asInfo ?? ''),
          significant: String(form.sellerDetails ?? ''),
          returnInfo: form.returnInfo,
          modelName: form.modelName,
          brand: form.brand,
          manufacturer: form.manufacturer,
          isKc: form.isKc,
        }),
      );
      if (responseData) {
        if (isEdit) {
          Modal.info({
            title: '알림',
            content: '상품이 수정되었습니다.',
            centered: true,
            okText: '확인',
            onOk: () => {
              navigate('/goods/list', { replace: true });
            },
          });
        } else {
          Modal.info({
            title: '알림',
            content: '상품이 등록되었습니다.',
            okText: '확인',
            onOk: () => {
              navigate('/goods/list', { replace: true });
            },
            centered: true,
          });
        }
      }
    } catch (error) {
      handleError(error);
      Modal.error({
        title: `상품 ${isEdit ? '수정' : '등록'}`,
        content: `상품 ${isEdit ? '수정' : '등록'}에 실패 했습니다. 잠시후 시도해주세요.`,
        onOk: () => {
          navigate('/goods/list', {
            replace: true,
          });
        },
      });
    }
  };

  React.useEffect(() => {
    init();
    return () => {
      disablePrevent();
    };
  }, [location]);

  React.useEffect(() => {
    if (!templatesOptions) return;
    if (templatesOptions.length === 0) {
      setForm(
        produce((draft) => {
          draft.isTemplateSave = true;
        }),
      );
    } else {
      setForm(
        produce((draft) => {
          draft.isTemplateSave = false;
        }),
      );
    }
  }, [templatesOptions]);

  if (product.loading) {
    return <Spin />;
  }

  return (
    <Form onFinish={handleSubmit}>
      <Space direction="vertical" style={{ width: '100%' }} size="middle">
        <Collapse defaultActiveKey={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13]}>
          <Collapse.Panel header="파트너사/카테고리/상품명" key={1}>
            <Descriptions colon={false} bordered>
              <Descriptions.Item
                labelStyle={{ width: 180 }}
                label={<RequiredOrNotLabel label="카테고리" required />}
                span={3}
              >
                <Space direction="vertical" style={{ width: '100%' }}>
                  <Select
                    value={form?.category}
                    onChange={(value) => {
                      setForm(
                        produce((draft) => {
                          draft.category = value;
                        }),
                      );
                    }}
                    placeholder="등록한 카테고리 템플릿을 선택하세요"
                    style={{ width: '100%' }}
                    options={depth?[{label:depth, value:form?.category},...categoriesOptions.filter(v=>v.value!==form.category)]:categoriesOptions}
                  />
                  <Radio.Group
                    value={form?.categoryType}
                    defaultValue={1}
                    options={[
                      { label: '카테고리 검색', value: CATEGORY_TYPE.SEARCH },
                      { label: '카테고리명 선택', value: CATEGORY_TYPE.SELECT },
                    ]}
                    optionType="button"
                    buttonStyle="solid"
                    onChange={(event) => {
                      setForm(
                        produce((draft) => {
                          draft.categoryType = event.target.value;
                        }),
                      );
                    }}
                  />
                  {form?.categoryType === CATEGORY_TYPE.SELECT && (
                    <Space direction="vertical" style={{ width: 1200 }}>
                      <Categories
                        category={form?.category}
                        onChangeCategory={(category) => {
                          setForm(
                            produce((draft) => {
                              draft.category = category.code;
                            }),
                          );
                        }}
                      />
                      {depth && (
                          <Typography.Text style={{ color: '#4c72f1' }}>{depth}</Typography.Text>
                      )}
                    </Space>
                  )}
                </Space>
              </Descriptions.Item>
              <Descriptions.Item
                labelStyle={{ width: 180 }}
                label={<RequiredOrNotLabel label="상품명" required />}
                span={3}
              >
                <Input
                  value={form.productName}
                  onChange={(event) => {
                    setForm(
                      produce((draft) => {
                        draft.productName = event.target.value;
                      }),
                    );
                  }}
                  maxLength={30}
                  style={{ width: 400 }}
                  placeholder="상품명 입력"
                />
              </Descriptions.Item>
              <Descriptions.Item labelStyle={{ width: 180 }} label={<RequiredOrNotLabel label="해시태그" required />}>
                <Space direction="vertical">
                  <Select
                    value={form?.tags}
                    onChange={(value) => {
                      const newValue = value.filter((value) => !!value.trim());
                      setForm(
                        produce((draft) => {
                          draft.tags = [
                            ...new Set(
                              newValue.map((value) => value.replace(/#+|(.)/, '#$1').replace(/^!#?#|,|\s/g, '')),
                            ),
                          ];
                        }),
                      );
                    }}
                    mode="tags"
                    style={{ width: 400 }}
                  />
                  <Typography.Text>
                    엔터키를 통해서 태그를 연속적으로 입력하세요(,(쉼표), 공백, 해시태그(#) 사용 불가능)
                  </Typography.Text>
                </Space>
              </Descriptions.Item>
            </Descriptions>
          </Collapse.Panel>
          <Collapse.Panel header="판매가/판매방식" key={2}>
            <Descriptions colon={false} bordered>
              <Descriptions.Item
                labelStyle={{ width: 180 }}
                label={<RequiredOrNotLabel label="판매방식" required />}
                span={3}
              >
                <Checkbox
                  checked={form.isDirectPurchase}
                  onChange={(event) => {
                    setForm(
                      produce((draft) => {
                        draft.isDirectPurchase = event.target.checked;
                      }),
                    );
                  }}
                >
                  고객 직접구매
                </Checkbox>
                <Checkbox
                  checked={form.isQuotation}
                  onChange={(event) => {
                    setForm(
                      produce((draft) => {
                        draft.isQuotation = event.target.checked;
                      }),
                    );
                  }}
                >
                  견적의뢰
                </Checkbox>
              </Descriptions.Item>
              <Descriptions.Item
                labelStyle={{ width: 180 }}
                label={<RequiredOrNotLabel label="교환환불정책" required />}
                span={3}
              >
                <Space align="center">
                  <Typography.Text>수령 후</Typography.Text>
                  <Checkbox
                    checked={form.isNotExchangeable}
                    onChange={(event) => {
                      setForm(
                        produce((draft) => {
                          draft.isNotExchangeable = event.target.checked;
                        }),
                      );
                    }}
                  >
                    교환불가
                  </Checkbox>
                  <Checkbox
                    checked={form.isNotReturn}
                    onChange={(event) => {
                      setForm(
                        produce((draft) => {
                          draft.isNotReturn = event.target.checked;
                        }),
                      );
                    }}
                  >
                    반품불가
                  </Checkbox>
                  <Checkbox
                    checked={form.isNotCancel}
                    onChange={(event) => {
                      setForm(
                        produce((draft) => {
                          draft.isNotCancel = event.target.checked;
                        }),
                      );
                    }}
                  >
                    환불/취소불가
                  </Checkbox>
                </Space>
              </Descriptions.Item>
              <Descriptions.Item
                labelStyle={{ width: 180 }}
                label={<RequiredOrNotLabel label="판매가" required />}
                span={3}
              >
                <InputMoney
                  style={{ width: 200 }}
                  value={form?.price}
                  onChange={(value) => {
                    setForm(
                      produce((draft) => {
                        draft.price = value;
                      }),
                    );
                  }}
                  placeholder="숫자만 입력"
                  suffix="원"
                />
              </Descriptions.Item>
              <Descriptions.Item labelStyle={{ width: 180 }} label="할인" span={3}>
                <Radio.Group
                  defaultValue={false}
                  value={form.isDiscount}
                  onChange={(event) => {
                    setForm(
                      produce((draft) => {
                        draft.isDiscount = event.target.value;
                      }),
                    );
                  }}
                  options={[
                    { label: '설정함', value: true },
                    { label: '설정안함', value: false },
                  ]}
                  optionType="button"
                  buttonStyle="solid"
                />
              </Descriptions.Item>
              {form.isDiscount && (
                <Descriptions.Item
                  labelStyle={{ width: 180 }}
                  label={<RequiredOrNotLabel label="기본할인" required />}
                  span={3}
                >
                  <Space>
                    <Select
                      style={{ width: 200 }}
                      defaultValue={DISCOUNT_TYPE.RATE}
                      value={form.discountType}
                      onChange={(value) => {
                        setForm(
                          produce((draft) => {
                            draft.discountType = value;
                          }),
                        );
                      }}
                      options={[
                        { label: '금액할인', value: DISCOUNT_TYPE.AMOUNT },
                        { label: '할인율', value: DISCOUNT_TYPE.RATE },
                      ]}
                    />
                    {form.discountType === DISCOUNT_TYPE.AMOUNT ? (
                      <InputMoney
                        value={form.discountAmount}
                        onChange={(value) => {
                          setForm(
                            produce((draft) => {
                              draft.discountAmount = value;
                            }),
                          );
                        }}
                        placeholder="숫자만 입력"
                        suffix="원"
                      />
                    ) : (
                      form.discountType === DISCOUNT_TYPE.RATE && (
                        <InputMoney
                          value={form.discountRate}
                          onChange={(value) => {
                            setForm(
                              produce((draft) => {
                                draft.discountRate = value;
                              }),
                            );
                          }}
                          min={1}
                          onMin={() => {
                            setForm(
                              produce((draft) => {
                                draft.discountRate = 1;
                              }),
                            );
                          }}
                          max={99}
                          onMax={() => {
                            Modal.warn({
                              title: '알림',
                              content: '할인율은 1이상 99이하의 숫자만 입력할 수 있습니다.',
                              centered: true,
                            });
                          }}
                          placeholder="숫자만 입력"
                          suffix="%"
                        />
                      )
                    )}
                  </Space>
                  <Divider style={{ marginTop: 15, marginBottom: 10 }} />
                  <Typography.Text>
                    할인가 {formatComma(discountPrice)}원 ({formatComma(discount)}원 할인)
                  </Typography.Text>
                </Descriptions.Item>
              )}
              <Descriptions.Item
                labelStyle={{ width: 180 }}
                label={<RequiredOrNotLabel label="판매기간" required />}
                span={3}
              >
                <Radio.Group
                  value={form?.isSetSalesPeriod}
                  onChange={(event) => {
                    setForm(
                      produce((draft) => {
                        draft.isSetSalesPeriod = event.target.value;
                      }),
                    );
                  }}
                  defaultValue={false}
                  options={[
                    { label: '설정함', value: true },
                    { label: '설정안함', value: false },
                  ]}
                  optionType="button"
                  buttonStyle="solid"
                />
              </Descriptions.Item>
              {form?.isSetSalesPeriod && (
                <Descriptions.Item
                  labelStyle={{ width: 180 }}
                  label={<RequiredOrNotLabel label="기본설정" required />}
                  span={3}
                >
                  <Space direction="vertical">
                    <Radio.Group
                      value={moment(form?.endAt).diff(form?.startAt, 'day')}
                      options={[
                        { label: '3일', value: 3 },
                        { label: '5일', value: 5 },
                        { label: '7일', value: 7 },
                        { label: '15일', value: 15 },
                        { label: '30일', value: 30 },
                        { label: '60일', value: 60 },
                        { label: '90일', value: 90 },
                        { label: '120일', value: 120 },
                      ]}
                      onChange={(event) => {
                        setForm(
                          produce((draft) => {
                            draft.startAt = moment().format('YYYY-MM-DD');
                            draft.endAt = moment().add(event.target.value, 'day').format('YYYY-MM-DD');
                          }),
                        );
                      }}
                      optionType="button"
                      buttonStyle="solid"
                    />
                    <DatePicker.RangePicker
                      value={[moment(form?.startAt), moment(form?.endAt)]}
                      onChange={([startAt, endAt]) => {
                        setForm(
                          produce((draft) => {
                            draft.startAt = startAt.format('YYYY-MM-DD');
                            draft.endAt = endAt.format('YYYY-MM-DD');
                          }),
                        );
                      }}
                      format="YYYY-MM-DD"
                    />
                    <Typography.Text>판매기간이 지나면 상품이 노출되지 않습니다.</Typography.Text>
                  </Space>
                </Descriptions.Item>
              )}
              <Descriptions.Item labelStyle={{ width: 180 }} label={<RequiredOrNotLabel label="부가세" required />}>
                <Radio.Group
                  value={form?.surtax}
                  onChange={(event) => {
                    setForm(
                      produce((draft) => {
                        draft.surtax = event.target.value;
                      }),
                    );
                  }}
                  defaultValue={SURTAX.TAXATION}
                  options={[
                    { label: '과세상품', value: SURTAX.TAXATION },
                    { label: '면세상품', value: SURTAX.EXEMPTION },
                    { label: '영세상품', value: SURTAX.SMALL },
                  ]}
                  optionType="button"
                  buttonStyle="solid"
                />
              </Descriptions.Item>
            </Descriptions>
          </Collapse.Panel>
          <Collapse.Panel header="재고수량" key={3}>
            <Descriptions colon={false} bordered>
              <Descriptions.Item labelStyle={{ width: 180 }} label={<RequiredOrNotLabel label="재고수량" required />}>
                <Space direction="vertical">
                  <InputMoney
                    style={{ width: 150 }}
                    value={form.isOption ? optionsCount : form.count}
                    onChange={(value) => {
                      setForm(
                        produce((draft) => {
                          draft.count = value;
                        }),
                      );
                    }}
                    placeholder="숫자만 입력"
                    suffix="개"
                    disabled={form?.isOption}
                  />
                  <Typography.Text>※ 옵션을 설정할 경우 옵션의 재고수량의 합으로 자동계산됩니다.</Typography.Text>
                </Space>
              </Descriptions.Item>
            </Descriptions>
          </Collapse.Panel>
          <Collapse.Panel header="옵션" key={4}>
            <Descriptions colon={false} bordered>
              <Descriptions.Item
                labelStyle={{ width: 180 }}
                label={<RequiredOrNotLabel label="선택형" required />}
                span={3}
              >
                <Radio.Group
                  // disabled={isEdit}
                  value={form?.isOption}
                  defaultValue={false}
                  onChange={(event) => {
                    setForm(
                      produce((draft) => {
                        draft.isOption = event.target.value;
                      }),
                    );
                  }}
                  options={[
                    { label: '설정함', value: true },
                    { label: '설정안함', value: false },
                  ]}
                  optionType="button"
                  buttonStyle="solid"
                />
              </Descriptions.Item>
              {form?.isOption && (
                <>
                  <Descriptions.Item
                    labelStyle={{ width: 180 }}
                    label={<RequiredOrNotLabel label="옵션 입력방식" required />}
                    span={3}
                  >
                    <Space direction="vertical">
                      <Radio.Group
                        // disabled={isEdit}
                        value={form?.optionType}
                        onChange={(event) => {
                          setForm(
                            produce((draft) => {
                              draft.optionType = event.target.value;
                            }),
                          );
                        }}
                        defaultValue={OPTION_TYPE.SELF}
                        options={[
                          { label: '직접 입력하기', value: OPTION_TYPE.SELF },
                          { label: '엑셀 일괄등록', value: OPTION_TYPE.EXCEL },
                        ]}
                      />
                      {form?.optionType === OPTION_TYPE.EXCEL && (
                        <Space>
                          <Upload
                            fileList={[]}
                            // disabled={isEdit}
                            beforeUpload={checkBeforeUpload({
                              availableLists: [
                                'application/vnd.ms-excel',
                                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                              ],
                              textWhenFail: 'excel 파일만 가능합니다.',
                            })}
                            onChange={({ file }) => {
                              const fileReader = new FileReader();
                              fileReader.readAsArrayBuffer(file.originFileObj);

                              fileReader.onload = (event) => {
                                const xlsx = XLSX.read(event.target.result, { type: 'array', cellDates: true });
                                const [sheet] = Object.values(xlsx.Sheets);
                                switch (form.optionConfigType) {
                                  case OPTION_CONFIG_TYPE.EXCLUSIVE: {
                                    const options = Object.entries(sheet).reduce((p, [key, { v }]) => {
                                      if (!p) {
                                        return null;
                                      }
                                      const keyIndex = ['A', 'B', 'C', 'D', '!'].findIndex((value) =>
                                        key.startsWith(value),
                                      );
                                      const valueIndex = key.substring(1) - 2;
                                      if (keyIndex > -1) {
                                        if (valueIndex > -1) {
                                          const _key = ['name', 'value', 'isActive'][keyIndex];
                                          if (_key) {
                                            if (p[valueIndex]) {
                                              p[valueIndex][_key] = v;
                                            } else {
                                              p[valueIndex] = { [_key]: v };
                                            }
                                            if (_key === 'isActive') {
                                              p[valueIndex][_key] = v === 'Y';
                                            }
                                          }
                                        }
                                      } else {
                                        return null;
                                      }
                                      return p;
                                    }, []);
                                    if (!options) {
                                      Modal.warn({
                                        title: '알림',
                                        content: '엑셀 문서 양식이 올바르지 않습니다.',
                                        centered: true,
                                      });
                                      return;
                                    }
                                    for (let i = 0; i < options.length; i++) {
                                      const option = options[i];
                                      if (option.name.length > 25) {
                                        Modal.warn({
                                          title: '알림',
                                          content: `${i + 1}번째 옵션의 옵션명 항목을 25자보다 작게 입력해주세요.`,
                                          centered: true,
                                        });
                                        return;
                                      }
                                      if (option.value.length > 25) {
                                        Modal.warn({
                                          title: '알림',
                                          content: `${i + 1}번째 옵션의 옵션값 항목을 25자보다 작게 입력해주세요.`,
                                          centered: true,
                                        });
                                        return;
                                      }
                                    }
                                    const inputOptions = formatExclusiveOptionsToInputOptions(options);
                                    if (inputOptions.length < 1) {
                                      Modal.warn({
                                        title: '알림',
                                        content: '옵션명을 1개 이상 입력해주세요.',
                                        centered: true,
                                      });
                                      return;
                                    }
                                    if (inputOptions.length > 3) {
                                      Modal.warn({
                                        title: '알림',
                                        content: '옵션명은 최대 3개까지 추가할 수 있습니다.',
                                        centered: true,
                                      });
                                      return;
                                    }
                                    setForm(
                                      produce((draft) => {
                                        draft.optionType = OPTION_TYPE.SELF;
                                        draft.optionsCount = options.length;
                                        draft.options = options;
                                        draft.inputOptions = inputOptions;
                                      }),
                                    );
                                    break;
                                  }
                                  case OPTION_CONFIG_TYPE.COMBINATION: {
                                    if (
                                      ['A', 'B', 'C', 'D', 'E', 'F'].some(
                                        (e) => !Object.keys(sheet).some((e2) => e2.startsWith(e)),
                                      )
                                    ) {
                                      Modal.warn({
                                        title: '알림',
                                        content: '엑셀 문서 양식이 올바르지 않습니다.',
                                        centered: true,
                                      });
                                      return;
                                    }
                                    const options = Object.entries(sheet).reduce((p, [key, { v }]) => {
                                      if (!p) {
                                        return null;
                                      }
                                      const keyIndex = ['A', 'B', 'C', 'D', 'E', 'F', '!'].findIndex((value) =>
                                        key.startsWith(value),
                                      );
                                      // A로 시작하면 0, B로 시작하는 키면 1,  !는 6
                                      const valueIndex = key.substring(1) - 2;
                                      // console.log('valueIndex :::: ', valueIndex);
                                      if (keyIndex > -1) {
                                        if (valueIndex > -1) {
                                          const _key = [
                                            sheet.A1.v, //선택1
                                            sheet.B1.v, // 선택2
                                            sheet.C1.v, //선택3
                                            'price',
                                            'count',
                                            'isActive',
                                          ][keyIndex];
                                          if (_key) {
                                            if (p[valueIndex]) {
                                              p[valueIndex][_key] = v;
                                            } else {
                                              p[valueIndex] = { [_key]: v };
                                            }
                                            if (_key === 'isActive') {
                                              p[valueIndex][_key] = v === 'Y';
                                            }
                                          }
                                        }
                                      } else {
                                        return null;
                                      }
                                      return p;
                                    }, []);
                                    // console.log('options :::: ', options);
                                    for (let i = 0; i < options.length; i++) {
                                      const option = options[i];
                                      const arr = [sheet.A1.v, sheet.B1.v, sheet.C1.v];
                                      const _index = arr.findIndex((value) => option[value]?.length > 25);
                                      if (_index > -1) {
                                        Modal.warn({
                                          title: '알림',
                                          content: `${i + 1}번째 옵션의 ${
                                            arr[_index]
                                          } 항목을 25자보다 작게 입력해주세요.`,
                                          centered: true,
                                        });
                                        return;
                                      }
                                    }
                                    const inputOptions = formatCombinationOptionsToInputOptions(options);
                                    setForm(
                                      produce((draft) => {
                                        draft.optionType = OPTION_TYPE.SELF;
                                        draft.options = options;
                                        draft.inputOptions = inputOptions;
                                      }),
                                    );
                                    break;
                                  }
                                  default:
                                }
                              };
                            }}
                          >
                            <Button type="primary">엑셀 일괄 적용</Button>
                          </Upload>
                          <Button>
                            <a href="/assets/excels/OptionSimpleTemplate.xls" download>
                              엑셀양식(단독형)
                            </a>
                          </Button>
                          <Button>
                            <a href="/assets/excels/OptionCombinationTemplate.xls" download>
                              엑셀양식(조합형)
                            </a>
                          </Button>
                        </Space>
                      )}
                    </Space>
                  </Descriptions.Item>

                  <Descriptions.Item
                    labelStyle={{ width: 180 }}
                    label={<RequiredOrNotLabel label="옵션명 개수" required />}
                    span={3}
                  >
                    <Select
                      value={String(form.inputOptions.length)}
                      defaultValue="1"
                      // disabled={isEdit}
                      onChange={(value) => {
                        setForm(
                          produce((draft) => {
                            draft.inputOptions = [...Array(Number(value))].map((_, i) => ({
                              name: draft.inputOptions[i]?.name ?? '',
                              value: draft.inputOptions[i]?.value ?? '',
                            }));
                          }),
                        );
                      }}
                      style={{ width: 120 }}
                      placeholder="개수 선택"
                      options={
                        form?.optionConfigType === OPTION_CONFIG_TYPE.EXCLUSIVE
                          ? [{ label: '1개', value: '1' }]
                          : [
                              { label: '1개', value: '1' },
                              { label: '2개', value: '2' },
                              { label: '3개', value: '3' },
                            ]
                      }
                    />
                  </Descriptions.Item>
                  <Descriptions.Item
                    labelStyle={{ width: 180 }}
                    label={<RequiredOrNotLabel label="정렬 순서" required />}
                    span={3}
                  >
                    <Select
                      // disabled={isEdit}
                      value={form?.optionOrderType}
                      defaultValue={OPTION_ORDER_TYPE.CREATED_AT}
                      onChange={(value) => {
                        setForm(
                          produce((draft) => {
                            draft.optionOrderType = value;
                          }),
                        );
                      }}
                      style={{ width: 120 }}
                      placeholder="순서 선택"
                      options={[
                        { label: '등록순', value: OPTION_ORDER_TYPE.CREATED_AT },
                        { label: '가나다순', value: OPTION_ORDER_TYPE.NAME },
                        { label: '낮은 가격순', value: OPTION_ORDER_TYPE.LOW_PRICE },
                        { label: '높은 가격순', value: OPTION_ORDER_TYPE.HIGH_PRICE },
                      ]}
                    />
                  </Descriptions.Item>
                  <Descriptions.Item
                    labelStyle={{ width: 180 }}
                    label={<RequiredOrNotLabel label="옵션 입력" required />}
                    span={3}
                  >
                    {/* NOTICE 옵션 작업 중 */}
                    <Table
                      rowKey={(_, index) => index}
                      columns={[
                        {
                          title: '옵션명',
                          dataIndex: 'name',
                          width: 150,
                          render: (value, record, index) => (
                            <Input
                              // disabled={isEdit}
                              value={value}
                              onChange={(event) => {
                                setForm(
                                  produce((draft) => {
                                    draft.inputOptions[index].name = event.target.value;
                                  }),
                                );
                              }}
                              placeholder="예) 컬러"
                            />
                          ),
                        },
                        {
                          title: '옵션값',
                          dataIndex: 'value',
                          render: (value, record, index) => (
                            <Space align="center" style={{ width: '100%' }}>
                              <Input
                                // disabled={isEdit}
                                value={value}
                                onChange={(event) => {
                                  setForm(
                                    produce((draft) => {
                                      draft.inputOptions[index].value = event.target.value;
                                    }),
                                  );
                                }}
                                placeholder="예) 빨강, 노랑, 파랑"
                              />
                              {index > 0 && (
                                <Button
                                  type="danger"
                                  // disabled={isEdit}
                                  onClick={() => {
                                    setForm(
                                      produce((draft) => {
                                        draft.inputOptions.splice(index, 1);
                                        draft.options = formatInputOptionsToExclusiveOptions(draft.inputOptions);
                                        if (form?.optionConfigType === OPTION_CONFIG_TYPE.COMBINATION) {
                                          draft.options = formatExclusiveOptionsToCombinationOptions(draft.options);
                                        }
                                      }),
                                    );
                                  }}
                                >
                                  삭제
                                </Button>
                              )}
                            </Space>
                          ),
                        },
                      ]}
                      dataSource={form?.inputOptions?.filter((value) => value?.name !== 'optionId')}
                      bordered
                      footer={() => (
                        <Button
                          type="primary"
                          onClick={() => {
                            setForm(
                              produce((draft) => {
                                draft.options = formatInputOptionsToExclusiveOptions(draft.inputOptions);
                                if (form?.optionConfigType === OPTION_CONFIG_TYPE.COMBINATION) {
                                  draft.options = formatExclusiveOptionsToCombinationOptions(draft.options);
                                }
                              }),
                            );
                          }}
                          disabled={disabledOptionsApplyButton}
                        >
                          옵션 목록으로 적용
                        </Button>
                      )}
                      size="small"
                      pagination={false}
                    />
                  </Descriptions.Item>
                  <Descriptions.Item
                    labelStyle={{ width: 180 }}
                    label={<RequiredOrNotLabel label="옵션 구성타입" required />}
                    span={3}
                  >
                    <Radio.Group
                      // disabled={isEdit}
                      defaultValue={OPTION_CONFIG_TYPE.EXCLUSIVE}
                      value={form?.optionConfigType}
                      onChange={(event) => {
                        if (form.inputOptions.some(({ name, value }) => !name || !value)) {
                          Modal.warn({
                            title: '알림',
                            content: '옵션명, 옵션값을 필수로 입력해 주세요.',
                            centered: true,
                            okText: '확인',
                          });
                          return;
                        }

                        if (event.target.value === OPTION_CONFIG_TYPE.EXCLUSIVE) {
                          if (form?.inputOptions?.length >= 2) {
                            Modal.warn({
                              title: '알림',
                              content: '단독형은 옵션 개수 1개만 가능합니다.',
                              centered: true,
                              okText: '확인',
                            });
                            return;
                          }
                        }
                        // NOTICE ::::: 옵션 수정 부분
                        setForm(
                          produce((draft) => {
                            draft.optionConfigType = event.target.value;

                            if (draft.optionConfigType === OPTION_CONFIG_TYPE.EXCLUSIVE) {
                              if (defaultOptionValuesWhenEdit?.optionTypeCode === OPTION_CONFIG_TYPE.EXCLUSIVE) {
                                draft.options = defaultOptionValuesWhenEdit?.options;
                              } else {
                                draft.options = [draft.inputOptions?.[0]]?.reduce((p, c) => {
                                  p.push(
                                    ...c.value?.split(',').map((value) => ({
                                      name: c.name,
                                      value: value.trim(),
                                      isActive: false,
                                      count: 0,
                                    })),
                                  );
                                  return p;
                                }, []);
                              }
                            }

                            if (draft.optionConfigType === OPTION_CONFIG_TYPE.COMBINATION) {
                              if (defaultOptionValuesWhenEdit?.optionTypeCode === OPTION_CONFIG_TYPE.COMBINATION) {
                                draft.options = defaultOptionValuesWhenEdit?.options;
                              } else {
                                draft.options = formatExclusiveOptionsToCombinationOptions(draft.options);
                              }
                            }
                          }),
                        );
                      }}
                      options={[
                        { label: '단독형', value: OPTION_CONFIG_TYPE.EXCLUSIVE },
                        { label: '조합형', value: OPTION_CONFIG_TYPE.COMBINATION },
                      ]}
                    />
                    <Typography.Text>※ 단독형은 옵션갯수가 1개만 가능합니다.</Typography.Text>
                  </Descriptions.Item>
                  <Descriptions.Item
                    labelStyle={{ width: 180 }}
                    label={<RequiredOrNotLabel label="옵션 목록" required />}
                    span={3}
                  >
                    <Table
                      columns={
                        form?.optionConfigType === OPTION_CONFIG_TYPE.EXCLUSIVE
                          ? [
                              {
                                title: '옵션명',
                                dataIndex: 'name',
                                render: (value, record, index) => (
                                  <Input
                                    // disabled={isEdit}
                                    value={value}
                                    onChange={(event) => {
                                      setForm(
                                        produce((draft) => {
                                          draft.options[index].name = event.target.value;
                                        }),
                                      );
                                    }}
                                    placeholder="옵션명"
                                    onBlur={(event) => {
                                      const _index = form.inputOptions.findIndex(
                                        ({ name }) => name === event.target.value,
                                      );
                                      if (!(_index > -1) && form.inputOptions.length > 2) {
                                        Modal.warn({
                                          title: '알림',
                                          content: '옵션명은 최대3개 까지 입력하세요.',
                                          centered: true,
                                          okText: '확인',
                                        });
                                        return;
                                      }
                                      setForm(
                                        produce((draft) => {
                                          draft.inputOptions = formatExclusiveOptionsToInputOptions(draft.options);
                                        }),
                                      );
                                    }}
                                  />
                                ),
                              },
                              {
                                title: '옵션값',
                                dataIndex: 'value',
                                render: (value, record, index) => (
                                  <Input
                                    // disabled={isEdit}
                                    value={value}
                                    onChange={(event) => {
                                      setForm(
                                        produce((draft) => {
                                          draft.options[index].value = event.target.value;
                                        }),
                                      );
                                    }}
                                    placeholder="옵션값"
                                    onBlur={() => {
                                      const _index = form.inputOptions.findIndex(({ name }) => name === record.name);
                                      if (!(_index > -1) && form.inputOptions.length > 2) {
                                        Modal.warn({
                                          title: '알림',
                                          content: '옵션명은 최대3개 까지 입력하세요.',
                                          centered: true,
                                          okText: '확인',
                                        });
                                        return;
                                      }
                                      setForm(
                                        produce((draft) => {
                                          draft.inputOptions = formatExclusiveOptionsToInputOptions(draft.options);
                                        }),
                                      );
                                    }}
                                  />
                                ),
                              },
                              {
                                title: '재고수량',
                                dataIndex: 'count',
                                render: (value, record, index) => (
                                  <InputMoney
                                    value={value}
                                    onChange={(value) => {
                                      setForm(
                                        produce((draft) => {
                                          draft.options[index].count = value;
                                        }),
                                      );
                                    }}
                                  />
                                ),
                              },
                              {
                                title: '사용여부',
                                dataIndex: 'isActive',
                                width: 100,
                                render: (value, record, index) => (
                                  <Select
                                    value={value}
                                    onChange={(value) => {
                                      setForm(
                                        produce((draft) => {
                                          draft.options[index].isActive = value;
                                        }),
                                      );
                                    }}
                                    options={[
                                      { label: 'Y', value: true },
                                      { label: 'N', value: false },
                                    ]}
                                  />
                                ),
                              },
                              {
                                title: '삭제',
                                width: 80,
                                render: (value, record, index) => (
                                  <Button
                                    type="danger"
                                    // disabled={isEdit}
                                    onClick={() => {
                                      setForm(
                                        produce((draft) => {
                                          draft.options.splice(index, 1);
                                          const inputOptions = formatExclusiveOptionsToInputOptions(draft.options);
                                          if (inputOptions.length > 3) {
                                            Modal.warn({
                                              title: '알림',
                                              content: '옵션명은 최대3개 까지 입력하세요.',
                                              centered: true,
                                              okText: '확인',
                                            });
                                          } else {
                                            draft.inputOptions =
                                              inputOptions.length > 0 ? inputOptions : [{ name: '', value: '' }];
                                          }
                                        }),
                                      );
                                    }}
                                  >
                                    삭제
                                  </Button>
                                ),
                              },
                            ]
                          : form?.optionConfigType === OPTION_CONFIG_TYPE.COMBINATION
                          ? [
                              // TODO 옵션 관련 수정 중
                              {
                                title: '옵션명',
                                children: Object.keys(form?.options[0] ?? {})
                                  ?.filter((value) => value !== 'optionId')
                                  ?.reduce(
                                    (p, key) =>
                                      ['price', 'count', 'isActive'].includes(key)
                                        ? p
                                        : [
                                            ...p,
                                            {
                                              title: key,
                                              dataIndex: key,
                                              width: 120,
                                              render: (value, record, index) => (
                                                <Input
                                                  value={value}
                                                  // disabled={isEdit}
                                                  onChange={(event) => {
                                                    setForm(
                                                      produce((draft) => {
                                                        draft.options[index][key] = event.target.value;
                                                      }),
                                                    );
                                                  }}
                                                  onBlur={() => {
                                                    setForm(
                                                      produce((draft) => {
                                                        draft.inputOptions = formatCombinationOptionsToInputOptions(
                                                          draft.options,
                                                        );
                                                      }),
                                                    );
                                                  }}
                                                />
                                              ),
                                            },
                                          ],
                                    [],
                                  ),
                              },
                              {
                                title: '옵션가',
                                dataIndex: 'price',
                                render: (value, record, index) => (
                                  <InputMoney
                                    value={value}
                                    // disabled={isEdit}
                                    onChange={(value) => {
                                      setForm(
                                        produce((draft) => {
                                          draft.options[index].price = value;
                                        }),
                                      );
                                    }}
                                  />
                                ),
                              },
                              {
                                title: '재고수량',
                                dataIndex: 'count',
                                render: (value, record, index) => (
                                  <InputMoney
                                    value={value}
                                    onChange={(value) => {
                                      setForm(
                                        produce((draft) => {
                                          draft.options[index].count = value;
                                        }),
                                      );
                                    }}
                                  />
                                ),
                              },
                              {
                                title: '판매상태',
                                dataIndex: 'count',
                                width: 100,
                                render: (value) => (value > 0 ? '판매중' : '품절'),
                              },
                              {
                                title: '사용여부',
                                dataIndex: 'isActive',
                                width: 100,
                                render: (value, record, index) => (
                                  <Select
                                    value={value}
                                    onChange={(value) => {
                                      setForm(
                                        produce((draft) => {
                                          draft.options[index].isActive = value;
                                        }),
                                      );
                                    }}
                                    options={[
                                      { label: 'Y', value: true },
                                      { label: 'N', value: false },
                                    ]}
                                    style={{ width: '100%' }}
                                  />
                                ),
                              },
                              {
                                title: '삭제',
                                dataIndex: 'id',
                                width: 80,
                                render: (value, record, index) => (
                                  <Button
                                    type="danger"
                                    onClick={() => {
                                      setForm(
                                        produce((draft) => {
                                          draft.options.splice(index, 1);
                                          const inputOptions = formatCombinationOptionsToInputOptions(draft.options);
                                          draft.inputOptions =
                                            inputOptions.length > 0 ? inputOptions : [{ name: '', value: '' }];
                                        }),
                                      );
                                    }}
                                  >
                                    삭제
                                  </Button>
                                ),
                              },
                            ]
                          : []
                      }
                      rowSelection={{
                        selectedRowKeys: form?.optionsSelectedRowKeys,
                        onChange: (selectedRowKeys) => {
                          setForm(
                            produce((draft) => {
                              draft.optionsSelectedRowKeys = selectedRowKeys;
                            }),
                          );
                        },
                      }}
                      rowKey={(_, index) => index}
                      dataSource={form?.options}
                      bordered
                      size="small"
                      scroll={{ y: 450 }}
                      title={() => (
                        <Space direction="vertical" style={{ width: '100%' }}>
                          <Row justify="end" gutter={8} wrap={false}>
                            <Col>
                              <Button
                                disabled={form.options?.[0]?.name === '' || form.options?.[0]?.value === ''}
                                onClick={() => {
                                  if (form.options?.length > 0) {
                                    downloadExcel({
                                      title: 'options',
                                      dataJsonArray: form.options,
                                      ObjToChangeKey: {
                                        count: '재고수량',
                                        price: '옵션가',
                                        name: '옵션명',
                                        value: '옵션값',
                                        isActive: '사용여부',
                                      },
                                      options: form?.inputOptions,
                                      isExclusive: form?.optionConfigType === OPTION_CONFIG_TYPE.EXCLUSIVE,
                                    });
                                  }
                                }}
                              >
                                엑셀다운
                              </Button>
                            </Col>
                            <Col>
                              <Button
                                type="primary"
                                // disabled={isEdit}
                                onClick={() => {
                                  setForm(
                                    produce((draft) => {
                                      draft.options.push(
                                        draft.optionConfigType === OPTION_CONFIG_TYPE.COMBINATION
                                          ? {
                                              price: 0,
                                              count: 0,
                                              isActive: false,
                                            }
                                          : draft.optionConfigType === OPTION_CONFIG_TYPE.EXCLUSIVE
                                          ? {
                                              name: '',
                                              value: '',
                                              isActive: false,
                                            }
                                          : {},
                                      );
                                    }),
                                  );
                                }}
                              >
                                목록추가
                              </Button>
                            </Col>
                          </Row>
                          <Row justify="space-between" gutter={16} wrap={false}>
                            <Col>
                              <Button
                                // disabled={isEdit}
                                onClick={() => {
                                  setForm(
                                    produce((draft) => {
                                      draft.options = draft.options.filter(
                                        (_, index) => !form?.optionsSelectedRowKeys.includes(index),
                                      );
                                      draft.optionsSelectedRowKeys = [];
                                      const inputOptions =
                                        draft.optionConfigType === OPTION_CONFIG_TYPE.COMBINATION
                                          ? formatCombinationOptionsToInputOptions(draft.options)
                                          : draft.optionConfigType === OPTION_CONFIG_TYPE.EXCLUSIVE
                                          ? formatExclusiveOptionsToInputOptions(draft.options)
                                          : [{ name: '', value: '' }];
                                      draft.inputOptions =
                                        inputOptions.length > 0 ? inputOptions : [{ name: '', value: '' }];
                                    }),
                                  );
                                }}
                              >
                                선택삭제
                              </Button>
                            </Col>
                            {form?.optionConfigType === OPTION_CONFIG_TYPE.COMBINATION && (
                              <>
                                <Col flex={1}>
                                  <Space>
                                    <Typography.Text>옵션가</Typography.Text>
                                    <Select
                                      value={form.isOptionsPriceAdd}
                                      // disabled={isEdit}
                                      onChange={(value) => {
                                        setForm(
                                          produce((draft) => {
                                            draft.isOptionsPriceAdd = value;
                                          }),
                                        );
                                      }}
                                      defaultValue
                                      options={[
                                        { label: '+', value: true },
                                        { label: '-', value: false },
                                      ]}
                                    />
                                    <InputMoney
                                      value={form.optionsPrice}
                                      onChange={(value) => {
                                        setForm(
                                          produce((draft) => {
                                            draft.optionsPrice = value;
                                          }),
                                        );
                                      }}
                                      placeholder="숫자만 입력"
                                      suffix="원"
                                    />
                                  </Space>
                                </Col>
                                <Col flex={1}>
                                  <Space>
                                    <Typography.Text>재고수량</Typography.Text>
                                    <InputMoney
                                      value={form?.optionsCount}
                                      onChange={(value) => {
                                        setForm(
                                          produce((draft) => {
                                            draft.optionsCount = value;
                                          }),
                                        );
                                      }}
                                      placeholder="숫자만 입력"
                                      suffix="개"
                                    />
                                  </Space>
                                </Col>
                              </>
                            )}
                            <Col>
                              <Space>
                                <Space>
                                  <Typography.Text>사용여부</Typography.Text>
                                  <Select
                                    value={form?.isOptionsActive}
                                    onChange={(value) => {
                                      setForm(
                                        produce((draft) => {
                                          draft.isOptionsActive = value;
                                        }),
                                      );
                                    }}
                                    defaultValue
                                    options={[
                                      { label: 'Y', value: true },
                                      { label: 'N', value: false },
                                    ]}
                                  />
                                </Space>
                                <Button
                                  type="primary"
                                  onClick={() => {
                                    setForm(
                                      produce((draft) => {
                                        draft.options = draft.options.map((option, index) => {
                                          const obj = { ...option };
                                          if (draft.optionsSelectedRowKeys.includes(index)) {
                                            obj.isActive = draft.isOptionsActive;
                                            if (draft.optionConfigType === OPTION_CONFIG_TYPE.COMBINATION) {
                                              if (draft.optionsPrice !== '') {
                                                let price = draft.optionsPrice;
                                                if (!draft.isOptionsPriceAdd) {
                                                  price = -price;
                                                }
                                                obj.price = price;
                                              }
                                              if (draft.optionsCount !== '') {
                                                obj.count = draft.optionsCount;
                                              }
                                            }
                                          }
                                          return obj;
                                        });
                                        draft.optionsSelectedRowKeys = [];
                                      }),
                                    );
                                  }}
                                >
                                  선택목록 일괄수정
                                </Button>
                              </Space>
                            </Col>
                          </Row>
                        </Space>
                      )}
                      pagination={false}
                    />
                  </Descriptions.Item>
                </>
              )}
            </Descriptions>
          </Collapse.Panel>
          <Collapse.Panel header="상품이미지" key={5}>
            <Descriptions colon={false} bordered>
              <Descriptions.Item
                labelStyle={{ width: 180 }}
                label={<RequiredOrNotLabel label="대표이미지" required />}
                span={3}
              >
                <Space direction="vertical">
                  <FileUpload
                    listType="picture-card"
                    items={form.images || []}
                    onDoneChange={(fileListDone) => {
                      setForm(
                        produce((draft) => {
                          draft.images = fileListDone.map(({ response }) => response[0].id);
                        }),
                      );
                    }}
                    hideUpload={({ length }) => length > 0}
                    beforeUpload={checkBeforeUpload({
                      availableLists: ['image/jpg', 'image/jpeg', 'image/png', 'image/gif'],
                      textWhenFail: 'jpg, jpeg, png, gif 확장자만 가능합니다.',
                    })}
                    maxCount={1}
                  />
                  <Typography.Paragraph>
                    <ul>
                      <li>첨부파일은 jpg, jpeg, png, gif 만 첨부가 가능합니다.</li>
                      <li>권장크기: 1000 x 1000</li>
                    </ul>
                  </Typography.Paragraph>
                </Space>
              </Descriptions.Item>
              <Descriptions.Item labelStyle={{ width: 180 }} label={`추가이미지(${form.images.length}/9)`} span={3}>
                <Space direction="vertical">
                  <FileUpload
                    listType="picture-card"
                    items={form.additionImages || []}
                    onDoneChange={(fileListDone) => {
                      setForm(
                        produce((draft) => {
                          draft.additionImages = fileListDone.map(({ response }) => response[0].id);
                        }),
                      );
                    }}
                    hideUpload={({ length }) => length > 8}
                    beforeUpload={checkBeforeUpload({
                      availableLists: ['image/jpg', 'image/jpeg', 'image/png', 'image/gif'],
                      textWhenFail: 'jpg, jpeg, png, gif 확장자만 가능합니다.',
                    })}
                    maxCount={9}
                  />
                  <Button
                    onClick={() => {
                      Modal.info({
                        title: '추가이미지 순서 변경',
                        centered: true,
                        content: (
                          <Space direction="vertical">
                            <Typography.Text>이미지를 선택해서 원하는 위치로 끌어서 옮겨주세요</Typography.Text>
                            <SortableTable
                              dataSource={form.additionImages.map((image) => ({ image }))}
                              columns={[
                                {
                                  title: '이미지',
                                  width: 150,
                                  dataIndex: 'image',
                                  render: (value) => (
                                    <img src={`${getFileHost()}/${value}`} alt="이미지" style={{ width: '100%' }} />
                                  ),
                                },
                                {
                                  title: '이미지 순서',
                                  render: (_, $, index) => `이미지 순서 ${index + 1}`,
                                },
                              ]}
                              onOk={(dataSource) => {
                                // console.log(
                                //   '!!!',
                                //   dataSource.map(({ image }) => image),
                                // );
                                setForm(
                                  produce((draft) => {
                                    draft.additionImages = dataSource.map(({ image }) => image);
                                  }),
                                );
                              }}
                              onCancel={() => {}}
                            />
                          </Space>
                        ),
                        okButtonProps: { style: { display: 'none' } },
                      });
                    }}
                  >
                    순서변경
                  </Button>
                  <Typography.Paragraph>
                    <ul>
                      <li>첨부파일은 jpg, jpeg, png, gif 만 첨부가 가능합니다.</li>
                      <li>권장크기: 1000 x 1000</li>
                    </ul>
                  </Typography.Paragraph>
                </Space>
              </Descriptions.Item>
            </Descriptions>
          </Collapse.Panel>
          {/* <Collapse.Panel header="상세설명 이미지" key={6}>
            <Descriptions colon={false} bordered>
              <Descriptions.Item
                labelStyle={{ width: 180 }}
                label={`상세설명 이미지(${form.detailImages.length}/9)`}
                span={3}
              >
                <Space direction="vertical">
                  <FileUpload
                    listType="picture-card"
                    items={form.detailImages || []}
                    onDoneChange={(fileListDone) => {
                      setForm(
                        produce((draft) => {
                          draft.detailImages = fileListDone.map(({ response }) => response[0].id);
                        }),
                      );
                    }}
                    hideUpload={({ length }) => length > 8}
                    maxCount={9}
                  />
                  <Button
                    onClick={() => {
                      Modal.info({
                        title: '이미지 순서 변경',
                        centered: true,
                        content: (
                          <Space direction="vertical">
                            <Typography.Text>이미지를 선택해서 원하는 위치로 끌어서 옮겨주세요</Typography.Text>
                            <SortableTable
                              dataSource={form.detailImages.map((image) => ({ image }))}
                              columns={[
                                {
                                  title: '이미지',
                                  width: 150,
                                  dataIndex: 'image',
                                  render: (value) => (
                                    <img src={`${getFileHost()}/${value}`} alt="이미지" style={{ width: '100%' }} />
                                  ),
                                },
                                {
                                  title: '이미지 순서',
                                  render: (_, $, index) => `이미지 순서 ${index + 1}`,
                                },
                              ]}
                              onOk={(dataSource) => {
                                // console.log(
                                //   '!!!',
                                //   dataSource.map(({ image }) => image),
                                // );
                                setForm(
                                  produce((draft) => {
                                    draft.detailImages = dataSource.map(({ image }) => image);
                                  }),
                                );
                              }}
                              onCancel={() => {}}
                            />
                          </Space>
                        ),
                        okButtonProps: { style: { display: 'none' } },
                      });
                    }}
                  >
                    순서변경
                  </Button>
                  <Typography.Paragraph>
                    <ul>
                      <li>첨부파일은 jpg, jpeg, png, gif 만 첨부가 가능합니다.</li>
                      <li>권장크기: 1000 x 1000</li>
                    </ul>
                  </Typography.Paragraph>
                </Space>
              </Descriptions.Item>
            </Descriptions>
          </Collapse.Panel> */}
          <Collapse.Panel header="상세설명" key={7}>
            <Descriptions colon={false} bordered>
              <Descriptions.Item labelStyle={{ width: 180 }} label="상세설명">
                <Editor
                  editorState={form.details}
                  onEditorStateChange={(editorState) => {
                    setForm(
                      produce((draft) => {
                        draft.details = editorState;
                      }),
                    );
                  }}
                  editorStyle={{ minHeight: 350 }}
                />
              </Descriptions.Item>
            </Descriptions>
          </Collapse.Panel>
          <Collapse.Panel header="상세 주요정보" key={8}>
            <Descriptions colon={false} bordered>
              <Descriptions.Item labelStyle={{ width: 180 }} label="모델명 *" span={3}>
                <Input
                  value={form.modelName}
                  placeholder="모델명 입력"
                  onChange={(event) => {
                    setForm(
                      produce((draft) => {
                        draft.modelName = event.target.value;
                      }),
                    );
                  }}
                  style={{ width: 300 }}
                />
              </Descriptions.Item>
              <Descriptions.Item labelStyle={{ width: 180 }} label="브랜드 *" span={3}>
                <Input
                  value={form.brand}
                  placeholder="브랜드 입력"
                  onChange={(event) => {
                    setForm(
                      produce((draft) => {
                        draft.brand = event.target.value;
                      }),
                    );
                  }}
                  style={{ width: 300 }}
                />
              </Descriptions.Item>
              <Descriptions.Item labelStyle={{ width: 180 }} label="제조사 *" span={3}>
                <Input
                  value={form.manufacturer}
                  placeholder="제조사 입력"
                  onChange={(event) => {
                    setForm(
                      produce((draft) => {
                        draft.manufacturer = event.target.value;
                      }),
                    );
                  }}
                  style={{ width: 300 }}
                />
              </Descriptions.Item>
              <Descriptions.Item labelStyle={{ width: 180 }} label="kc인증여부 *" span={3}>
                <Radio.Group
                  value={form?.isKc}
                  options={[
                    { label: 'YES', value: true },
                    { label: 'NO', value: false },
                  ]}
                  optionType="button"
                  buttonStyle="solid"
                  onChange={(event) => {
                    setForm(
                      produce((draft) => {
                        draft.isKc = event.target.value;
                      }),
                    );
                  }}
                />
              </Descriptions.Item>
            </Descriptions>
          </Collapse.Panel>
          {/* <Collapse.Panel header="맞춤제작" key={9}>
            <Descriptions colon={false} bordered>
              <Descriptions.Item
                labelStyle={{ width: 180 }}
                label={<RequiredOrNotLabel label="맞춤제작" required />}
                span={3}
              >
                <Space direction="vertical" style={{ width: '100%' }}>
                  <Checkbox
                    checked={form.isCustom}
                    onChange={(event) => {
                      setForm(
                        produce((draft) => {
                          draft.isCustom = event.target.checked;
                        }),
                      );
                    }}
                  >
                    특정 주문자의 요구사항에 맞춰 개별 맞춤 제작되는 상품
                  </Checkbox>
                  {form.isCustom && (
                    <Input.TextArea
                      value={form.custom}
                      onChange={(event) => {
                        setForm(
                          produce((draft) => {
                            draft.custom = event.target.value;
                          }),
                        );
                      }}
                    />
                  )}
                </Space>
              </Descriptions.Item>
              <Descriptions.Item labelStyle={{ width: 180 }} label="제조일자">
                <DatePicker
                  value={form.manufacturingAt ? moment(form.manufacturingAt) : null}
                  onChange={(date) => {
                    setForm(
                      produce((draft) => {
                        draft.manufacturingAt = date && date.format('YYYY-MM-DD');
                      }),
                    );
                  }}
                  style={{ width: 200 }}
                />
              </Descriptions.Item>
            </Descriptions>
          </Collapse.Panel> */}
          <Collapse.Panel header="상품정보제공고시" key={10}>
            <Descriptions colon={false} bordered>
              <Descriptions.Item labelStyle={{ width: 180 }} label={<RequiredOrNotLabel label="설정여부" required />}>
                <Editor
                  editorState={form.productInformationAnnouncement}
                  onEditorStateChange={(editorState) => {
                    setForm(
                      produce((draft) => {
                        draft.productInformationAnnouncement = editorState;
                      }),
                    );
                  }}
                  editorStyle={{ minHeight: 350 }}
                />
              </Descriptions.Item>
            </Descriptions>
          </Collapse.Panel>
          <Collapse.Panel header="배송" key={11}>
            <Descriptions colon={false} bordered>
              <Descriptions.Item labelStyle={{ width: 180 }} label="배송비 템플릿 *" span={3}>
                <Space direction="vertical" style={{ width: '100%' }}>
                  {templatesOptions?.length > 0 && (
                    <Select
                      value={form.template}
                      placeholder="배송비 템플릿을 선택하세요"
                      onChange={(value) => {
                        template
                          .fetch(restApi.get(`/templates/${value}`))
                          .then(({ data: responseData }) => {
                            setForm(
                              produce((draft) => {
                                draft.template = responseData.id;
                                draft.templateName = responseData.name;
                                draft.deliveryType = responseData.methods;
                                draft.isVisit = responseData.isVisit;
                                draft.sellerAddr = responseData.sellerAddress
                                  ? {
                                      jibunAddress: responseData.sellerAddress,
                                      zonecode: responseData.sellerZipcode,
                                    }
                                  : null;
                                draft.deliveryPriceType = responseData.deliveryFreeTypeCode;
                                draft.deliveryPrice = responseData.deliveryAmount;
                                draft.deliveryPriceSum = responseData.deliveryFreeAmount;
                                draft.deliveryPriceRepeat = responseData.deliveryFreeCount;
                                draft.deliveryPaymentType = responseData.deliveryPaymentTypeCode;
                                draft.deliveryCompany = responseData.courierCode;
                                draft.isInstallationAmount = responseData.isInstallationAmount;
                                draft.returnDeliveryCompany = responseData.refundCourierCode;
                                draft.returnDeliveryPrice = responseData.refundDeliveryAmount;
                                draft.exchangeDeliveryPrice = responseData.changeDeliveryAmount;
                                draft.returnAddr = {
                                  jibunAddress: responseData.refundAddress,
                                  zonecode: responseData.refundZipcode,
                                };
                                draft.forwardingLocation = {
                                  jibunAddress: responseData.cargoAddress,
                                  zonecode: responseData.cargoZipcode,
                                };
                                draft.deliverySpecialPrice = responseData.extraAmount;
                              }),
                            );
                          })
                          .catch((error) => {
                            handleError(error);
                            Modal.warn({
                              title: '배송비 템플릿',
                              content: '배송비 템플릿 설정에 실패했습니다.',
                              centered: true,
                            });
                          });
                      }}
                      options={templatesOptions}
                      loading={templates.loading || template.loading}
                      style={{ width: 200 }}
                    />
                  )}

                  <Space align="center">
                    <Typography.Text>배송비 템플릿명</Typography.Text>
                    <Input
                      value={form.templateName}
                      onChange={(event) => {
                        setForm(
                          produce((draft) => {
                            draft.templateName = event.target.value;
                          }),
                        );
                      }}
                    />
                    <Checkbox
                      checked={form.isTemplateSave}
                      onChange={(event) => {
                        setForm(
                          produce((draft) => {
                            draft.isTemplateSave = event.target.checked;
                          }),
                        );
                      }}
                    >
                      신규 배송비 템플릿으로 저장
                    </Checkbox>
                  </Space>
                </Space>
              </Descriptions.Item>
              <Descriptions.Item
                labelStyle={{ width: 180 }}
                label={<RequiredOrNotLabel label="배송방법" required />}
                span={3}
              >
                <Space direction="vertical" style={{ width: '100%' }} size="middle">
                  <Radio.Group
                    value={form.deliveryType}
                    onChange={(event) => {
                      setForm(
                        produce((draft) => {
                          draft.deliveryType = event.target.value;
                        }),
                      );
                    }}
                    defaultValue={DELIVERY_TYPE.PARCEL}
                    options={[
                      { label: '택배, 소포, 등기', value: DELIVERY_TYPE.PARCEL },
                      { label: '직접배송(화물배달)', value: DELIVERY_TYPE.SELF },
                    ]}
                    optionType="button"
                    buttonStyle="solid"
                  />
                  <Checkbox
                    checked={form.isVisit}
                    onChange={(event) => {
                      setForm(
                        produce((draft) => {
                          draft.isVisit = event.target.checked;
                        }),
                      );
                    }}
                  >
                    방문수령
                  </Checkbox>
                  {form.isVisit && (
                    <Space>
                      <Button
                        type="primary"
                        onClick={() => {
                          Modal.info({
                            title: '판매자 주소',
                            content: (
                              <Postcode
                                style={{ marginLeft: -30 }}
                                onSelected={(data) => {
                                  setForm(
                                    produce((draft) => {
                                      draft.sellerAddr = data;
                                    }),
                                  );
                                  Modal.destroyAll();
                                }}
                              />
                            ),
                            width: 600,
                            closable: true,
                            centered: true,
                            okButtonProps: { style: { display: 'none' } },
                          });
                        }}
                      >
                        판매자 주소
                      </Button>
                      {form.sellerAddr && (
                        <Typography.Text>
                          {form.sellerAddr.jibunAddress || form.sellerAddr.address} {form.sellerAddr.buildingName} (우:{' '}
                          {form.sellerAddr.zonecode})
                        </Typography.Text>
                      )}
                    </Space>
                  )}
                  <Checkbox
                    checked={form.isQuick}
                    onChange={(event) => {
                      setForm(
                        produce((draft) => {
                          draft.isQuick = event.target.checked;
                        }),
                      );
                    }}
                  >
                    퀵서비스
                  </Checkbox>
                  <Input
                    value={form.deliveryDetails}
                    onChange={(event) => {
                      setForm(
                        produce((draft) => {
                          draft.deliveryDetails = event.target.value;
                        }),
                      );
                    }}
                    placeholder="상세내용을 입력해주세요."
                  />
                </Space>
              </Descriptions.Item>
              <Descriptions.Item
                labelStyle={{ width: 180 }}
                label={<RequiredOrNotLabel label="배송속성" required />}
                span={3}
              >
                <Space direction="vertical" style={{ width: '100%' }} size="middle">
                  <Radio.Group
                    value={form.deliveryMethodType}
                    onChange={(event) => {
                      setForm(
                        produce((draft) => {
                          draft.deliveryMethodType = event.target.value;
                        }),
                      );
                    }}
                    defaultValue={DELIVERY_METHOD_TYPE.DEFAULT}
                    options={[{ label: '일반배송', value: DELIVERY_METHOD_TYPE.DEFAULT }]}
                    optionType="button"
                    buttonStyle="solid"
                  />
                  <Checkbox
                    checked={form.isCheckOrder}
                    onChange={(event) => {
                      setForm(
                        produce((draft) => {
                          draft.isCheckOrder = event.target.checked;
                        }),
                      );
                    }}
                  >
                    주문확인 후 제작
                  </Checkbox>
                </Space>
              </Descriptions.Item>
              {form.isCheckOrder && (
                <Descriptions.Item
                  labelStyle={{ width: 180 }}
                  label={<RequiredOrNotLabel label="발송예정일" required />}
                  span={3}
                >
                  <Space align="center">
                    <Select
                      value={form.deliveryDate}
                      onChange={(value) => {
                        setForm(
                          produce((draft) => {
                            draft.deliveryDate = value;
                          }),
                        );
                      }}
                      placeholder="일자를 선택해주세요."
                      options={[...Array(13)].map((_, index) => ({ label: index + 2, value: index + 2 }))}
                    />
                    <Typography.Text>일 이내 발송 예정</Typography.Text>
                  </Space>
                </Descriptions.Item>
              )}
              <Descriptions.Item
                labelStyle={{ width: 180 }}
                label={<RequiredOrNotLabel label="상품별 배송비" required />}
                span={3}
              >
                <Space align="center">
                  <Select
                    value={form.deliveryPriceType}
                    onChange={(value) => {
                      setForm(
                        produce((draft) => {
                          draft.deliveryPriceType = value;
                        }),
                      );
                    }}
                    defaultValue={DELIVERY_PRICE_TYPE.FREE}
                    options={[
                      { label: '무료', value: DELIVERY_PRICE_TYPE.FREE },
                      { label: '유료', value: DELIVERY_PRICE_TYPE.CHARGED },
                      { label: '조건부 무료', value: DELIVERY_PRICE_TYPE.CONDITIONALLY_FREE },
                      { label: '수량별', value: DELIVERY_PRICE_TYPE.BY_COUNT },
                    ]}
                    style={{ width: 120 }}
                  />
                  <Select
                    value={form.deliveryCompany}
                    onChange={(value) => {
                      setForm(
                        produce((draft) => {
                          draft.deliveryCompany = value;
                        }),
                      );
                    }}
                    options={deliveryCompanyOptions}
                    placeholder="택배사 선택"
                    style={{ width: 200 }}
                  />
                  <Checkbox
                    checked={form.isDefaultDeliveryCompany}
                    onChange={(event) => {
                      setForm(
                        produce((draft) => {
                          draft.isDefaultDeliveryCompany = event.target.checked;
                        }),
                      );
                    }}
                  >
                    배송 기본 택배사로 설정
                  </Checkbox>
                </Space>
              </Descriptions.Item>
              {[
                DELIVERY_PRICE_TYPE.CHARGED,
                DELIVERY_PRICE_TYPE.CONDITIONALLY_FREE,
                DELIVERY_PRICE_TYPE.BY_COUNT,
              ].includes(form.deliveryPriceType) && (
                <Descriptions.Item
                  labelStyle={{ width: 180 }}
                  label={<RequiredOrNotLabel label="기본 배송비" required />}
                  span={3}
                >
                  <InputMoney
                    value={form.deliveryPrice}
                    onChange={(value) => {
                      setForm(
                        produce((draft) => {
                          draft.deliveryPrice = value;
                        }),
                      );
                    }}
                    placeholder="숫자만 입력"
                    style={{ width: 200 }}
                    suffix="원"
                  />
                </Descriptions.Item>
              )}
              {[DELIVERY_PRICE_TYPE.CONDITIONALLY_FREE].includes(form.deliveryPriceType) && (
                <Descriptions.Item
                  labelStyle={{ width: 180 }}
                  label={<RequiredOrNotLabel label="배송비 조건" required />}
                  span={3}
                >
                  <Space direction="vertical">
                    <Typography.Text>상품 판매가 합계 (할인이 적용되지 않은 판매가 + 옵션가 포함금액)</Typography.Text>
                    <Space>
                      <InputMoney
                        value={form.deliveryPriceSum}
                        onChange={(value) => {
                          setForm(
                            produce((draft) => {
                              draft.deliveryPriceSum = value;
                            }),
                          );
                        }}
                        placeholder="숫자만 입력"
                        style={{ width: 200 }}
                        suffix="원"
                      />
                      <Typography.Text>이상 무료</Typography.Text>
                    </Space>
                  </Space>
                </Descriptions.Item>
              )}
              {[DELIVERY_PRICE_TYPE.BY_COUNT].includes(form.deliveryPriceType) && (
                <Descriptions.Item
                  labelStyle={{ width: 180 }}
                  label={<RequiredOrNotLabel label="배송비 조건" required />}
                  span={3}
                >
                  <Space>
                    <InputMoney
                      placeholder="숫자만 입력"
                      value={form.deliveryPriceRepeat}
                      onChange={(value) => {
                        setForm(
                          produce((draft) => {
                            draft.deliveryPriceRepeat = value;
                          }),
                        );
                      }}
                      style={{ width: 200 }}
                      suffix="개"
                    />
                    <Typography.Text>마다 기본 배송비 반복부과</Typography.Text>
                  </Space>
                </Descriptions.Item>
              )}
              {[
                DELIVERY_PRICE_TYPE.CHARGED,
                DELIVERY_PRICE_TYPE.CONDITIONALLY_FREE,
                DELIVERY_PRICE_TYPE.BY_COUNT,
              ].includes(form.deliveryPriceType) && (
                <Descriptions.Item
                  labelStyle={{ width: 180 }}
                  label={<RequiredOrNotLabel label="결제방식" required />}
                  span={3}
                >
                  <Radio.Group
                    value={form.deliveryPaymentType}
                    onChange={(event) => {
                      setForm(
                        produce((draft) => {
                          draft.deliveryPaymentType = event.target.value;
                        }),
                      );
                    }}
                    options={[
                      { label: '착불', value: DELIVERY_PAYMENT_TYPE.COLLECTIBLE },
                      { label: '선결제', value: DELIVERY_PAYMENT_TYPE.ADVANCE },
                    ]}
                  />
                </Descriptions.Item>
              )}
              <Descriptions.Item
                labelStyle={{ width: 180 }}
                label={
                  <RequiredOrNotLabel
                    label={
                      <>
                        제주/도서상간
                        <br />
                        추가배송비
                      </>
                    }
                    required
                  />
                }
                span={3}
              >
                <InputMoney
                  value={form.deliverySpecialPrice}
                  onChange={(value) => {
                    setForm(
                      produce((draft) => {
                        draft.deliverySpecialPrice = value;
                      }),
                    );
                  }}
                  placeholder="숫자만 입력"
                  style={{ width: 200 }}
                  suffix="원"
                />
              </Descriptions.Item>
              <Descriptions.Item
                labelStyle={{ width: 180 }}
                label={<RequiredOrNotLabel label="별도 설치비" required />}
                span={3}
              >
                <Radio.Group
                  value={form?.isInstallationAmount}
                  options={[
                    { label: '있음', value: true },
                    { label: '없음', value: false },
                  ]}
                  optionType="button"
                  buttonStyle="solid"
                  onChange={(event) => {
                    setForm(
                      produce((draft) => {
                        draft.isInstallationAmount = event.target.value;
                      }),
                    );
                  }}
                />
              </Descriptions.Item>
              <Descriptions.Item
                labelStyle={{ width: 180 }}
                label={<RequiredOrNotLabel label="출고지" required />}
                span={3}
              >
                <Space>
                  <Button
                    type="primary"
                    onClick={() => {
                      Modal.info({
                        title: '출고지',
                        content: (
                          <Postcode
                            style={{ marginLeft: -30 }}
                            onSelected={(data) => {
                              setForm(
                                produce((draft) => {
                                  draft.forwardingLocation = data;
                                }),
                              );
                              Modal.destroyAll();
                            }}
                          />
                        ),
                        width: 600,
                        closable: true,
                        centered: true,
                        okButtonProps: { style: { display: 'none' } },
                      });
                    }}
                  >
                    판매자 주소
                  </Button>
                  {form.forwardingLocation && (
                    <Typography.Text>
                      {form.forwardingLocation.jibunAddress || form.forwardingLocation.address}{' '}
                      {form.forwardingLocation.buildingName} (우: {form.forwardingLocation.zonecode})
                    </Typography.Text>
                  )}
                </Space>
              </Descriptions.Item>
            </Descriptions>
          </Collapse.Panel>
          <Collapse.Panel header="반품/교환" key={12}>
            <Descriptions colon={false} bordered>
              <Descriptions.Item
                labelStyle={{ width: 180 }}
                label={<RequiredOrNotLabel label="반품/교환 택배사" required />}
                span={3}
              >
                <Space>
                  <Select
                    value={form.returnDeliveryCompany}
                    onChange={(value) => {
                      setForm(
                        produce((draft) => {
                          draft.returnDeliveryCompany = value;
                        }),
                      );
                    }}
                    options={deliveryCompanyOptions}
                    style={{ width: 200 }}
                    placeholder="택배사 선택"
                  />
                  <Typography.Text>반품택배사 설정 안내</Typography.Text>
                </Space>
              </Descriptions.Item>
              <Descriptions.Item
                labelStyle={{ width: 180 }}
                label={<RequiredOrNotLabel label="반품 배송비(편도)" required />}
                span={3}
              >
                <Space>
                  <InputMoney
                    value={form.returnDeliveryPrice}
                    onChange={(value) => {
                      setForm(
                        produce((draft) => {
                          draft.returnDeliveryPrice = value;
                        }),
                      );
                    }}
                    placeholder="숫자만 입력"
                    suffix="원"
                    style={{ width: 200 }}
                  />
                  <Typography.Text>
                    최초 배송시의 배송비가 무료인 경우 왕복 배송비 6,000원이 청구됩니다.
                  </Typography.Text>
                </Space>
              </Descriptions.Item>
              <Descriptions.Item
                labelStyle={{ width: 180 }}
                label={<RequiredOrNotLabel label="교환 배송비(왕복)" required />}
                span={3}
              >
                <InputMoney
                  value={form.exchangeDeliveryPrice}
                  onChange={(value) => {
                    setForm(
                      produce((draft) => {
                        draft.exchangeDeliveryPrice = value;
                      }),
                    );
                  }}
                  placeholder="숫자만 입력"
                  suffix="원"
                  style={{ width: 200 }}
                />
              </Descriptions.Item>
              <Descriptions.Item
                labelStyle={{ width: 180 }}
                label={<RequiredOrNotLabel label="반품/교환지" required />}
                span={3}
              >
                <Space>
                  <Button
                    type="primary"
                    onClick={() => {
                      Modal.info({
                        title: '반품/교환지',
                        content: (
                          <Postcode
                            style={{ marginLeft: -30 }}
                            onSelected={(data) => {
                              setForm(
                                produce((draft) => {
                                  draft.returnAddr = data;
                                }),
                              );
                              Modal.destroyAll();
                            }}
                          />
                        ),
                        width: 600,
                        closable: true,
                        centered: true,
                        okButtonProps: { style: { display: 'none' } },
                      });
                    }}
                  >
                    주소변경하기
                  </Button>
                  {form.returnAddr && (
                    <Typography.Text>
                      {form.returnAddr.jibunAddress || form.returnAddr.address || form.returnAddr.roadAddress}{' '}
                      {form.returnAddr.buildingName} (우: {form.returnAddr.zonecode})
                    </Typography.Text>
                  )}
                </Space>
              </Descriptions.Item>
              <Descriptions.Item
                labelStyle={{ width: 180 }}
                label={<RequiredOrNotLabel label="반품교환 안내" required />}
                span={3}
              >
                <Input.TextArea
                  value={form.returnInfo}
                  onChange={(event) => {
                    setForm(
                      produce((draft) => {
                        draft.returnInfo = event.target.value;
                      }),
                    );
                  }}
                />
              </Descriptions.Item>
            </Descriptions>
          </Collapse.Panel>
          <Collapse.Panel header="A/S 특이사항" key={13}>
            <Descriptions colon={false} bordered>
              <Descriptions.Item
                labelStyle={{ width: 180 }}
                label={<RequiredOrNotLabel label="A/S 전화번호" required />}
                span={3}
              >
                <Input
                  value={form.asPhone}
                  onChange={(event) => {
                    setForm(
                      produce((draft) => {
                        draft.asPhone = event.target.value;
                      }),
                    );
                  }}
                  style={{ width: 200 }}
                  placeholder="000-0000-0000"
                />
              </Descriptions.Item>
              <Descriptions.Item
                labelStyle={{ width: 180 }}
                label={<RequiredOrNotLabel label="A/S 안내" required />}
                span={3}
              >
                <Space direction="vertical" style={{ width: '100%' }}>
                  <Input.TextArea
                    value={form.asInfo}
                    onChange={(event) => {
                      setForm(
                        produce((draft) => {
                          draft.asInfo = event.target.value;
                        }),
                      );
                    }}
                  />
                  <Typography.Text>
                    법률에 근거하지 않은 판매자의 임의적인 A/S 규정 및 청약철회 기준 안내 시, 이용정지 및 관련 법에
                    의거하여 제재될 수 있습니다.
                  </Typography.Text>
                </Space>
              </Descriptions.Item>
              <Descriptions.Item labelStyle={{ width: 180 }} label="주의(특이) 사항">
                <Space direction="vertical">
                  <Input.TextArea
                    value={form.sellerDetails}
                    onChange={(event) => {
                      setForm(
                        produce((draft) => {
                          draft.sellerDetails = event.target.value;
                        }),
                      );
                    }}
                  />
                  <Typography.Text>
                    청약철회, 배송기간 및 방법, 교환/반품의 보증 및 추가비용, 판매일시/판매지역/판매수량/인도지역 등과
                    관련해서 특이사항이 있는 경우, 해외배송상품인 경우 입력 법률에 근거하지 않은 판매자의 임의적인
                    판매규정 및 청약철회 기준 안내 시, 이용정지 및 관련 법에 의거하여 제재될 수 있습니다.
                  </Typography.Text>
                </Space>
              </Descriptions.Item>
            </Descriptions>
          </Collapse.Panel>
        </Collapse>
        <Row justify="end" gutter={8}>
          <Col>
            {isEdit ? (
              <Link to="/goods/list">
                <Button>목록</Button>
              </Link>
            ) : (
              <Button onClick={handleCancel}>취소</Button>
            )}
          </Col>
          {/* {!isEdit && (
            <Col>
              <Button onClick={handleTempSave} loading={submit.loading}>
                임시저장
              </Button>
            </Col>
          )} */}
          <Col>
            <Button type="primary" htmlType="submit" loading={submit.loading}>
              저장
            </Button>
          </Col>
        </Row>
      </Space>
    </Form>
  );
}

export default GoodsItemsCreate;
