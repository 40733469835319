import React from 'react';
import { Space, Table } from 'antd';
import useTable from '../../hooks/useTable';
import CommTableHeader from './CommTableHeader';
import usePagination from '../../hooks/usePagination';

const CommTable = React.forwardRef(
  (
    {
      url,
      params,
      columns,
      scroll,
      customPagination,
      onAdd,
      onFetch,
      toggleObj,
      renderHeader,
      onLoadData,
      onChangePagination,
      rightBts,
      ...args
    },
    ref,
  ) => {
    const { data, setData, loadData, isLoading } = useTable({ url, params, onFetch, onLoadData });

    const { pagination, paging } = usePagination({ data, onChangePagination });
    const dataSource = React.useMemo(() => data?.results ?? [], [data]);

    React.useImperativeHandle(ref, () => ({
      data,
      setData,
      loadData,
      isLoading,
      dataSource,
    }));

    return (
      <Space direction="vertical" style={{ width: '100%', marginTop: 20 }}>
        <CommTableHeader toggleObj={toggleObj} paging={paging} onAdd={onAdd} rightBts={rightBts} />
        {renderHeader}
        <Table
          dataSource={dataSource}
          columns={typeof columns === 'function' ? columns(data ?? {}) : columns}
          scroll={scroll}
          pagination={customPagination ?? pagination}
          loading={isLoading}
          {...args}
        />
      </Space>
    );
  },
);

export default CommTable;
