import React from 'react';
import { Steps, Typography } from 'antd';
import classNames from 'classnames/bind';
import styles from './Join.module.less';
import FirstStep from '#components/partners/join/FirstStep';
import SecondStep from '#components/partners/join/SecondStep';
import ThirdStep from '#components/partners/join/ThirdStep';

const cx = classNames.bind(styles);

const { Step } = Steps;
const { Text } = Typography;

function PartnerJoin() {
  const [nowStep, setNowStep] = React.useState(0);
  const handleChange = (current) => {
    setNowStep(current);
  };

  const StepComps = React.useMemo(() => [FirstStep, SecondStep, ThirdStep], []);

  const SelectedStep = StepComps[nowStep];

  return (
    <div style={{ paddingBottom: '50px', paddingInline: 50 }}>
      <Steps current={nowStep} className={cx({ step_wrapper: true })}>
        <Step title="약관동의" />
        <Step title="정보입력" />
        <Step title="신청완료" />
      </Steps>
      <div>
        <SelectedStep handleMoveStep={handleChange} />
      </div>
      <footer className={cx({ footer: true })}>
        <div className={cx({ callCenterText: true })}>
          <Text>고객센터 콜센터 1644-1154</Text>
        </div>
        <Text>Copyright © 2022 홈그릿. ALL RIGHTS RESERVED​</Text>
      </footer>
    </div>
  );
}

export default PartnerJoin;
