import { authApi } from '.';

export const postOauthToken = (data, config) => {
  const endpoint = '/oauth/token';
  data.isPartners =true;
  const formData = Object.entries(data)

    .map(([key, value]) => {
      return `${encodeURIComponent(key)}=${encodeURIComponent(String(value))}`;
    })

    .join('&');
  return authApi.post(endpoint, formData, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    ...config,
  });
};
