import * as XLSX from 'xlsx';
import { mapKeys } from 'lodash';

const DefaultOptions = ['옵션가', '재고수량', '사용여부'];

export function downloadExcel({ title, dataJsonArray, ObjToChangeKey, options, isExclusive }) {
  // key 변경해주는 help function
  const objMapper = (obj) => {
    let newObj = mapKeys(obj, (value, key) => {
      if (ObjToChangeKey[key]) return ObjToChangeKey[key];
      return key;
    });

    if (isExclusive) return newObj;

    const hasSequenceObj = {};

    for (let y = 0; y < 3; y++) {
      if (options[y]) {
        hasSequenceObj[options[y].name] = newObj[options[y].name];
      } else {
        hasSequenceObj[`선택${y + 1}`] = null;
      }
    }

    DefaultOptions.forEach((option) => {
      hasSequenceObj[option] = newObj[option];
    });

    return hasSequenceObj;
  };

  let arrJSON = dataJsonArray.map((data) => {
    return objMapper(data);
  });

  // book이라는 게 아예 excel 파일하나라고 생각하면 편함
  let wb = XLSX.utils.book_new();

  let arrJsonToSheet = JSON.parse(JSON.stringify(arrJSON));

  let ws = XLSX.utils.json_to_sheet(arrJsonToSheet);

  wb.Props = {
    Title: title,
    Subject: 'Excel',
    Author: 'Master',
    CreatedDate: new Date(),
  };

  wb.SheetNames.push(title);

  // sheet를 추가 하고 싶을 때 아래 메서드 사용하세요
  // XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

  // 타이틀에 해당하는 sheet에 work sheet 붙여주기.
  wb.Sheets[title] = ws;

  // 해당 부분으로 excel download
  XLSX.writeFile(wb, `${title}.xlsx`);
}
