import { restApiNotUsingToken } from '#apis';
import React from 'react';

export default function useSms() {
  const [uuid, setUuid] = React.useState(null);
  const [phone, setPhone] = React.useState(null);

  async function check({ code }) {
    await restApiNotUsingToken.put('/common/sms/auth-sms/register/check', {
      uuid,
      code,
      phoneNumber: phone,
    });

    return uuid;
  }

  async function verify(phone) {
    const { data: uuid } = await restApiNotUsingToken.post('/common/sms/auth-sms/register', {
      phoneNumber: phone,
    });
    setUuid(uuid);
    setPhone(phone);
    return {
      uuid,
    };
  }

  return {
    data: {
      uuid,
      phone,
    },
    verify,
    check,
  };
}
