import React from 'react';
import { Button, Col, Divider, Input, Row, Select, Space } from 'antd';
import CommTable from '#components/table/CommTable';
import SearchForm from '#components/SearchForm';
import useIndex from '#hooks/useIndex';
import produce from 'immer';
import { Link } from 'react-router-dom';

function PartnerNoticeIndex() {
  const { getIndex } = useIndex();

  const initialParams = React.useMemo(
    () => ({
      searchType: null,
      keyword: null,
    }),
    [],
  );

  const [params, setParams] = React.useState(initialParams);

  return (
    <div>
      <SearchForm
        params={params}
        onInit={setParams}
        onReset={() => {
          setParams(initialParams);
          return initialParams;
        }}
      >
        <Row gutter={[50, 15]}>
          <Col span={24}>
            <Space>
              <Select
                placeholder="검색 옵션"
                style={{ width: 120 }}
                value={params.searchType}
                onChange={(value) => {
                  setParams(
                    produce((draft) => {
                      draft.searchType = value;
                    }),
                  );
                }}
                options={[
                  { label: '내용', value: 'CONTENTS' },
                  { label: '제목', value: 'TITLE' },
                ]}
              />
              <Input
                placeholder="키워드를 입력하세요"
                style={{ width: 300 }}
                value={params.keyword}
                onChange={(event) => {
                  setParams(
                    produce((draft) => {
                      draft.keyword = event.target.value;
                    }),
                  );
                }}
              />
            </Space>
            <Space style={{ marginLeft: 20 }}>
              <Button type="primary" htmlType="submit">
                검색
              </Button>
              <Button htmlType="reset">초기화</Button>
            </Space>
          </Col>
        </Row>
      </SearchForm>
      <Divider />
      <CommTable
        url="/partners/notices"
        columns={[
          {
            title: '번호',
            dataIndex: 'orderNum',
            width: 100,
            render: (_, __, index) => getIndex?.(index),
          },
          {
            title: '제목',
            dataIndex: 'title',
            align: 'center',
            render: (data, obj) => <Link to={`/notice/${obj?.id}`}>{data}</Link>,
          },
          {
            title: '등록일',
            dataIndex: 'createdAt',
            align: 'center',
            width: 200,
            render: (value) => value,
          },
        ]}
      />
    </div>
  );
}

export default PartnerNoticeIndex;
