import axios from 'axios';
import dayjs from 'dayjs';

export const TOKEN_SAVE_NAME = 'app_token_data';
export const TOKEN_RELOAD_MESSAGE_NAME = 'onTokenRefreshed';
const isDev = process.env.NODE_ENV === 'development';

const _tl = {
  p: false,
  s: [],
};

export const getToken = () => {
  const promise = new Promise((resolve, reject) => {
    _tl.s.push({ resolve, reject });
  });

  if (!_tl.p) {
    _tl.p = true;

    const loadData = async () => {
      if (!localStorage.getItem(TOKEN_SAVE_NAME)) return undefined;
      const token = setToken(JSON.parse(localStorage.getItem(TOKEN_SAVE_NAME)));
      if (token && (token.expires_at || 0) < dayjs().unix()) {
        const formData = new FormData();
        formData.append('grant_type', 'refresh_token');
        formData.append('refresh_token', token.refresh_token);
        const { data } = await authApi.post('/oauth/token', formData);
        setToken(data);
      }
      return token;
    };

    loadData()
      .then((r) => _tl.s.forEach((o) => o.resolve(r)))
      .catch((e) => {
        _tl.s.forEach((listener) => listener.reject(e));
        window.location.reload();
      })
      .then(() => {
        _tl.s = [];
        _tl.l = false;
      });
  }

  return promise;
};

// 'https://6005.in.actbase.io/v1'

export const getAPIHost = () => {
  return process.env.REACT_APP_API_HOST || 'https://homegrit-serv.actbase.dev';
};

export const getWebURL = () => {
  return process.env.REACT_APP_API_HOST || (isDev ? 'http://localhost:3000' : 'https://settlement.dev.kurlycorp.kr');
};

export const getFileHost = () => {
  return `${getAPIHost()}/api/files`;
};

export const replaceFile = (src) => {
  return Number(String(src).replace(`${getFileHost()}/`, ''));
};

export const restApi = axios.create({
  baseURL: `${getAPIHost()}/api`,
});

export const restApiNotUsingToken = axios.create({
  baseURL: `${getAPIHost()}/api`,
});

restApi.interceptors.request.use(async (request) => {
  if (localStorage.getItem(TOKEN_SAVE_NAME)) {
    try {
      const token = JSON.parse(localStorage.getItem(TOKEN_SAVE_NAME));

      // Refresh Token Applyed
      if ((token.expires_at || 0) < dayjs().unix()) {
        const formData = new FormData();
        formData.append('grant_type', 'refresh_token');
        formData.append('refresh_token', token.refresh_token);

        const { data } = await authApi.post('/oauth/token', formData);
        setToken(data);

        const { access_token } = data;
        token.access_token = access_token;
      }

      request.headers.common.authorization = `Bearer ${token.access_token}`;
    } catch (error) {
      setToken('');
      alert('다시 로그인 해주세요.');
      window.location.href = '/';
    }
  }

  return request;
});

let meLoading = false;
restApi.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response?.status === 403 && !meLoading) {
      meLoading = true;
      restApi
        .get(`/users/authentication`)
        .then(({ data }) => {
          // if (data.uuid !== unique_id) {
          //   alert('중복 로그인이 감지되었습니다.');
          //   setToken(null);
          //   window.location.reload();
          // }
        })
        .catch((e) => {
          setToken(null);
          window.location.reload();
        })
        .then(() => (meLoading = false));
    }
    return Promise.reject(error);
  },
);

export const authApi = axios.create({
  baseURL: getAPIHost(),
  auth: {
    username: 'homegrit-serv',
    password: 'gharmflt!@',
  },
});

export const kakaoLocalApi = axios.create({
  baseURL: 'https://dapi.kakao.com',
});

kakaoLocalApi.defaults.headers.common.authorization = `KakaoAK ${process.env.REACT_APP_KAKAO_REST_KEY}`;

export const setToken = (cookies) => {
  if (!cookies?.access_token) {
    localStorage.setItem(TOKEN_SAVE_NAME, '');
    return '';
  }

  const { access_token, refresh_token, access_token_start_at, expires_in, expires_at } = cookies;
  if (access_token) {
    const now = dayjs().unix() - 1;
    const store_data = {
      access_token,
      refresh_token,
      access_token_start_at: access_token_start_at || now,
      expires_at: expires_at || now + expires_in,
    };

    restApi.defaults.headers.common.authorization = `Bearer ${access_token}`;

    localStorage.setItem(TOKEN_SAVE_NAME, JSON.stringify(store_data));
    if (window?.opener) {
      window?.opener?.postMessage(TOKEN_RELOAD_MESSAGE_NAME, '*');
    }
    return store_data;
  }
};
