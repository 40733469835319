import React from 'react';
import { Button, Spin, Modal } from 'antd';
import classNames from 'classnames/bind';
import styles from '../../Page.module.less';
import { restApi } from '#apis';
import handleError from '#utils/handleError';
import { useReduxStore } from '#hooks/useReduxStore';
import { useNavigate } from 'react-router-dom';

const cx = classNames.bind(styles);

function PartnersStoreAuthIndex() {
  const { map } = useReduxStore('codes');
  const [hasPermissionToSell, setHasPermissionToSell] = React.useState(null);
  const [asking, setAsking] = React.useState(false);
  const navigate = useNavigate();

  const handleRequest = () => {
    Modal.confirm({
      content: '판매권한을 신청하시겠습니까?',
      onOk: async () => {
        try {
          if (asking) return;
          setAsking(true);

          setHasPermissionToSell(true);
          setAsking(false);
          setHasPermissionToSell('PASS001');
          Modal.success({
            content: '판매권한 신청을 완료했습니다.',
          });
        } catch (e) {
          setAsking(false);
          handleError(e);
        }
      },
      okText: '확인',
      cancelText: '취소',
    });
  };

  React.useEffect(() => {
    restApi
      .get('/partners/stores/permission')
      .then((response) => {
        setHasPermissionToSell(response.data);
      })
      .catch((error) => {
        Modal.error({
          title: '실패',
          content: '데이터를 불러오는데 실패했습니다. 관리자에 문의해주세요.',
          onOk: () => {
            navigate('/', {
              replace: true,
            });
          },
        });
        handleError(error);
      });
  }, []);

  if (hasPermissionToSell === null) return <Spin />;

  return (
    <div>
      <div className={cx({ windowLayout: true })}>
        <p style={{ fontSize: '30px', marginBlock: 30 }}>
          판매자님은
          <span className={cx({ textRed: true })} style={hasPermissionToSell === 'PASS002' ? { color: 'green' } : null}>
            {' '}
            {map[hasPermissionToSell]}{' '}
          </span>
          상태입니다.
        </p>
        {hasPermissionToSell !== null && (hasPermissionToSell === 'PASS003' || hasPermissionToSell === 'PASS004') && (
          <p style={{ marginTop: 40 }}>&#8251; 이용정지 사유를 확인하시려면 고객센터 1644-1154로 문의주시기 바랍니다.</p>
        )}
        {hasPermissionToSell !== null && hasPermissionToSell !== 'PASS002' && hasPermissionToSell !== 'PASS001' && (
          <Button disabled={asking} type="primary" style={{ marginTop: 30 }} onClick={handleRequest}>
            판매권한 신청
          </Button>
        )}
      </div>
    </div>
  );
}

export default PartnersStoreAuthIndex;
