import { Button, Col, Form, Input, Modal, Row, Select, Space } from 'antd';
import React from 'react';
import RegexObj from '#utils/regex';
import { restApi } from '#apis';
import handleError from '#utils/handleError';

const ChangeInvoice = ({ changeIds, onRefresh, codesTree, modalTitle }) => {
  const [method, setMethod] = React.useState(null);
  const [courierCode, setCourierCode] = React.useState(null);
  const [invoiceNumber, setInvoiceNumber] = React.useState(null);

  return (
    <Form
      onFinish={async () => {
        try {
          if (!method) {
            return alert('배송방법을 선택해주세요');
          }

          if (method !== 'DM003') {
            if (!courierCode) {
              return alert('택배사를 선택해주세요');
            }

            if (!invoiceNumber) {
              return alert('송장번호를 입력해주세요');
            }

            if (!RegexObj.onlyNumber.test(invoiceNumber)) {
              return alert('송장번호는 숫자만 입력가능합니다.');
            }
          }

          await Promise.all(
            changeIds.map((value) =>
              restApi.put(`/cms/account-orders/change-order/${value}/delivery-access`, {
                method,
                courierCode,
                invoiceNumber,
              }),
            ),
          );

          onRefresh();

          Modal.destroyAll();
          Modal.success({
            title: `${modalTitle}`,
            content: `${modalTitle} 처리 되었습니다.`,
            centered: true,
            okText: '확인',
          });
        } catch (e) {
          handleError(e);
          Modal.error({
            title: `${modalTitle}`,
            content: `${modalTitle} 처리 실패했습니다.`,
            centered: true,
            okText: '확인',
          });
        }
      }}
    >
      <Space direction="vertical" size="middle" style={{ width: '100%' }}>
        <Form.Item label="배송방법">
          <Select
            style={{ width: 200 }}
            value={method}
            onChange={(value) => {
              setMethod(value);
              if (value === 'DM003') {
                setCourierCode(null);
                setInvoiceNumber(null);
              }
            }}
            placeholder="배송방법"
            options={codesTree?.DM.items.map((value) => ({ label: value.label, value: value.code })) || []}
          />
        </Form.Item>
        <Form.Item label="택배사">
          <Select
            style={{ width: 200 }}
            disabled={method === 'DM003'}
            placeholder="택배사"
            value={courierCode}
            onChange={(value) => setCourierCode(value)}
            options={codesTree?.DV.items.map((value) => ({ label: value.label, value: value.code })) || []}
          />
        </Form.Item>
        <Form.Item label="송장번호 입력">
          <Input
            disabled={method === 'DM003'}
            type="text"
            placeholder="송장번호"
            onChange={(event) => setInvoiceNumber(event.target.value)}
            value={invoiceNumber}
          />
        </Form.Item>
        <Row justify="end" style={{ marginBottom: -25 }} gutter={10}>
          <Col>
            <Button type="primary" htmlType="submit">
              확인
            </Button>
          </Col>
          <Col>
            <Button
              onClick={() => {
                Modal.destroyAll();
              }}
            >
              취소
            </Button>
          </Col>
        </Row>
      </Space>
    </Form>
  );
};

export default ChangeInvoice;
