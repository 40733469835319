import React from 'react';
import { restApi } from '#apis';
import { Button, Modal, Spin } from 'antd';
import handleError from '#utils/handleError';
import OrderInfoModalContent from '#components/Modal/InnerContent/OrderInfoModalContent';
import { useReduxStore } from '#hooks/useReduxStore';

const ShowOrderDetailBtn = ({ orderId, title, ...args }) => {
  const { map: codesMap } = useReduxStore('codes');

  const [calling, setCalling] = React.useState(false);

  const handleClick = React.useCallback(() => {
    if (calling) return;
    setCalling(true);
    restApi
      .get(`/partners/account-orders/${orderId}`)
      .then(({ data: responseData }) => {
        Modal.info({
          title: '상품주문정보 조회',
          style: { padding: 50 },
          content: <OrderInfoModalContent map={codesMap} {...responseData} />,
          centered: true,
          closable: true,
          width: 1200,
          okText: '닫기',
        });
      })
      .catch((error) => {
        handleError(error);
        Modal.warn({
          title: '상품주문정보 조회',
          content: '상품주문정보 조회에 실패했습니다.',
          centered: true,
        });
      })
      .finally(() => {
        setCalling(false);
      });
  }, [orderId, calling, codesMap]);

  return (
    <Button disabled={calling} type="text" style={{ fontSize: 12 }} onClick={handleClick} {...args}>
      {calling ? <Spin size="small" /> : title || `${orderId}`}
    </Button>
  );
};

export default React.memo(ShowOrderDetailBtn);
