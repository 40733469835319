import React from 'react';
import { Divider, Modal, Spin } from 'antd';
import CardWrapper from '#components/common/CardWrapper';
import formatComma from '#utils/formatComma';
import styles from './Page.module.less';
import classNames from 'classnames/bind';
import handleError from '#utils/handleError';
import { restApi } from '../apis';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import qs from 'query-string';
import { useReduxStore } from '../hooks/useReduxStore';

const cx = classNames.bind(styles);

function HomeIndex() {
  const [dashboardInfo, setDashboardInfo] = React.useState(null);
  const { principal } = useReduxStore('auth');
  const navigate = useNavigate();
  const today = React.useMemo(() => moment().format('YYYY-MM-DD'), []);

  const dayBeforeSeven = React.useMemo(() => moment().subtract(7, 'd').format('YYYY-MM-DD'), []);

  const handleNavigate = React.useCallback(
    (url, params) => () => {
      if (params) {
        navigate(`${url}?${qs.stringify(params)}`);
        return;
      }

      navigate(`${url}`);
    },
    [navigate],
  );

  const requestData = React.useCallback(() => {
    restApi
      .get('/partners/dashboard')
      .then(({ data }) => {
        setDashboardInfo(data);
      })
      .catch((e) => {
        handleError(e);
      });
  }, []);

  React.useEffect(() => {
    if (!requestData) return;

    requestData();
    const intervalId = setInterval(requestData, 10000);

    return () => {
      clearInterval(intervalId);
    };
  }, [requestData]);

  React.useEffect(() => {
    if (principal) {
      if (!principal.writeInfo) {
        Modal.info({
          title: '상점 등록',
          content: '상점 등록 후 이용 가능합니다.',
          width: 600,
          closable: true,
          centered: true,
          onOk: () => {
            navigate('/store/modify');
          },
        });
      }
    }
  }, [principal, navigate]);

  if (!dashboardInfo || !principal?.writeInfo) return <Spin />;

  return (
    <div>
      <div className={cx({ dashboard_section: true })}>
        <div style={{ margin: 10 }}>
          <label>주문</label>
          <div style={{ flexDirection: 'row', display: 'flex', marginLeft: -5 }}>
            <CardWrapper
              onClick={handleNavigate('/sales/total_order', {
                dateType: 'PayAt',
                startAt: today,
                endAt: today,
              })}
              title="오늘"
              value={formatComma(dashboardInfo.order.today)}
            />
            <CardWrapper
              onClick={handleNavigate('/sales/total_order', {
                dateType: 'PayAt',
                startAt: dayBeforeSeven,
                endAt: today,
              })}
              title="최근 7일"
              value={formatComma(dashboardInfo.order.week)}
            />
          </div>
        </div>
        <div style={{ margin: 10 }}>
          <label>견적의뢰</label>
          <div style={{ flexDirection: 'row', display: 'flex', marginLeft: -5 }}>
            <CardWrapper
              onClick={handleNavigate('/sales/request_estimate', {
                dateType: 'CreatedAt',
                startAt: dayBeforeSeven,
                endAt: today,
              })}
              title="오늘"
              value={formatComma(dashboardInfo.estimate.today)}
            />
            <CardWrapper
              onClick={handleNavigate('/sales/request_estimate', {
                dateType: 'CreatedAt',
                startAt: today,
                endAt: today,
              })}
              title="최근 7일"
              value={formatComma(dashboardInfo.estimate.week)}
            />
          </div>
        </div>
        {/* TODO :::: 매출 섹션이 따로 없어서 클릭 이벤트를 담을 수 없음 */}
        <div style={{ margin: 10 }}>
          <label>매출</label>
          <div style={{ flexDirection: 'row', display: 'flex', marginLeft: -5 }}>
            <CardWrapper
              // onClick={handleNavigate('/sales/calculate', {
              //   dateType: 'ProcessAt',
              //   startAt: today,
              //   endAt: today,
              // })}
              title="오늘"
              value={formatComma(dashboardInfo.sales.today)}
            />
            <CardWrapper
              // onClick={handleNavigate('/sales/calculate', {
              //   dateType: 'ProcessAt',
              //   startAt: dayBeforeSeven,
              //   endAt: today,
              // })}
              title="최근 7일"
              value={formatComma(dashboardInfo.sales.week)}
            />
          </div>
        </div>
      </div>
      <Divider />
      {/* TODO :::: 미발주 onClick */}
      <div className={cx({ dashboard_section: true })}>
        <div style={{ margin: 10 }}>
          <label>미발주</label>
          <div style={{ flexDirection: 'row', display: 'flex', marginLeft: -5 }}>
            <CardWrapper onClick={() => {}} title="오늘" value={formatComma(dashboardInfo.unordered.today)} />
            <CardWrapper onClick={() => {}} title="최근 7일" value={formatComma(dashboardInfo.unordered.week)} />
          </div>
        </div>
        <div style={{ margin: 10 }}>
          <label>발주확인(배송대기)</label>
          <div style={{ flexDirection: 'row', display: 'flex', marginLeft: -5 }}>
            <CardWrapper
              onClick={handleNavigate('/sales/deliver', {
                dateType: 'PayAt',
                startAt: today,
                endAt: today,
                orderProductStateCode: 'OPS002',
              })}
              title="오늘"
              value={formatComma(dashboardInfo.ordered.today)}
            />
            <CardWrapper
              onClick={handleNavigate('/sales/deliver', {
                dateType: 'PayAt',
                startAt: dayBeforeSeven,
                endAt: today,
                orderProductStateCode: 'OPS002',
              })}
              title="최근 7일"
              value={formatComma(dashboardInfo.ordered.week)}
            />
          </div>
        </div>
      </div>
      <Divider />
      <div className={cx({ dashboard_section: true })}>
        <div style={{ margin: 10 }}>
          <label>배송중</label>
          <div style={{ flexDirection: 'row', display: 'flex', marginLeft: -5 }}>
            <CardWrapper
              onClick={handleNavigate('/sales/deliver', {
                dateType: 'PayAt',
                startAt: today,
                endAt: today,
                orderProductStateCode: 'OPS005',
              })}
              title="오늘"
              value={formatComma(dashboardInfo.shipping.today)}
            />
            <CardWrapper
              onClick={handleNavigate('/sales/deliver', {
                dateType: 'PayAt',
                startAt: dayBeforeSeven,
                endAt: today,
                orderProductStateCode: 'OPS005',
              })}
              title="최근 7일"
              value={formatComma(dashboardInfo.shipping.week)}
            />
          </div>
        </div>
        <div style={{ margin: 10 }}>
          <label>배송완료</label>
          <div style={{ flexDirection: 'row', display: 'flex', marginLeft: -5 }}>
            <CardWrapper
              onClick={handleNavigate('/sales/deliver', {
                dateType: 'PayAt',
                startAt: today,
                endAt: today,
                orderProductStateCode: 'OPS006',
              })}
              title="오늘"
              value={formatComma(dashboardInfo.shipped.today)}
            />
            <CardWrapper
              onClick={handleNavigate('/sales/deliver', {
                dateType: 'PayAt',
                startAt: dayBeforeSeven,
                endAt: today,
                orderProductStateCode: 'OPS006',
              })}
              title="최근 7일"
              value={formatComma(dashboardInfo.shipped.week)}
            />
          </div>
        </div>
        {/* TODO ::: 구매 확정 onClick */}
        <div style={{ margin: 10 }}>
          <label>구매확정(오늘)</label>
          <div style={{ flexDirection: 'row', display: 'flex', marginLeft: -5 }}>
            <CardWrapper onClick={() => {}} title="오늘" value={formatComma(dashboardInfo.applyOrder)} />
          </div>
        </div>
      </div>
      <Divider />
      <div className={cx({ dashboard_section: true })}>
        <div style={{ margin: 10 }}>
          <label>교환</label>
          <div style={{ flexDirection: 'row', display: 'flex', marginLeft: -5 }}>
            <CardWrapper
              onClick={handleNavigate('sales/exchange', {
                dateType: 'RequestAt',
                startAt: dayBeforeSeven,
                endAt: today,
                changeStateCode: 'CHS001',
              })}
              title="신청"
              value={formatComma(dashboardInfo.exchange.requested)}
            />
            <CardWrapper
              onClick={handleNavigate('sales/exchange', {
                dateType: 'RequestAt',
                startAt: dayBeforeSeven,
                endAt: today,
                changeStateCode: 'CHS002',
              })}
              title="진행중"
              value={formatComma(dashboardInfo.exchange.proceeding)}
            />
            <CardWrapper
              onClick={handleNavigate('sales/exchange', {
                dateType: 'RequestAt',
                startAt: dayBeforeSeven,
                endAt: today,
                changeStateCode: 'CHS003',
              })}
              title="완료"
              value={formatComma(dashboardInfo.exchange.completed)}
            />
          </div>
        </div>
        <div style={{ margin: 10 }}>
          <label>반품</label>
          <div style={{ flexDirection: 'row', display: 'flex', marginLeft: -5 }}>
            <CardWrapper
              onClick={handleNavigate('/sales/refund', {
                startAt: dayBeforeSeven,
                endAt: today,
                returnStateCode: 'RTS001',
              })}
              title="신청"
              value={formatComma(dashboardInfo.refund.requested)}
            />
            <CardWrapper
              onClick={handleNavigate('/sales/refund', {
                startAt: dayBeforeSeven,
                endAt: today,
                returnStateCode: 'RTS002',
              })}
              title="진행중"
              value={formatComma(dashboardInfo.refund.proceeding)}
            />
            <CardWrapper
              onClick={handleNavigate('/sales/refund', {
                startAt: dayBeforeSeven,
                endAt: today,
                returnStateCode: 'RTS003',
              })}
              title="완료"
              value={formatComma(dashboardInfo.refund.completed)}
            />
          </div>
        </div>
        <div style={{ margin: 10 }}>
          <label>취소</label>
          <div style={{ flexDirection: 'row', display: 'flex', marginLeft: -5 }}>
            <CardWrapper
              onClick={handleNavigate('/sales/cancel', {
                dateType: 'RequestAt',
                cancelCode: 'CAS001',
                startAt: dayBeforeSeven,
                endAt: today,
              })}
              title="신청"
              value={formatComma(dashboardInfo.cancel.requested)}
            />
            <CardWrapper
              onClick={handleNavigate('/sales/cancel', {
                dateType: 'RequestAt',
                cancelCode: 'CAS002',
                startAt: dayBeforeSeven,
                endAt: today,
              })}
              title="진행중"
              value={formatComma(dashboardInfo.cancel.proceeding)}
            />
            <CardWrapper
              onClick={handleNavigate('/sales/cancel', {
                dateType: 'RequestAt',
                cancelCode: 'CAS003',
                startAt: dayBeforeSeven,
                endAt: today,
              })}
              title="완료"
              value={formatComma(dashboardInfo.cancel.completed)}
            />
          </div>
        </div>
      </div>
      <Divider />
      <div className={cx({ dashboard_section: true })}>
        <div style={{ margin: 10 }}>
          <label>정산</label>
          <div style={{ flexDirection: 'row', display: 'flex', marginLeft: -5 }}>
            <CardWrapper
              onClick={handleNavigate('/sales/calculate', {
                isProcess: 'false',
              })}
              title="정산가능금액(예치금)"
              value={formatComma(dashboardInfo.settlement.available)}
            />
            <CardWrapper
              onClick={handleNavigate('/sales/calculate', {
                dateType: 'CreatedAt',
                startAt: dayBeforeSeven,
                endAt: today,
                isProcess: 'false',
              })}
              title="정산신청금액(최근 7일)"
              value={formatComma(dashboardInfo.settlement.requested)}
            />
            <CardWrapper
              onClick={handleNavigate('/sales/calculate', {
                dateType: 'ProcessAt',
                startAt: dayBeforeSeven,
                endAt: today,
                isProcess: 'true',
              })}
              title="정산처리금액(최근 7일)"
              value={formatComma(dashboardInfo.settlement.completed)}
            />
          </div>
        </div>
      </div>
      <Divider />
      {/* <div className={cx({ dashboard_section: true })}>
        <div style={{ margin: 10 }}>
          <label>광고</label>
          <div style={{ flexDirection: 'row', display: 'flex', marginLeft: -5 }}>
            <CardWrapper
              onClick={handleNavigate()}
              title="신청중인 광고"
              value={formatComma(dashboardInfo.ad.requested)}
            />
            <CardWrapper
              onClick={handleNavigate()}
              title="진행중인 광고"
              value={formatComma(dashboardInfo.ad.proceeding)}
            />
            <CardWrapper
              onClick={handleNavigate()}
              title="7일 이내 종료 광고"
              value={formatComma(dashboardInfo.ad.completed)}
            />
          </div>
        </div>
      </div> */}
    </div>
  );
}

export default HomeIndex;
