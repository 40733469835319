import React from 'react';
import Postcode from '@actbase/react-daum-postcode';
import produce from 'immer';
import { Button, Input, Select, Checkbox, Modal, Radio, Descriptions, Space, Typography, Col, Row, Spin } from 'antd';
import { shallowEqual, useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { restApi } from '#apis';
import InputMoney from '#components/Input/InputMoney';
import handleError from '#utils/handleError';
import useFetch from '#hooks/useFetch';
import RequiredOrNotLabel from '#components/RequiredOrNotLabel';

const DELIVERY_TYPE = {
  PARCEL: '택배',
  SELF: '직접배송',
};

const DELIVERY_PRICE_TYPE = {
  FREE: 'DFT001',
  CHARGED: 'DFT002',
  CONDITIONALLY_FREE: 'DFT003',
  BY_COUNT: 'DFT004',
};

const DELIVERY_PAYMENT_TYPE = {
  COLLECTIBLE: 'DPT001',
  ADVANCE: 'DPT002',
};

function PartnersChargeTemplateCreate() {
  const { id } = useParams();
  const isModify = React.useMemo(() => Boolean(id), [id]);
  const navigate = useNavigate();
  const { tree: codesTree } = useSelector((selector) => selector?.codes ?? {}, shallowEqual);
  const template = useFetch();
  const [form, setForm] = React.useState({
    title: '',
    deliveryType: DELIVERY_TYPE.PARCEL,
    isVisit: false,
    isMoreVisit: false,
    sellerAddr: null,
    deliveryPriceType: DELIVERY_PRICE_TYPE.FREE,
    deliveryPrice: null,
    deliveryPriceSum: null,
    deliveryPriceRepeat: null,
    deliveryPaymentType: null,
    deliveryCompany: null,
    isSameDelivery: false,
    isInstallationAmount: false,
    returnDeliveryCompany: null,
    returnDeliveryPrice: null,
    exchangeDeliveryPrice: null,
    returnAddr: null,
    isMoreReturnAddr: false,
    extraAmount: null,
    cargoAddr: null,
  });

  const deliveryCompanyOptions = React.useMemo(
    () => codesTree?.DV?.items?.map(({ code, label }) => ({ label, value: code })) ?? [],
    [codesTree],
  );
  const submit = useFetch();

  const handleSubmit = React.useCallback(async () => {
    function exception(error) {
      Modal.warn({
        title: '알림',
        content: error,
        centered: true,
      });
    }

    // 배송비 템플릿 명
    if (!form.title) return exception('배송비 템플릿 명을 입력하세요.');

    // 배송방법
    if (!form.deliveryType) return exception('배송방법을 선택하세요.');

    // 방문수령
    if (form.isVisit === true) {
      if (!form.sellerAddr) return exception('방문수령 주소지를 등록하세요.');
    }

    // 상품별 배송비
    switch (form.deliveryPriceType) {
      // 무료
      case DELIVERY_PRICE_TYPE.FREE:
        break;
      // 유료
      case DELIVERY_PRICE_TYPE.CHARGED:
        if (form.deliveryPrice === '') return exception('기본 배송비를 입력하세요.');
        if (form.deliveryPaymentType === '') return exception('결제방식을 선택하세요.');
        break;
      // 조건부 무료
      case DELIVERY_PRICE_TYPE.CONDITIONALLY_FREE:
        if (form.deliveryPrice === '') return exception('기본 배송비를 입력하세요.');
        if (form.deliveryPriceSum === '') return exception('배송비 조건을 입력하세요.');
        if (form.deliveryPaymentType === '') return exception('결제방식을 선택하세요.');
        break;
      // 수량별
      case DELIVERY_PRICE_TYPE.BY_COUNT:
        if (form.deliveryPrice === '') return exception('기본 배송비를 입력하세요.');
        if (form.deliveryPriceRepeat === '') return exception('배송비 조건을 입력하세요.');
        if (form.deliveryPaymentType === '') return exception('결제방식을 선택하세요.');
        break;
    }

    if (form.isInstallationAmount === null) return exception('별도 설치비를 선택하세요.');

    if (!form.deliveryCompany) return exception('배송 택배사를 선택하세요.');

    if (form.isSameDelivery !== true) {
      if (!form.returnDeliveryCompany) return exception('반품/교환 택배사를 선택하세요.');
    }

    if (form.returnDeliveryPrice === '') return exception('반품 배송비를 입력하세요.');

    if (form.exchangeDeliveryPrice === '') return exception('교환 배송비를 입력하세요.');

    if (!form.cargoAddr) return exception('출고지를 등록하세요.');

    if (!form.returnAddr) return exception('반품/교환처를 등록하세요.');

    const fetch = isModify
      ? (data) => restApi.put(`/templates/${id}`, data)
      : (data) => restApi.post('/templates', data);

    try {
      const { data: responseData } = await submit.fetch(
        fetch({
          name: String(form.title),
          methods: String(form.deliveryType),
          isVisit: Boolean(form.isVisit),
          isMoreVisit: Boolean(form.isMoreVisit),
          deliveryFreeTypeCode: String(form.deliveryPriceType),
          deliveryPaymentTypeCode: String(form.deliveryPaymentType),
          isInstallationAmount: Boolean(form.isInstallationAmount),
          courierCode: String(form.deliveryCompany),
          isSameDelivery: Boolean(form.isSameDelivery),
          refundCourierCode: String(form.isSameDelivery ? form.deliveryCompany : form.returnDeliveryCompany),
          sellerAddress: form.isVisit ? String(form.sellerAddr?.address ?? '') : null,
          sellerZipcode: form.isVisit ? String(form.sellerAddr?.zonecode ?? '') : null,
          deliveryAmount: form.deliveryPriceType !== DELIVERY_PRICE_TYPE.FREE ? Number(form.deliveryPrice) : null,
          refundDeliveryAmount: Number(form.returnDeliveryPrice),
          changeDeliveryAmount: Number(form.exchangeDeliveryPrice),
          extraAmount: Number(form.extraAmount),
          refundAddress: String(form.returnAddr?.address ?? form.returnAddr?.jibunAddress),
          refundZipcode: String(form.returnAddr?.zonecode ?? ''),
          cargoAddress: String(form.cargoAddr?.address ?? form.cargoAddr?.jibunAddress),
          cargoZipcode: String(form.cargoAddr?.zonecode ?? ''),
          deliveryFreeCount:
            form.deliveryPriceType === DELIVERY_PRICE_TYPE.BY_COUNT ? Number(form.deliveryPriceRepeat) : null,
          deliveryFreeAmount:
            form.deliveryPriceType === DELIVERY_PRICE_TYPE.CONDITIONALLY_FREE ? Number(form.deliveryPriceSum) : null,
          isMoreReturnAddr: Boolean(form.isMoreReturnAddr),
        }),
      );
      if (responseData) {
        Modal.success({
          title: '알림',
          content: isModify ? '템플릿이 수정되었습니다.' : '템플릿이 등록되었습니다.',
          onOk: () => {
            navigate('/goods/delivery_template', {
              replace: true,
            });
          },
          centered: true,
        });
      }
    } catch (error) {
      handleError(error);
    }
  }, [form, id, isModify]);

  React.useEffect(() => {
    if (isModify) {
      template.fetch(restApi.get(`/templates/${id}`)).then(({ data: responseData }) => {
        setForm({
          title: responseData.name,
          deliveryType: responseData.methods,
          isVisit: responseData.isVisit,
          isMoreVisit: null,
          sellerAddr: responseData.sellerAddress
            ? {
                address: responseData.sellerAddress,
                zonecode: responseData.sellerZipcode,
              }
            : null,
          deliveryPriceType: responseData.deliveryFreeTypeCode,
          deliveryPrice: responseData.deliveryAmount,
          deliveryPriceSum: responseData.deliveryFreeAmount,
          deliveryPriceRepeat: responseData.deliveryFreeCount,
          deliveryPaymentType: responseData.deliveryPaymentTypeCode,
          deliveryCompany: responseData.courierCode,
          isSameDelivery: responseData.isSameDelivery,
          isInstallationAmount: responseData.isInstallationAmount,
          returnDeliveryCompany: responseData.refundCourierCode,
          returnDeliveryPrice: responseData.refundDeliveryAmount,
          exchangeDeliveryPrice: responseData.changeDeliveryAmount,
          returnAddr: {
            address: responseData.refundAddress,
            zonecode: responseData.refundZipcode,
          },
          extraAmount: responseData.extraAmount,
          cargoAddr: {
            address: responseData.cargoAddress,
            zonecode: responseData.cargoZipcode,
          },
          isMoreReturnAddr: responseData.isMoreReturnAddr,
        });
      });
    }
  }, []);

  if (template.loading) {
    return <Spin />;
  }

  return (
    <Space direction="vertical" style={{ width: '100%' }}>
      <Descriptions colon={false} bordered>
        <Descriptions.Item
          labelStyle={{ width: 180 }}
          label={<RequiredOrNotLabel label="배송비 템플릿 명" required />}
          span={3}
        >
          <Input
            value={form.title}
            onChange={(event) => {
              setForm(
                produce((draft) => {
                  draft.title = event.target.value;
                }),
              );
            }}
            placeholder="배송비 템플릿 명을 입력하세요."
          />
        </Descriptions.Item>
        <Descriptions.Item
          labelStyle={{ width: 180 }}
          label={<RequiredOrNotLabel label="배송방법" required />}
          span={3}
        >
          <Space direction="vertical" style={{ width: '100%' }} size="middle">
            <Radio.Group
              value={form.deliveryType}
              onChange={(event) => {
                setForm(
                  produce((draft) => {
                    draft.deliveryType = event.target.value;
                  }),
                );
              }}
              defaultValue={DELIVERY_TYPE.PARCEL}
              options={[
                { label: '택배, 소포, 등기', value: DELIVERY_TYPE.PARCEL },
                { label: '직접배송(화물배달)', value: DELIVERY_TYPE.SELF },
              ]}
              optionType="button"
              buttonStyle="solid"
            />
            <Checkbox
              checked={form.isVisit}
              onChange={(event) => {
                setForm(
                  produce((draft) => {
                    draft.isVisit = event.target.checked;
                  }),
                );
              }}
            >
              방문수령
            </Checkbox>
            {form.isVisit && (
              <Space>
                <Button
                  type="primary"
                  onClick={() => {
                    Modal.info({
                      title: '주소변경',
                      content: (
                        <Postcode
                          style={{ marginLeft: -30 }}
                          onSelected={(data) => {
                            setForm(
                              produce((draft) => {
                                draft.sellerAddr = data;
                              }),
                            );
                            Modal.destroyAll();
                          }}
                        />
                      ),
                      width: 600,
                      closable: true,
                      centered: true,
                      okButtonProps: { style: { display: 'none' } },
                    });
                  }}
                >
                  주소변경하기
                </Button>
                {form.sellerAddr && (
                  <Typography.Text>
                    {form.sellerAddr.address} {form.sellerAddr.buildingName} (우: {form.sellerAddr.zonecode})
                  </Typography.Text>
                )}
                <Checkbox
                  checked={form.isMoreVisit}
                  onChange={(event) => {
                    setForm(
                      produce((draft) => {
                        draft.isMoreVisit = event.target.checked;
                      }),
                    );
                  }}
                >
                  방문수령지로 계속 사용하기
                </Checkbox>
              </Space>
            )}
          </Space>
        </Descriptions.Item>
        <Descriptions.Item
          labelStyle={{ width: 180 }}
          label={<RequiredOrNotLabel label="상품별 배송비" required />}
          span={3}
        >
          <Space align="center">
            <Select
              value={form.deliveryPriceType}
              onChange={(value) => {
                setForm(
                  produce((draft) => {
                    draft.deliveryPriceType = value;
                  }),
                );
              }}
              defaultValue={DELIVERY_PRICE_TYPE.FREE}
              options={[
                { label: '무료', value: DELIVERY_PRICE_TYPE.FREE },
                { label: '유료', value: DELIVERY_PRICE_TYPE.CHARGED },
                { label: '조건부 무료', value: DELIVERY_PRICE_TYPE.CONDITIONALLY_FREE },
                { label: '수량별', value: DELIVERY_PRICE_TYPE.BY_COUNT },
              ]}
              style={{ width: 120 }}
            />
          </Space>
        </Descriptions.Item>
        {[DELIVERY_PRICE_TYPE.CHARGED, DELIVERY_PRICE_TYPE.CONDITIONALLY_FREE, DELIVERY_PRICE_TYPE.BY_COUNT].includes(
          form.deliveryPriceType,
        ) && (
          <Descriptions.Item
            labelStyle={{ width: 180 }}
            label={<RequiredOrNotLabel label="기본배송비" required />}
            span={3}
          >
            <InputMoney
              value={form.deliveryPrice}
              onChange={(value) => {
                setForm(
                  produce((draft) => {
                    draft.deliveryPrice = value;
                  }),
                );
              }}
              placeholder="숫자만 입력"
              style={{ width: 200 }}
              suffix="원"
            />
          </Descriptions.Item>
        )}
        {[DELIVERY_PRICE_TYPE.CONDITIONALLY_FREE].includes(form.deliveryPriceType) && (
          <Descriptions.Item
            labelStyle={{ width: 180 }}
            label={<RequiredOrNotLabel label="배송비 조건" required />}
            span={3}
          >
            <Space direction="vertical">
              <Typography.Text>상품 판매가 합계 (할인이 적용되지 않은 판매가 + 옵션가 포함금액)</Typography.Text>
              <Space>
                <InputMoney
                  value={form.deliveryPriceSum}
                  onChange={(value) => {
                    setForm(
                      produce((draft) => {
                        draft.deliveryPriceSum = value;
                      }),
                    );
                  }}
                  placeholder="숫자만 입력"
                  style={{ width: 200 }}
                  suffix="원"
                />
                <Typography.Text>이상 무료</Typography.Text>
              </Space>
            </Space>
          </Descriptions.Item>
        )}
        {[DELIVERY_PRICE_TYPE.BY_COUNT].includes(form.deliveryPriceType) && (
          <Descriptions.Item
            labelStyle={{ width: 180 }}
            label={<RequiredOrNotLabel label="배송비 조건" required />}
            span={3}
          >
            <Space>
              <InputMoney
                placeholder="숫자만 입력"
                value={form.deliveryPriceRepeat}
                onChange={(value) => {
                  setForm(
                    produce((draft) => {
                      draft.deliveryPriceRepeat = value;
                    }),
                  );
                }}
                style={{ width: 200 }}
                suffix="개"
              />
              <Typography.Text>마다 기본 배송비 반복부과</Typography.Text>
            </Space>
          </Descriptions.Item>
        )}
        {[DELIVERY_PRICE_TYPE.CHARGED, DELIVERY_PRICE_TYPE.CONDITIONALLY_FREE, DELIVERY_PRICE_TYPE.BY_COUNT].includes(
          form.deliveryPriceType,
        ) && (
          <Descriptions.Item
            labelStyle={{ width: 180 }}
            label={<RequiredOrNotLabel label="결제방식" required />}
            span={3}
          >
            <Radio.Group
              value={form.deliveryPaymentType}
              onChange={(event) => {
                setForm(
                  produce((draft) => {
                    draft.deliveryPaymentType = event.target.value;
                  }),
                );
              }}
              options={[
                { label: '착불', value: DELIVERY_PAYMENT_TYPE.COLLECTIBLE },
                { label: '선결제', value: DELIVERY_PAYMENT_TYPE.ADVANCE },
              ]}
            />
          </Descriptions.Item>
        )}
        <Descriptions.Item
          labelStyle={{ width: 180 }}
          label={<RequiredOrNotLabel label="별도 설치비" required />}
          span={3}
        >
          <Radio.Group
            value={form.isInstallationAmount}
            options={[
              { label: '있음', value: true },
              { label: '없음', value: false },
            ]}
            optionType="button"
            buttonStyle="solid"
            onChange={(event) => {
              setForm(
                produce((draft) => {
                  draft.isInstallationAmount = event.target.value;
                }),
              );
            }}
          />
        </Descriptions.Item>
        <Descriptions.Item labelStyle={{ width: 180 }} label={<RequiredOrNotLabel label="택배사" required />} span={3}>
          <Descriptions colon={false} bordered>
            <Descriptions.Item label="배송" labelStyle={{ width: 120 }} span={3}>
              <Space>
                <Select
                  value={form.deliveryCompany}
                  onChange={(value) => {
                    setForm(
                      produce((draft) => {
                        draft.deliveryCompany = value;
                      }),
                    );
                  }}
                  options={deliveryCompanyOptions}
                  style={{ width: 200 }}
                  placeholder="택배사 선택"
                />
              </Space>
            </Descriptions.Item>
            <Descriptions.Item label="반품/교환" labelStyle={{ width: 120 }}>
              <Space>
                <Select
                  value={form.isSameDelivery ? form.deliveryCompany : form.returnDeliveryCompany}
                  onChange={(value) => {
                    setForm(
                      produce((draft) => {
                        draft.returnDeliveryCompany = value;
                      }),
                    );
                  }}
                  options={deliveryCompanyOptions}
                  disabled={form.isSameDelivery}
                  style={{ width: 200 }}
                  placeholder="택배사 선택"
                />
                <Checkbox
                  checked={form.isSameDelivery}
                  onChange={(event) => {
                    setForm(
                      produce((draft) => {
                        draft.isSameDelivery = event.target.checked;
                      }),
                    );
                  }}
                >
                  배송택배사랑 동일
                </Checkbox>
              </Space>
            </Descriptions.Item>
          </Descriptions>
        </Descriptions.Item>
        <Descriptions.Item
          labelStyle={{ width: 180 }}
          label={<RequiredOrNotLabel label="반품 배송비" required />}
          span={3}
        >
          <Space>
            <InputMoney
              value={form.returnDeliveryPrice}
              onChange={(value) => {
                setForm(
                  produce((draft) => {
                    draft.returnDeliveryPrice = value;
                  }),
                );
              }}
              placeholder="숫자만 입력"
              suffix="원"
              style={{ width: 200 }}
            />
          </Space>
        </Descriptions.Item>
        <Descriptions.Item
          labelStyle={{ width: 180 }}
          label={<RequiredOrNotLabel label="교환 배송비" required />}
          span={3}
        >
          <InputMoney
            value={form.exchangeDeliveryPrice}
            onChange={(value) => {
              setForm(
                produce((draft) => {
                  draft.exchangeDeliveryPrice = value;
                }),
              );
            }}
            placeholder="숫자만 입력"
            suffix="원"
            style={{ width: 200 }}
          />
        </Descriptions.Item>
        <Descriptions.Item
          labelStyle={{ width: 180 }}
          label={
            <RequiredOrNotLabel
              label={
                <div>
                  <div>제주/도서상간</div>
                  <div>
                    추가 배송비<span style={{ color: 'red' }}> *</span>
                  </div>
                </div>
              }
            />
          }
          span={3}
        >
          <InputMoney
            value={form.extraAmount}
            onChange={(value) => {
              setForm(
                produce((draft) => {
                  draft.extraAmount = value;
                }),
              );
            }}
            placeholder="숫자만 입력"
            suffix="원"
            style={{ width: 200 }}
          />
        </Descriptions.Item>
        <Descriptions.Item labelStyle={{ width: 180 }} label={<RequiredOrNotLabel label="출고지" required />} span={3}>
          <Space>
            <Button
              type="primary"
              onClick={() => {
                Modal.info({
                  title: '출고지',
                  content: (
                    <Postcode
                      style={{ marginLeft: -30 }}
                      onSelected={(data) => {
                        setForm(
                          produce((draft) => {
                            draft.cargoAddr = data;
                          }),
                        );
                        Modal.destroyAll();
                      }}
                    />
                  ),
                  width: 600,
                  closable: true,
                  centered: true,
                  okButtonProps: { style: { display: 'none' } },
                });
              }}
            >
              주소변경하기
            </Button>
            {form.cargoAddr && (
              <Typography.Text>
                {form.cargoAddr.address} {form.cargoAddr.buildingName} (우: {form.cargoAddr.zonecode})
              </Typography.Text>
            )}
          </Space>
        </Descriptions.Item>
        <Descriptions.Item
          labelStyle={{ width: 180 }}
          label={<RequiredOrNotLabel label="반품/교환지" required />}
          span={3}
        >
          <Space>
            <Button
              type="primary"
              onClick={() => {
                Modal.info({
                  title: '반품/교환지',
                  content: (
                    <Postcode
                      style={{ marginLeft: -30 }}
                      onSelected={(data) => {
                        // console.log('data :::: ', data);
                        setForm(
                          produce((draft) => {
                            draft.returnAddr = data;
                          }),
                        );
                        Modal.destroyAll();
                      }}
                    />
                  ),
                  width: 600,
                  closable: true,
                  centered: true,
                  okButtonProps: { style: { display: 'none' } },
                });
              }}
            >
              주소변경하기
            </Button>
            {form.returnAddr && (
              <Typography.Text>
                {form.returnAddr.address} {form.returnAddr.buildingName} (우: {form.returnAddr.zonecode})
              </Typography.Text>
            )}
            <Checkbox
              checked={form.isMoreReturnAddr}
              onChange={(event) => {
                setForm(
                  produce((draft) => {
                    draft.isMoreReturnAddr = event.target.checked;
                  }),
                );
              }}
            >
              반품/교환지 주소지로 계속 사용하기
            </Checkbox>
          </Space>
        </Descriptions.Item>
      </Descriptions>
      <Row justify="end" gutter={10}>
        <Col>
          <Link to="/goods/delivery_template">
            <Button>목록</Button>
          </Link>
        </Col>
        <Col>
          <Button onClick={handleSubmit} type="primary">
            저장
          </Button>
        </Col>
      </Row>
    </Space>
  );
}

export default PartnersChargeTemplateCreate;
