import { restApi } from '#apis/index';
import handleError from '#utils/handleError';
import Postcode from '@actbase/react-daum-postcode';
import produce from 'immer';
import moment from 'moment';
import React from 'react';
import { Button, Modal, Input, Descriptions, Space, Select, Radio, Row, Col, DatePicker, Form, Typography } from 'antd';
import SearchForm from '#components/SearchForm';
import CommTable from '#components/table/CommTable';
import { shallowEqual, useSelector } from 'react-redux';
import ShowOrderDetailBtn from '#components/Buttons/ShowOrderDetailBtn';
import useCheckOrderStatusCodeRowKeys from '#hooks/useCheckOrderStatusCodeRowKeys';
import { useNavigate } from 'react-router-dom';
import ProductDetails from '#components/App/ProductDetails';
import dayjs from 'dayjs';

const DATE_TYPE = {
  ORDER_AT: 'OrderAt',
  PAY_AT: 'PayAt',
  SEND_AT: 'SendAt',
};

const SEARCH_TYPE = {
  BUYER: 'Buyer',
  BUYER_ID: 'BuyerId',
  BUYER_PHONE: 'BuyerPhone',
  INVOICE_NUMBER: 'InvoiceNumber',
  ORDER_NUMBER: 'OrderNumber',
  ORDER_PRODUCT_NUMBER: 'OrderProductNumber',
  PRODUCT_NUMBER: 'ProductNumber',
  RECIPIENT: 'Recipient',
};

const RETURN_CHANGE_TYPE = {
  CHANGE: 'CHANGE',
  RETURN: 'RETURN',
};

const DeliverySendForm = ({ selectedRowKeys, onRefresh, codesTree }) => {
  const initialForm = React.useMemo(
    () => ({
      methodCode: 'DM001',
      courierCode: null,
      invoiceNumber: null,
    }),
    [],
  );
  const [form, setForm] = React.useState(initialForm);

  return (
    <Form
      onFinish={() => {
        Modal.confirm({
          title: '발송처리',
          content: '발송처리 후에는 구매고객에게는 배송중으로 노출됩니다.\n선택한 내역을 발송 처리를 하시겠습니까 ?',
          centered: true,
          async onOk() {
            if (form.methodCode === 'DM001') {
              if (!form.courierCode) {
                return Modal.warn({
                  title: '발송처리',
                  content: '택배사를 선택해주세요.',
                  centered: true,
                });
              }
              if (!form.invoiceNumber) {
                return Modal.warn({
                  title: '발송처리',
                  content: '송장번호를 입력해주세요.',
                  centered: true,
                });
              }
            }

            try {
              // 발송처리
              await Promise.all(
                selectedRowKeys.map((orderProductId) =>
                  restApi.put(`/partners/account-orders/${orderProductId}/send`, {
                    methodCode: form.methodCode,
                    courierCode: form.courierCode,
                    invoiceNumber: form.invoiceNumber,
                  }),
                ),
              );
              Modal.destroyAll();
              onRefresh();
              Modal.success({
                title: '발송처리',
                content: '발송처리가 되었습니다.',
                centered: true,
              });
            } catch (error) {
              // 발주확인 실패
              handleError(error);
              Modal.error({
                title: '발송처리',
                content: error?.response?.data?.message ?? '발송처리에 실패했습니다.',
                centered: true,
              });
            }
          },
        });
      }}
    >
      <Space direction="vertical" size="middle" style={{ width: '100%' }}>
        <Form.Item label="배송방법" initialValue="DM001">
          <Select
            value={form.methodCode}
            onChange={(value) => {
              setForm(
                produce((draft) => {
                  draft.methodCode = value;
                  draft.invoiceNumber = null;
                  draft.courierCode = null;
                }),
              );
            }}
            options={codesTree?.DM?.items?.map(({ code, label }) => ({ label, value: code })) ?? []}
          />
        </Form.Item>
        <Form.Item label="택배사">
          <Select
            value={form.courierCode}
            onChange={(value) => {
              setForm(
                produce((draft) => {
                  draft.courierCode = value;
                }),
              );
            }}
            options={codesTree?.DV?.items?.map(({ code, label }) => ({ label, value: code })) ?? []}
            placeholder="택배사명 선택"
            disabled={form.methodCode !== 'DM001'}
          />
        </Form.Item>
        <Form.Item label="송장정보 입력">
          <Input
            value={form.invoiceNumber}
            onChange={(event) => {
              setForm(
                produce((draft) => {
                  draft.invoiceNumber = event.target.value;
                }),
              );
            }}
            placeholder="송장정보 입력"
            disabled={form.methodCode !== 'DM001'}
          />
        </Form.Item>
        <Row justify="end" style={{ marginBottom: -25 }} gutter={10}>
          <Col>
            <Button type="primary" htmlType="submit">
              적용
            </Button>
          </Col>
          <Col>
            <Button
              onClick={() => {
                Modal.destroyAll();
              }}
            >
              취소
            </Button>
          </Col>
        </Row>
      </Space>
    </Form>
  );
};

const ChangeDelivery = ({ selectedRowKeys = [], onSubmit = () => {} }) => {
  const formRef = React.useRef();
  return (
    <Form
      ref={formRef}
      onFinish={async ({ deliveryAddress, deliveryAddressDetail, phone, recipient, subPhone, zipCode }) => {
        if (!recipient) {
          return Modal.warn({
            title: '배송지 정보 수정',
            content: '수취인명을 입력하세요.',
            centered: true,
          });
        }
        if (!phone) {
          return Modal.warn({
            title: '배송지 정보 수정',
            content: '휴대폰 번호를 입력하세요.',
            centered: true,
          });
        }
        if (!subPhone) {
          return Modal.warn({
            title: '배송지 정보 수정',
            content: '전화번호를 입력하세요.',
            centered: true,
          });
        }
        if (!deliveryAddress) {
          return Modal.warn({
            title: '배송지 정보 수정',
            content: '배송지 주소를 입력하세요.',
            centered: true,
          });
        }
        try {
          // 배송지 정보 수정
          await Promise.all(
            selectedRowKeys.map((orderProductId) =>
              restApi.put(`/partners/account-orders/${orderProductId}/address`, {
                deliveryAddress,
                deliveryAddressDetail,
                phone,
                recipient,
                subPhone,
                zipCode,
              }),
            ),
          );
          Modal.destroyAll();
          onSubmit();
          Modal.success({
            title: '배송지 정보 수정',
            content: '배송지 정보가 변경되었습니다.',
            centered: true,
          });
        } catch (error) {
          // 배송지 정보 수정 실패
          handleError(error);
          Modal.error({
            title: '배송지 정보 수정',
            content: '배송지 정보 수정에 실패했습니다.',
            centered: true,
          });
        }
      }}
    >
      <Space direction="vertical" size="middle" style={{ width: '100%' }}>
        <Space direction="vertical" style={{ width: '100%' }}>
          <Form.Item label="수취인명" name="recipient">
            <Input />
          </Form.Item>
          <Form.Item label="휴대폰 번호" name="phone">
            <Input />
          </Form.Item>
          <Form.Item label="전화번호" name="subPhone">
            <Input />
          </Form.Item>
          <Space>
            <Form.Item label="배송지 주소" name="zipCode">
              <Input style={{ width: 120 }} readOnly />
            </Form.Item>
            <Button
              type="primary"
              onClick={() => {
                const addressModal = Modal.info({
                  title: '주소변경',
                  content: (
                    <Postcode
                      style={{ marginLeft: -30 }}
                      onSelected={({ zonecode, address }) => {
                        formRef.current.setFieldsValue({
                          zipCode: zonecode,
                          deliveryAddress: address,
                        });
                        addressModal.destroy();
                      }}
                    />
                  ),
                  width: 600,
                  closable: true,
                  centered: true,
                  okButtonProps: { style: { display: 'none' } },
                });
              }}
            >
              우편번호 찾기
            </Button>
          </Space>
          <Form.Item name="deliveryAddress" label={'\t'}>
            <Input />
          </Form.Item>
          <Form.Item name="deliveryAddressDetail" label={'\t'}>
            <Input />
          </Form.Item>
        </Space>
        <Row justify="end" style={{ marginBottom: -25 }} gutter={10}>
          <Col>
            <Button type="primary" htmlType="submit">
              변경
            </Button>
          </Col>
          <Col>
            <Button
              onClick={() => {
                Modal.destroyAll();
              }}
            >
              취소
            </Button>
          </Col>
        </Row>
      </Space>
    </Form>
  );
};

function SalesTotalOrder() {
  const { map: codesMap, tree: codesTree } = useSelector((selector) => selector.codes, shallowEqual);
  const navigate = useNavigate();
  const initialParams = React.useMemo(
    () => ({
      dateType: DATE_TYPE.PAY_AT,
      startAt: null,
      endAt: null,
      businessName: '',
      orderProductStateCode: undefined,
      searchType: null,
    }),
    [],
  );

  const ORDER_STATUS_TYPE = React.useMemo(
    () =>
      codesTree?.OPS.items?.reduce((p, v) => {
        p[v.code] = v.code;
        return p;
      }, {}),
    [codesTree],
  );

  const [params, setParams] = React.useState(initialParams);
  const tableRef = React.useRef();
  const [selectedRowKeys, setSelectedRowKeys] = React.useState([]);

  const handleChangePagination = React.useCallback(() => {
    setSelectedRowKeys([]);
  }, []);

  const { checkOrderStatusCodeRowKeys } = useCheckOrderStatusCodeRowKeys(tableRef.current?.dataSource, selectedRowKeys);

  const handleRefresh = React.useCallback(() => {
    setSelectedRowKeys([]);
    tableRef.current?.loadData();
  }, []);

  return (
    <Space direction="vertical" style={{ width: '100%' }}>
      <SearchForm
        params={params}
        onInit={setParams}
        onReset={() => {
          setParams(initialParams);
          return initialParams;
        }}
      >
        <Row gutter={10} style={{ marginBottom: 10, alignItems: 'center' }}>
          <Col>
            <Select
              value={params.dateType}
              defaultValue={DATE_TYPE.ORDER_AT}
              onChange={(value) => {
                setParams(
                  produce((draft) => {
                    draft.dateType = value;
                  }),
                );
              }}
              options={[
                { label: '결제일', value: DATE_TYPE.PAY_AT },
                { label: '발주확인일', value: DATE_TYPE.ORDER_AT },
                { label: '발송처리일', value: DATE_TYPE.SEND_AT },
              ]}
              style={{ width: 150 }}
            />
          </Col>
          <Col>
            <DatePicker.RangePicker
              value={[
                params.startAt ? moment(params.startAt) : undefined,
                params.endAt ? moment(params.endAt) : undefined,
              ]}
              onChange={([startAt, endAt]) => {
                setParams(
                  produce((draft) => {
                    draft.startAt = startAt.format('YYYY-MM-DD');
                    draft.endAt = endAt.format('YYYY-MM-DD');
                  }),
                );
              }}
            />
          </Col>
          <Col>
            <Radio.Group
              value={moment(params.endAt).diff(params.startAt, 'day')}
              onChange={(event) => {
                setParams(
                  produce((draft) => {
                    draft.startAt = moment().add(-event.target.value, 'day').format('YYYY-MM-DD');
                    draft.endAt = moment().format('YYYY-MM-DD');
                  }),
                );
              }}
              options={[
                { label: '오늘', value: 0 },
                { label: '1주일', value: 7 },
                { label: '1개월', value: 30 },
                { label: '3개월', value: 90 },
              ]}
              buttonStyle="solid"
              optionType="button"
            />
          </Col>
        </Row>
        <Row gutter={10} style={{ alignItems: 'center' }}>
          <Col>
            <Select
              value={params.orderProductStateCode}
              onChange={(value) => {
                setParams(
                  produce((draft) => {
                    draft.orderProductStateCode = value;
                  }),
                );
              }}
              options={[
                { label: '결제완료(발송대기)', value: 'OPS002' },
                { label: '배송 중', value: 'OPS005' },
                { label: '배송완료', value: 'OPS006' },
              ]}
              placeholder="주문상태"
              allowClear
              style={{ width: 150 }}
            />
          </Col>
          <Col>
            <Select
              value={params.searchType}
              onChange={(value) => {
                setParams(
                  produce((draft) => {
                    draft.searchType = value;
                  }),
                );
              }}
              placeholder="검색 옵션"
              options={[
                { label: '구매자명', value: SEARCH_TYPE.BUYER },
                { label: '구매자 연락처', value: SEARCH_TYPE.BUYER_PHONE },
                { label: '구매자 ID', value: SEARCH_TYPE.BUYER_ID },
                { label: '주문번호', value: SEARCH_TYPE.ORDER_NUMBER },
                { label: '상품번호', value: SEARCH_TYPE.PRODUCT_NUMBER },
                { label: '상품주문번호', value: SEARCH_TYPE.ORDER_PRODUCT_NUMBER },
              ]}
              style={{ width: 150 }}
            />
          </Col>
          <Col>
            <Input
              value={params.keyword}
              onChange={(event) => {
                setParams(
                  produce((draft) => {
                    draft.keyword = event.target.value;
                  }),
                );
              }}
              placeholder="키워드를 입력하세요"
              style={{ width: 200 }}
            />
          </Col>
          <Col>
            <Space>
              <Button type="primary" htmlType="submit">
                검색
              </Button>
              <Button htmlType="reset">초기화</Button>
            </Space>
          </Col>
        </Row>
        <CommTable
          ref={tableRef}
          url="/partners/account-orders"
          rowSelection={{
            selectedRowKeys,
            onChange: setSelectedRowKeys,
            type: 'radio',
          }}
          onChangePagination={handleChangePagination}
          rowKey={({ orderProductId }) => orderProductId}
          columns={[
            {
              title: '주문번호',
              dataIndex: 'orderId',
              fixed: true,
              width: 100,
            },
            {
              title: '상품주문번호',
              dataIndex: 'orderProductId',
              fixed: true,
              width: 200,
              render: (value) => <ShowOrderDetailBtn orderId={value} />,
            },
            {
              title: '파트너사',
              dataIndex: 'businessName',
              fixed: true,
              width: 100,
            },
            {
              title: '주문상태',
              dataIndex: 'orderProductStateCode',
              fixed: true,
              width: 100,
              render: (value) => codesMap?.[value],
            },
            {
              title: '주문일시',
              dataIndex: 'buyAt',
              width: 200,
              render: (value) => {
                return dayjs(value).format('YYYY-MM-DD HH:mm:ss');
              },
            },
            {
              title: '상품번호',
              dataIndex: 'productId',
              width: 100,
              render: (value) => (
                <Typography.Link
                  onClick={() => {
                    Modal.info({
                      title: '상세설명',
                      width: 550,
                      content: <ProductDetails productId={value} />,
                      centered: true,
                    });
                  }}
                >
                  {value}
                </Typography.Link>
              ),
            },
            {
              title: '상품명',
              dataIndex: 'productName',
              width: 200,
            },
            {
              title: '옵션정보',
              dataIndex: 'options',
              width: 100,
              render: (value) => value?.join(', '),
            },
            {
              title: '수량',
              dataIndex: 'count',
              width: 100,
            },
            {
              title: '구매자명',
              dataIndex: 'buyerName',
              width: 100,
            },
            {
              title: '구매자ID',
              dataIndex: 'buyerId',
              width: 100,
            },
            {
              title: '수취인명',
              dataIndex: 'recipient',
              width: 100,
            },
          ]}
          scroll={{ x: 1400 }}
        />
      </SearchForm>
      <Descriptions colon={false} bordered column={1}>
        <Descriptions.Item label="주문관리" labelStyle={{ width: 140, alignItems: 'center' }}>
          <Space>
            <Button
              disabled={!selectedRowKeys.length || !checkOrderStatusCodeRowKeys(ORDER_STATUS_TYPE.OPS002)}
              onClick={() => {
                Modal.confirm({
                  title: '발주확인',
                  content:
                    '발주 확인 후에는 구매고객에게는 상품준비중으로 노출됩니다. 선택한 내역을 발주확인 처리를 하시겠습니까 ?',
                  centered: true,
                  async onOk() {
                    // 선택된 값이 1개 이상일 경우
                    if (selectedRowKeys.length > 0) {
                      // 결제완료 상품 체크
                      if (checkOrderStatusCodeRowKeys(ORDER_STATUS_TYPE.OPS002)) {
                        try {
                          // 발주확인
                          await Promise.all(
                            selectedRowKeys.map((orderProductId) =>
                              restApi.put(`/partners/account-orders/${orderProductId}/confirm-place-order`),
                            ),
                          );
                          handleRefresh();
                          Modal.success({
                            title: '발주확인',
                            content: '발주확인 처리가 완료되었습니다.',
                            centered: true,
                          });
                        } catch (error) {
                          // 발주확인 실패
                          handleError(error);
                          Modal.error({
                            title: '발주확인',
                            content: '발주확인 처리에 실패했습니다.',
                            centered: true,
                          });
                        }
                      }
                      // 결제완료 상품이 아닌 것이 있을 경우
                      else {
                        Modal.warn({
                          title: '발주확인',
                          content: '결제완료 상품만 발주확인처리를 할 수 있습니다.',
                          centered: true,
                        });
                      }
                    }
                    // 선택된 값이 1개도 없을경우
                    else {
                      Modal.warn({
                        title: '발주확인',
                        content: '처리할 상품주문번호를 먼저 선택해주세요.',
                        centered: true,
                      });
                    }
                  },
                });
              }}
            >
              발주확인
            </Button>
            <Button
              disabled={
                !selectedRowKeys.length ||
                !checkOrderStatusCodeRowKeys(ORDER_STATUS_TYPE.OPS003, ORDER_STATUS_TYPE.OPS004)
              }
              onClick={() => {
                // 선택된 값이 1개 이상일 경우
                if (selectedRowKeys.length > 0) {
                  // 상품준비, 발송지연 상품 체크
                  if (checkOrderStatusCodeRowKeys(ORDER_STATUS_TYPE.OPS003, ORDER_STATUS_TYPE.OPS004)) {
                    Modal.info({
                      title: '발송처리',
                      width: 650,
                      content: (
                        <DeliverySendForm
                          selectedRowKeys={selectedRowKeys}
                          onRefresh={handleRefresh}
                          codesTree={codesTree}
                        />
                      ),
                      okButtonProps: { style: { display: 'none' } },
                      centered: true,
                    });
                  }
                  // 결제완료, 발송지연 상품이 아닌 것이 있을 경우
                  else {
                    Modal.warn({
                      title: '발송처리',
                      content: '발주확인 처리 된 내역만 발송처리를 할 수 있습니다.',
                      centered: true,
                    });
                  }
                }
                // 선택된 값이 1개도 없을경우
                else {
                  Modal.warn({
                    title: '발송처리',
                    content: '처리할 상품주문번호를 먼저 선택해주세요.',
                    centered: true,
                  });
                }
              }}
            >
              발송처리
            </Button>
            <Button
              disabled={!selectedRowKeys.length || !checkOrderStatusCodeRowKeys(ORDER_STATUS_TYPE.OPS003)}
              onClick={() => {
                // 선택된 값이 1개 이상일 경우
                if (selectedRowKeys.length > 0) {
                  // 상품준비 상품 체크
                  if (checkOrderStatusCodeRowKeys(ORDER_STATUS_TYPE.OPS003)) {
                    Modal.info({
                      title: '발송지연 처리',
                      width: 650,
                      content: (
                        <Form
                          onFinish={({ delayReason }) => {
                            Modal.confirm({
                              title: '발송지연 처리',
                              content: (
                                <>
                                  발송지연 처리 후에는 구매고객에게는 발송지연으로 노출됩니다. {delayReason}
                                  <br />
                                  선택한 내역을 발송지연 처리를 하시겠습니까?
                                </>
                              ),
                              centered: true,
                              okText: '확인',
                              cancelText: '취소',
                              async onOk() {
                                if (!delayReason) {
                                  return Modal.warn({
                                    title: '발송지연 처리',
                                    content: '발송지연 사유를 입력하세요.',
                                    centered: true,
                                  });
                                }

                                try {
                                  // 발송 지연 처리
                                  await Promise.all(
                                    selectedRowKeys.map((orderProductId) =>
                                      restApi.put(`/partners/account-orders/${orderProductId}/delay-send`, {
                                        delayReason,
                                      }),
                                    ),
                                  );
                                  Modal.destroyAll();
                                  handleRefresh();
                                  Modal.success({
                                    title: '발송지연 처리',
                                    content: '발송지연 처리가 되었습니다.',
                                    centered: true,
                                  });
                                } catch (error) {
                                  // 발송지연처리 실패
                                  handleError(error);
                                  Modal.error({
                                    title: '발송지연 처리',
                                    content: '발송지연 처리애 실패했습니다.',
                                    centered: true,
                                  });
                                }
                              },
                            });
                          }}
                        >
                          <Space direction="vertical" size="middle" style={{ width: '100%' }}>
                            <Form.Item label="발송지연 사유" name="delayReason">
                              <Input />
                            </Form.Item>
                            <Row justify="end" style={{ marginBottom: -25 }} gutter={10}>
                              <Col>
                                <Button type="primary" htmlType="submit">
                                  발송지연 처리
                                </Button>
                              </Col>
                              <Col>
                                <Button
                                  onClick={() => {
                                    Modal.destroyAll();
                                  }}
                                >
                                  취소
                                </Button>
                              </Col>
                            </Row>
                          </Space>
                        </Form>
                      ),
                      okButtonProps: { style: { display: 'none' } },
                      centered: true,
                    });
                  }
                  // 상품준비 상품이 아닌 것이 있을 경우
                  else {
                    Modal.warn({
                      title: '발송지연 처리',
                      content: '발주확인 처리 된 내역만 발송지연 처리를 할 수 있습니다.',
                      centered: true,
                    });
                  }
                }
                // 선택된 값이 1개도 없을경우
                else {
                  Modal.warn({
                    title: '발송지연 처리',
                    content: '처리할 상품주문번호를 먼저 선택해주세요.',
                    centered: true,
                  });
                }
              }}
            >
              발송지연처리
            </Button>
            <Button
              disabled={!selectedRowKeys.length || !checkOrderStatusCodeRowKeys()}
              onClick={() => {
                // 선택된 값이 1개 이상일 경우
                if (selectedRowKeys.length > 0) {
                  Modal.info({
                    title: '배송지 정보 수정',
                    width: 650,
                    content: <ChangeDelivery selectedRowKeys={selectedRowKeys} onSubmit={handleRefresh} />,
                    okButtonProps: { style: { display: 'none' } },
                    centered: true,
                  });
                }
                // 선택된 값이 1개도 없을경우
                else {
                  Modal.warn({
                    title: '배송지 정보 수정',
                    content: '처리할 상품주문번호를 먼저 선택해주세요.',
                    centered: true,
                  });
                }
              }}
            >
              배송지 정보 수정
            </Button>
          </Space>
        </Descriptions.Item>
        <Descriptions.Item label="취소관리" labelStyle={{ width: 140, alignItems: 'center' }}>
          <Space>
            <Button
              disabled={!selectedRowKeys.length || !checkOrderStatusCodeRowKeys(ORDER_STATUS_TYPE.OPS002)}
              onClick={() => {
                // 선택된 값이 1개 이상일 경우
                if (selectedRowKeys.length > 0 && checkOrderStatusCodeRowKeys(ORDER_STATUS_TYPE.OPS002)) {
                  Modal.info({
                    title: '판매취소 처리',
                    width: 650,
                    content: (
                      <Form
                        onFinish={async ({ cancelMessage, cancelReason }) => {
                          if (!cancelReason) {
                            return Modal.warn({
                              title: '판매취소 처리',
                              content: '판매취소 사유를 입력하세요.',
                              centered: true,
                            });
                          }
                          if (!cancelMessage) {
                            return Modal.warn({
                              title: '판매취소 처리',
                              content: '구매자에게 전하실 말씀을 입력하세요.',
                              centered: true,
                            });
                          }
                          try {
                            // 발송처리
                            await Promise.all(
                              selectedRowKeys.map((orderProductId) =>
                                restApi.put(`/partners/account-orders/${orderProductId}/cancel`, {
                                  cancelMessage,
                                  cancelReason,
                                }),
                              ),
                            );
                            Modal.destroyAll();
                            handleRefresh();
                            Modal.success({
                              title: '판매취소 처리',
                              content: '판매취소 처리되었습니다.',
                              centered: true,
                            });
                          } catch (error) {
                            // 발송지연처리 실패
                            handleError(error);
                            Modal.error({
                              title: '판매취소 처리',
                              content: '판매취소 처리 실패했습니다.',
                              centered: true,
                            });
                          }
                        }}
                      >
                        <Space direction="vertical" size="middle" style={{ width: '100%' }}>
                          <Form.Item label="판매취소 사유" name="cancelReason">
                            <Input />
                          </Form.Item>
                          <Form.Item label="구매자에게 전하실 말씀" name="cancelMessage">
                            <Input.TextArea />
                          </Form.Item>
                          <Row justify="end" style={{ marginBottom: -25 }} gutter={10}>
                            <Col>
                              <Button type="primary" htmlType="submit">
                                판매취소 처리
                              </Button>
                            </Col>
                            <Col>
                              <Button
                                onClick={() => {
                                  Modal.destroyAll();
                                }}
                              >
                                취소
                              </Button>
                            </Col>
                          </Row>
                        </Space>
                      </Form>
                    ),
                    okButtonProps: { style: { display: 'none' } },
                    centered: true,
                  });
                }
                // 선택된 값이 1개도 없을경우
                else {
                  Modal.warn({
                    title: '판매취소 처리',
                    content: '처리할 상품주문번호를 먼저 선택해주세요.',
                    centered: true,
                  });
                }
              }}
            >
              판매취소 처리
            </Button>
            <Button
              disabled={!selectedRowKeys.length || !checkOrderStatusCodeRowKeys(ORDER_STATUS_TYPE.OPS008)}
              onClick={() => {
                // 선택된 값이 1개 이상일 경우
                if (selectedRowKeys.length > 0) {
                  // 선택된 값이 취소인것들만 있을 경우
                  if (checkOrderStatusCodeRowKeys(ORDER_STATUS_TYPE.OPS008)) {
                    Modal.confirm({
                      title: '취소 완료처리',
                      content: (
                        <>
                          취소 완료 처리 후에는 되돌릴 수 없으며, 결제된 내역은 자동 환불처리가 됩니다.
                          <br />
                          선택한 내역을 취소 완료처리를 하시겠습니까?
                        </>
                      ),
                      centered: true,
                      async onOk() {
                        try {
                          // 발송처리
                          await Promise.all(
                            selectedRowKeys.map((orderProductId) =>
                              restApi.put(`/partners/account-orders/${orderProductId}/confirm-cancel`),
                            ),
                          );
                          Modal.destroyAll();
                          handleRefresh();
                          Modal.success({
                            title: '취소 완료처리',
                            content: '취소 완료처리가 되었습니다.',
                            centered: true,
                          });
                        } catch (error) {
                          // 발송지연처리 실패
                          handleError(error);
                          Modal.error({
                            title: '취소 완료처리',
                            content: '취소 완료처리 실패했습니다.',
                            centered: true,
                          });
                        }
                      },
                    });
                  }
                  // 선택된 값이 취소가 아닌게 있을 경우
                  else {
                    Modal.warn({
                      title: '취소 완료처리',
                      content: '취소 처리 된 내역만 취소 완료처리를 할 수 있습니다.',
                      centered: true,
                    });
                  }
                }
                // 선택된 값이 1개도 없을경우
                else {
                  Modal.warn({
                    title: '취소 완료처리',
                    content: '처리할 상품주문번호를 먼저 선택해주세요.',
                    centered: true,
                  });
                }
              }}
            >
              취소 완료처리
            </Button>
            <Button
              disabled={!selectedRowKeys.length || !checkOrderStatusCodeRowKeys(ORDER_STATUS_TYPE.OPS007)}
              onClick={() => {
                // 선택된 값이 1개 이상일 경우
                if (selectedRowKeys.length > 0) {
                  // 선택된 값이 구매확정인것들만 있을 경우
                  if (checkOrderStatusCodeRowKeys(ORDER_STATUS_TYPE.OPS007)) {
                    Modal.confirm({
                      title: '구매확정 후 취소처리',
                      content: (
                        <>
                          취소 완료 처리 후에는 되돌릴 수 없으며, 결제된 내역은 자동 환불처리가 됩니다.
                          <br />
                          선택한 내역을 취소 완료처리를 하시겠습니까?
                        </>
                      ),
                      centered: true,
                      async onOk() {
                        try {
                          // 구매확정 후 취소처리
                          await Promise.all(
                            selectedRowKeys.map((orderProductId) =>
                              restApi.put(`/partners/account-orders/${orderProductId}/cancel/after-buy`),
                            ),
                          );
                          Modal.destroyAll();
                          handleRefresh();
                          Modal.success({
                            title: '구매확정 후 취소처리',
                            content: '취소 완료처리가 되었습니다.',
                            centered: true,
                          });
                        } catch (error) {
                          // 구매확정 후 취소처리 실패
                          handleError(error);
                          Modal.error({
                            title: '구매확정 후 취소처리',
                            content: '취소 완료처리 실패했습니다.',
                            centered: true,
                          });
                        }
                      },
                    });
                  }
                  // 선택된 값이 구매확정이 아닌게 있을 경우
                  else {
                    Modal.warn({
                      title: '구매확정 후 취소처리',
                      content: '구매확정 처리 된 내역만 취소 완료처리를 할 수 있습니다.',
                      centered: true,
                    });
                  }
                }
                // 선택된 값이 1개도 없을경우
                else {
                  Modal.warn({
                    title: '구매확정 후 취소처리',
                    content: '처리할 상품주문번호를 먼저 선택해주세요.',
                    centered: true,
                  });
                }
              }}
            >
              구매확정 후 취소처리
            </Button>
          </Space>
        </Descriptions.Item>
        <Descriptions.Item label="반품교환관리" labelStyle={{ width: 140, alignItems: 'center' }}>
          <Space>
            <Button
              disabled={!selectedRowKeys.length || !checkOrderStatusCodeRowKeys(ORDER_STATUS_TYPE.OPS006)}
              onClick={() => {
                // 선택된 값이 1개 이상일 경우
                if (selectedRowKeys.length > 0) {
                  // 선택된 값이 배송완료 인것들만 있을 경우
                  if (checkOrderStatusCodeRowKeys(ORDER_STATUS_TYPE.OPS006)) {
                    Modal.info({
                      title: '반품 및 교환',
                      width: 650,
                      content: (
                        <Form
                          onFinish={async ({ type, memo }) => {
                            async function onOk() {
                              try {
                                // 반품 및 교환
                                await Promise.all(
                                  selectedRowKeys.map((orderProductId) =>
                                    restApi.put(`/partners/account-orders/${orderProductId}/return-change`, {
                                      type,
                                      memo,
                                    }),
                                  ),
                                );
                                Modal.destroyAll();
                                handleRefresh();
                              } catch (error) {
                                // 반품 및 교환 실패
                                handleError(error);
                                throw error;
                              }
                            }
                            switch (type) {
                              case RETURN_CHANGE_TYPE.CHANGE:
                                Modal.confirm({
                                  title: '반품 및 교환',
                                  content: '선택한 내역을 교환처리를 하시겠습니까 ?',
                                  centered: true,
                                  async onOk() {
                                    return onOk()
                                      .then(() => {
                                        Modal.success({
                                          title: '반품 및 교환',
                                          content: '교환 처리가 되었습니다.',
                                          centered: true,
                                          okText: '확인',
                                        });
                                      })
                                      .catch(() => {
                                        Modal.error({
                                          title: '반품 및 교환',
                                          content: '교환 처리 실패했습니다.',
                                          centered: true,
                                          okText: '확인',
                                        });
                                      });
                                  },
                                  okText: '확인',
                                  cancelText: '취소',
                                });
                                break;
                              case RETURN_CHANGE_TYPE.RETURN:
                                Modal.confirm({
                                  title: '반품 및 교환',
                                  content: '선택한 내역을 반품처리를 하시겠습니까 ?',
                                  centered: true,
                                  async onOk() {
                                    return onOk()
                                      .then(() => {
                                        Modal.success({
                                          title: '반품 및 교환',
                                          content: '반품 처리가 되었습니다.',
                                          centered: true,
                                          okText: '확인',
                                        });
                                      })
                                      .catch(() => {
                                        Modal.error({
                                          title: '반품 및 교환',
                                          content: '반품 처리 실패했습니다.',
                                          centered: true,
                                          okText: '확인',
                                        });
                                      });
                                  },
                                  okText: '확인',
                                  cancelText: '취소',
                                });
                                break;
                              default:
                            }
                          }}
                        >
                          <Space direction="vertical" size="middle" style={{ width: '100%' }}>
                            <Form.Item label="구분" name="type" initialValue={RETURN_CHANGE_TYPE.RETURN}>
                              <Radio.Group
                                options={[
                                  { label: '반품', value: RETURN_CHANGE_TYPE.RETURN },
                                  { label: '교환', value: RETURN_CHANGE_TYPE.CHANGE },
                                ]}
                              />
                            </Form.Item>
                            <Typography.Paragraph>해당 메모는 구매자에게는 노출되지 않습니다.</Typography.Paragraph>
                            <Form.Item label="메모" name="memo">
                              <Input.TextArea />
                            </Form.Item>
                            <Row justify="end" style={{ marginBottom: -25 }} gutter={10}>
                              <Col>
                                <Button type="primary" htmlType="submit">
                                  확인
                                </Button>
                              </Col>
                              <Col>
                                <Button
                                  onClick={() => {
                                    Modal.destroyAll();
                                  }}
                                >
                                  취소
                                </Button>
                              </Col>
                            </Row>
                          </Space>
                        </Form>
                      ),
                      okButtonProps: { style: { display: 'none' } },
                      centered: true,
                    });
                  }
                  // 선택된 값이 배송완료이 아닌게 있을 경우
                  else {
                    Modal.warn({
                      title: '반품 및 교환',
                      content: '배송완료 처리 된 내역만 취소 완료처리를 할 수 있습니다.',
                      centered: true,
                    });
                  }
                }
                // 선택된 값이 1개도 없을경우
                else {
                  Modal.warn({
                    title: '반품 및 교환',
                    content: '처리할 상품주문번호를 먼저 선택해주세요.',
                    centered: true,
                  });
                }
              }}
            >
              반품 및 교환접수
            </Button>
            <Button
              disabled={
                !selectedRowKeys.length ||
                selectedRowKeys.length >= 2 ||
                !checkOrderStatusCodeRowKeys(ORDER_STATUS_TYPE.OPS010)
              }
              onClick={() => {
                navigate(`/sales/refund?searchType=OrderProductId&keyword=${selectedRowKeys[0]}`);
              }}
            >
              반품접수 후 처리
            </Button>
            <Button
              disabled={
                !selectedRowKeys.length ||
                selectedRowKeys.length >= 2 ||
                !checkOrderStatusCodeRowKeys(ORDER_STATUS_TYPE.OPS009)
              }
              onClick={() => {
                navigate(`/sales/exchange?searchType=OrderProductId&keyword=${selectedRowKeys[0]}`);
              }}
            >
              교환접수 후 처리
            </Button>
          </Space>
        </Descriptions.Item>
      </Descriptions>
    </Space>
  );
}

export default SalesTotalOrder;
