import { restApi } from '#apis/index';
import handleError from '#utils/handleError';
import produce from 'immer';
import React from 'react';
import { Button, Space, Select, Radio, Row, Col, Modal } from 'antd';
import SearchForm from '#components/SearchForm';
import CommTable from '#components/table/CommTable';
import useFetch from '#hooks/useFetch';
import useIndex from '#hooks/useIndex';
import AdvertiseHistories from '#components/advertise/AdvertiseHistories';
import { useNavigate } from 'react-router-dom';

const AdFilterType = {
  all: 'ALL',
  on: 'ADON',
  off: 'ADOFF',
};

const AdStatusType = {
  submit: 'SUBMIT',
  on: 'ADON',
  off: 'ADOFF',
};

function Advertise() {
  const { getIndex } = useIndex();
  const navigate = useNavigate();

  const initialParams = React.useMemo(
    () => ({
      adFilterType: AdFilterType.all,
      adStatusType: null,
      page: 0,
    }),
    [],
  );

  const [params, setParams] = React.useState(initialParams);
  const tableRef = React.useRef();
  const areaCodes = useFetch();
  const areaCodes2 = useFetch();

  const handleRequestAd = React.useCallback(() => {
    navigate('/advertise/create');
  }, []);

  React.useEffect(() => {
    areaCodes.fetch(restApi.get('/area-codes/filter')).catch(handleError);
  }, []);

  return (
    <Space direction="vertical" style={{ width: '100%' }}>
      <SearchForm
        params={params}
        onInit={setParams}
        onReset={() => {
          setParams(initialParams);
          return initialParams;
        }}
      >
        <Row gutter={10} style={{ marginBottom: 10, alignItems: 'center', rowGap: 10 }}>
          <Col>
            <Select
              value={params.extra1}
              loading={areaCodes.loading || areaCodes2.loading}
              onChange={async (value) => {
                await areaCodes2.fetch(restApi.get(`/area-codes/filter/${value}`));
                setParams(
                  produce((draft) => {
                    draft.extra1 = value;
                    draft.extra2 = null;
                    draft.page = 0;
                  }),
                );
              }}
              options={areaCodes.data?.map((area) => ({ label: area, value: area })) ?? []}
              style={{ width: 200 }}
              disabled={!(areaCodes.data?.length > 0)}
              placeholder="시/도"
              allowClear
            />
          </Col>
          <Col>
            <Select
              value={params.extra2}
              loading={areaCodes2.loading}
              onChange={(value) => {
                setParams(
                  produce((draft) => {
                    draft.extra2 = value;
                    draft.page = 0;
                  }),
                );
              }}
              options={areaCodes2.data?.map((area) => ({ label: area, value: area })) ?? []}
              style={{ width: 200 }}
              disabled={!(areaCodes2.data?.length > 0)}
              placeholder="시/군/구"
              allowClear
            />
          </Col>
          <Col>
            <Select
              value={params.adStatusType}
              placeholder="광고 상태 옵션"
              onChange={(value) => {
                setParams(
                  produce((draft) => {
                    draft.adStatusType = value;
                  }),
                );
              }}
              options={[
                { label: '신청 중', value: AdStatusType.submit },
                { label: '광고 중', value: AdStatusType.on },
                { label: '광고 중지', value: AdStatusType.off },
              ]}
              style={{ width: 150 }}
            />
          </Col>
          <Col>
            <Radio.Group
              value={params.adFilterType}
              onChange={(event) => {
                setParams(
                  produce((draft) => {
                    draft.adFilterType = event.target.value;
                  }),
                );
              }}
              options={[
                { label: '전체', value: AdFilterType.all },
                { label: '광고 있는 지역만 보기', value: AdFilterType.on },
                { label: '광고 없는 지역만 보기', value: AdFilterType.off },
              ]}
              buttonStyle="solid"
              optionType="button"
            />
          </Col>
          <Col>
            <Space>
              <Button type="primary" htmlType="submit">
                검색
              </Button>
              <Button htmlType="reset">초기화</Button>
            </Space>
          </Col>
        </Row>
      </SearchForm>
      <CommTable
        rightBts={
          <Button
            onClick={() => {
              Modal.info({
                title: '광고 내역',
                width: 1000,
                content: <AdvertiseHistories />,
                centered: true,
                closable: true,
              });
            }}
            type="ghost"
          >
            광고 내역
          </Button>
        }
        ref={tableRef}
        url="/partners/advertisement"
        columns={[
          {
            title: '번호',
            dataIndex: 'tableIndex',
            index: 'tableIndex',
            fixed: true,
            width: 50,
            render: (_, __, index) => getIndex(index),
          },
          {
            title: '시도',
            dataIndex: 'extra1',
            fixed: true,
            width: 100,
            render: (value) => value,
          },
          {
            title: '시군구',
            dataIndex: 'extra2',
            fixed: true,
            width: 100,
            render: (value) => value,
          },
          {
            title: '광고 수(진행중/전체)',
            dataIndex: 'advertiseNumber',
            width: 150,
            render: (_, row) => `${row.adCount ?? 0}/${row.totalCount ?? 0}`,
          },
          {
            title: '광고비(1일기준)',
            dataIndex: 'price',
            width: 200,
            render: (price) => `${(price ?? 0).toLocaleString()}원`,
          },
          {
            title: '상태',
            dataIndex: 'adStatusType',
            width: 100,
            render: (adStatusType) => {
              switch (adStatusType) {
                case 'ADOFF':
                  return '광고 중지';
                case 'ADON':
                  return '광고 중';
                case 'SUBMIT':
                  return '신청 중';
                default:
                  return;
              }
            },
          },
        ]}
        scroll={{ x: 1200 }}
      />
      <Space direction="row" style={{ width: '100%', justifyContent: 'flex-end' }}>
        <Button onClick={handleRequestAd} type="primary">
          신청하기
        </Button>
      </Space>
    </Space>
  );
}

export default Advertise;
