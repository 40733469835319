import React from 'react';
import { Divider, Row, Col, Space, DatePicker, Select, Input, Button, Rate } from 'antd';
import { Link } from 'react-router-dom';
import SearchForm from '#components/SearchForm';
import CommTable from '#components/table/CommTable';
import moment from 'moment';
import produce from 'immer';
import useIndex from '#hooks/useIndex';

function ReviewManagement() {
  const { getIndex } = useIndex();

  const initailParams = React.useMemo(
    () => ({
      startAt: null,
      endAt: null,
      searchType: null,
      keyword: null,
    }),
    [],
  );

  const [params, setParams] = React.useState(initailParams);

  return (
    <div>
      <SearchForm
        params={params}
        onInit={setParams}
        onReset={() => {
          setParams(initailParams);
          return initailParams;
        }}
      >
        <Row gutter={[50, 15]}>
          <Col span={24}>
            <Space style={{ width: '100%' }}>
              <span>등록일</span>
              <DatePicker.RangePicker
                value={[params.startAt ? moment(params.startAt) : null, params.endAt ? moment(params.endAt) : null]}
                onChange={([startAt, endAt]) => {
                  setParams(
                    produce((draft) => {
                      draft.startAt = startAt.format('YYYY-MM-DD');
                      draft.endAt = endAt.format('YYYY-MM-DD');
                    }),
                  );
                }}
              />
              <Select
                placeholder="검색 옵션"
                style={{ width: 120 }}
                value={params.searchType}
                onChange={(value) => {
                  setParams(
                    produce((draft) => {
                      draft.searchType = value;
                    }),
                  );
                }}
                options={[
                  { label: '작성자', value: 'Creator' },
                  { label: '상품명', value: 'PName' },
                ]}
              />
              <Input
                disabled={!params.searchType}
                placeholder="키워드를 입력하세요"
                style={{ width: 300 }}
                value={params.keyword}
                onChange={(event) => {
                  setParams(
                    produce((draft) => {
                      draft.keyword = event.target.value;
                    }),
                  );
                }}
              />
            </Space>
          </Col>
          <Col span={24}>
            <Row justify="end">
              <Col>
                <Space>
                  <Button type="primary" htmlType="submit">
                    검색
                  </Button>
                  <Button htmlType="reset">초기화</Button>
                </Space>
              </Col>
            </Row>
          </Col>
        </Row>
      </SearchForm>
      <Divider />
      <CommTable
        url="/partners/product-reviews"
        columns={[
          {
            title: '번호',
            dataIndex: 'tableIndex',
            key: 'tableIndex',
            fixed: 'left',
            width: 50,
            render: (_, __, index) => getIndex(index),
          },
          {
            title: '상품명',
            dataIndex: 'productName',
            key: 'productName',
            width: 200,
            render: (productName, obj) => <Link to={`/review/management/${obj.id}`}>{productName}</Link>,
          },
          {
            title: '별점',
            dataIndex: 'score',
            key: 'score',
            width: 100,
            render: (score) => {
              return <Rate disabled defaultValue={score} style={{ fontSize: 12 }} />;
            },
          },
          {
            title: '작성자',
            dataIndex: 'creator',
            key: 'creator',
            width: 100,
            render: (creator) => {
              return creator;
            },
          },
          {
            title: '등록일',
            dataIndex: 'createdAt',
            key: 'createdAt',
            width: 100,
            render: (createdAt) => createdAt,
          },
        ]}
        scroll={{ x: 1120 }}
      />
    </div>
  );
}

export default ReviewManagement;
