import moment from 'moment';
import React, { useEffect, useState } from 'react';
import './App.less';
import { Provider as ReduxProvider, useDispatch } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { categories } from './data/reducers/categoriesReducer';
import { codes } from './data/reducers/codesReducer';
import BasicLayout from './nav/BasicLayout';
import store from './data';
import { QueryClientProvider, QueryClient } from '@tanstack/react-query';
import { me } from './data/reducers/authReducer';
moment.locale('ko', {
  weekdays: ['일요일', '월요일', '화요일', '수요일', '목요일', '금요일', '토요일'],
  weekdaysShort: ['일', '월', '화', '수', '목', '금', '토'],
});
const Container = React.memo(() => {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loadData = async () => {
      await Promise.all([dispatch(me()), dispatch(categories()), dispatch(codes())]);
    };

    loadData()
      .catch(console.warn)
      .then(() => setLoading(false));
  }, []);

  return <BasicLayout loading={loading} />;
});

function App() {
  const queryClient = React.useMemo(() => new QueryClient(), []);

  return (
    <ReduxProvider store={store}>
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <Container />
        </BrowserRouter>
      </QueryClientProvider>
    </ReduxProvider>
  );
}

export default App;
