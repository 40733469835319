import React from 'react';
import { Button, Space, Descriptions, Modal, Input } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import classNames from 'classnames/bind';
import styles from '../../Page.module.less';
import { restApi } from '#apis';
import handleError from '#utils/handleError';
import { useReduxStore } from '#hooks/useReduxStore';
import FileUpload from '#components/datalist/FileUpload';
import moment from 'moment';

const cx = classNames.bind(styles);

// TODO :: 파일 관련 서버랑 이야기 해봐야함
function InquireStoreDetail() {
  const navigate = useNavigate();
  const { map: codeMap } = useReduxStore('codes');
  const { inquireId } = useParams();
  const [inquireInfo, setInquireInfo] = React.useState(null);
  const [answer, setAnswer] = React.useState('');
  const [saving, setSaving] = React.useState(false);

  const handleShowDetailStore = React.useCallback(() => {}, []);

  const init = React.useCallback(() => {
    restApi
      .get(`/partners/store-questions/${inquireId}`)
      .then(({ data }) => {
        const { answer, ...restData } = data;
        if (answer) {
          setAnswer(answer);
        }
        setInquireInfo(restData);
      })
      .catch(handleError);
  }, [inquireId]);

  const handleSave = async () => {
    if (saving) return;
    if (answer.length < 1) {
      Modal.warning({
        content: '답변을 입력해주세요.',
      });
      return;
    }
    try {
      setSaving(true);
      await restApi.put(`/partners/store-questions/${inquireId}`, {
        answer,
      });
      init();
      Modal.success({
        content: '저장을 완료했습니다.',
      });
      setSaving(false);
    } catch (e) {
      setSaving(false);
    }
  };

  React.useEffect(() => {
    if (!inquireId) return;
    init();
  }, [inquireId, init]);

  return (
    <div>
      <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
        <Descriptions bordered>
          {/* <Descriptions.Item span={3} labelStyle={{ width: 140 }} label="문의분야">
            {codeMap?.[inquireInfo?.storeQuestionTypeCode]}
          </Descriptions.Item> */}
          <Descriptions.Item span={3} labelStyle={{ width: 140 }} label="회원아이디">
            {inquireInfo?.userId}
          </Descriptions.Item>
          <Descriptions.Item span={3} labelStyle={{ width: 140 }} label="회원이름">
            {inquireInfo?.userName}
          </Descriptions.Item>
          <Descriptions.Item span={3} labelStyle={{ width: 140 }} label="문의내용">
            {inquireInfo?.contents}
          </Descriptions.Item>
          <Descriptions.Item span={3} labelStyle={{ width: 140 }} label="첨부파일">
            {inquireInfo?.images && (
              <Space direction="vertical">
                <FileUpload items={inquireInfo?.images} disabled hideUpload />
              </Space>
            )}
          </Descriptions.Item>
        </Descriptions>
        <Descriptions bordered>
          <Descriptions.Item span={3} labelStyle={{ width: 140 }} label="답변내용">
            <Input
              disabled={saving}
              placeholder="답변을 입력하세요."
              value={answer}
              onChange={(event) => setAnswer(event.target.value)}
            />
          </Descriptions.Item>
          <Descriptions.Item span={3} labelStyle={{ width: 140 }} label="답변일">
            {inquireInfo?.answerAt ? moment(inquireInfo?.answerAt).format('YYYY-MM-DD / HH:mm:ss') : ''}
          </Descriptions.Item>
          {/* TODO 답변자는 파트너 정보가 와야 되는데 현재는 admin의 정보가 오고 있음 */}
          <Descriptions.Item span={3} labelStyle={{ width: 140 }} label="답변자">
            {inquireInfo?.storeUserId}
          </Descriptions.Item>
        </Descriptions>
      </Space>
      <div className={cx({ buttonBox: true })}>
        <Button onClick={() => navigate(-1)}>목록</Button>
        <Button disabled={saving} onClick={handleSave}>
          저장
        </Button>
      </div>
    </div>
  );
}

export default InquireStoreDetail;
