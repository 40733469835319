import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Space, Row, Col, Modal } from 'antd';
import moment from 'moment';
import handleError from '#utils/handleError';
import useFetch from '#hooks/useFetch';
import { restApi } from '#apis/index';
import CommTable from '#components/table/CommTable';

function GoodsDeliveryFee() {
  const tableRef = React.useRef();
  const [selectedRowKeys, setSelectedRowKeys] = React.useState([]);
  const deleteFetch = useFetch();

  return (
    <Space direction="vertical" style={{ width: '100%' }}>
      <Row justify="end" gutter={10}>
        <Col>
          <Button
            type="danger"
            loading={deleteFetch.loading}
            onClick={() => {
              if (selectedRowKeys.length > 0) {
                Modal.confirm({
                  title: '선택삭제',
                  content: '선택한 배송 템플릿을 삭제하시겠습니까?',
                  centered: true,
                  onOk: () =>
                    deleteFetch
                      .fetch(Promise.all(selectedRowKeys.map((id) => restApi.delete(`/templates/${id}`))))
                      .then(() => {
                        tableRef.current?.loadData();
                        setSelectedRowKeys([]);
                      })
                      .catch((error) => {
                        handleError(error);
                        Modal.error({
                          title: '삭제 실패',
                          content: error?.response?.data?.message || '템플릿 삭제에 실패 했습니다.',
                        });
                      }),
                  okText: '확인',
                  cancelText: '취소',
                });
              } else {
                Modal.warn({
                  title: '선택삭제',
                  content: '선택 한 내역이 없습니다.',
                  centered: true,
                  okText: '확인',
                });
              }
            }}
          >
            선택삭제
          </Button>
        </Col>
        <Col>
          <Link to="/goods/delivery_template/regist">
            <Button type="primary">등록</Button>
          </Link>
        </Col>
      </Row>
      <CommTable
        ref={tableRef}
        url="/templates"
        columns={[
          {
            title: '템플릿ID',
            dataIndex: 'id',
            width: 80,
          },
          {
            title: '템플릿명',
            dataIndex: 'name',
            align: 'left',
          },
          {
            title: '수정',
            dataIndex: 'id',
            width: 80,
            render: (id) => (
              <Link to={`/goods/delivery_template/modify/${id}`}>
                <Button>수정</Button>
              </Link>
            ),
          },
          {
            title: '등록일',
            dataIndex: 'createdAt',
            width: 120,
            render: (value) => moment(value).format('YYYY-MM-DD'),
          },
          {
            title: '수정일',
            dataIndex: 'updatedAt',
            width: 120,
            render: (value) => moment(value).format('YYYY-MM-DD'),
          },
        ]}
        rowSelection={{ selectedRowKeys, onChange: setSelectedRowKeys }}
        scroll={{ x: 1100 }}
        rowKey={({ id }) => id}
      />
    </Space>
  );
}

export default GoodsDeliveryFee;
