import { Button, Descriptions, Modal, Space, Typography } from 'antd';
import moment from 'moment';
import React from 'react';
import formatComma from '#utils/formatComma';
import ShowFormForSweetTracker from '../../Buttons/ShowFormForSweetTracker';

const OrderInfoModalContent = ({ map, ...contents }) => {
  return (
    <Space direction="vertical" style={{ width: '100%', maxHeight: '80vh', overflowY: 'scroll' }} size="middle">
      <Descriptions
        colon={false}
        bordered
        column={2}
        title={
          <>
            <Typography.Paragraph>주문 상세정보</Typography.Paragraph>
            <Space>
              <Typography.Text>
                주문번호: <b style={{ color: '#4c72f1' }}>{contents.orderId}</b>
              </Typography.Text>
              <Typography.Text>/</Typography.Text>
              <Typography.Text>
                상품주문번호: <b style={{ color: '#4c72f1' }}>{contents.orderProductId}</b>
              </Typography.Text>
            </Space>
          </>
        }
        labelStyle={{ width: 140 }}
        className="descriptions-table-layout-fixed"
      >
        <Descriptions.Item label="상품명" span={2}>
          {contents.orderProductInfo?.productName}
        </Descriptions.Item>
        <Descriptions.Item label="옵션" span={2}>
          {contents.orderProductInfo?.optionString}
        </Descriptions.Item>
        <Descriptions.Item label="주문수량" span={1}>
          {formatComma(contents.orderProductInfo?.count)}
        </Descriptions.Item>
        <Descriptions.Item label="상품주문상태" span={1}>
          {map[contents.orderProductInfo?.orderProductStateCode]}
        </Descriptions.Item>
      </Descriptions>
      <Descriptions
        colon={false}
        bordered
        column={2}
        labelStyle={{ width: 140 }}
        className="descriptions-table-layout-fixed"
      >
        <Descriptions.Item label="구매자명" span={1}>
          {contents.orderProductInfo?.buyerName}
        </Descriptions.Item>
        <Descriptions.Item label="구매자 아이디" span={1}>
          {contents.orderProductInfo?.buyerUserId}
        </Descriptions.Item>
        <Descriptions.Item label="총 상품금액" span={2}>
          {formatComma(contents.orderProductInfo?.totalAmount)}
        </Descriptions.Item>
      </Descriptions>
      <Descriptions
        colon={false}
        bordered
        title="배송정보"
        column={2}
        labelStyle={{ width: 140 }}
        className="descriptions-table-layout-fixed"
      >
        <Descriptions.Item label="발송지연사유" span={1}>
          {contents.deliveryInfo?.deliveryDelayReason}
        </Descriptions.Item>
        <Descriptions.Item label="당일발송여부" span={1}>
          {contents.deliveryInfo?.isSendToday ? 'Y' : 'N'}
        </Descriptions.Item>
        <Descriptions.Item label="발송기한" span={1}>
          {contents.deliveryInfo?.sendLimitAt &&
            moment(contents.deliveryInfo.sendLimitAt).format('YYYY-MM-DD HH:mm:ss')}
        </Descriptions.Item>
        <Descriptions.Item label="예약구매여부" span={1}>
          {contents.deliveryInfo?.isPreOrder ? 'Y' : 'N'}
        </Descriptions.Item>
        <Descriptions.Item label="발송처리일" span={1}>
          {contents.deliveryInfo?.sendAt && moment(contents.deliveryInfo.sendAt).format('YYYY-MM-DD HH:mm:ss')}
        </Descriptions.Item>
        <Descriptions.Item label="발주확인일" span={1}>
          {contents.deliveryInfo?.placingOrderAt &&
            moment(contents.deliveryInfo.placingOrderAt).format('YYYY-MM-DD HH:mm:ss')}
        </Descriptions.Item>
        <Descriptions.Item label="배송방법" span={1}>
          {map[contents.deliveryInfo?.method]}
        </Descriptions.Item>
        <Descriptions.Item label="배송완료일" span={1}>
          {contents.deliveryInfo?.completeAt && moment(contents.deliveryInfo.completeAt).format('YYYY-MM-DD HH:mm:ss')}
        </Descriptions.Item>
        <Descriptions.Item label="택배사" span={1}>
          {map[contents.deliveryInfo?.courierCode]}
        </Descriptions.Item>
        <Descriptions.Item label="배송상세상태" span={1}>
          {map[contents.deliveryInfo?.stateCode]}
        </Descriptions.Item>
        <Descriptions.Item label="구매확정일" span={1}>
          {contents.deliveryInfo?.confirmAt && moment(contents.deliveryInfo.confirmAt).format('YYYY-MM-DD HH:mm:ss')}
        </Descriptions.Item>
        <Descriptions.Item label="송장번호" span={1}>
          {contents.deliveryInfo?.invoiceNumber}
          {typeof contents.deliveryInfo?.invoiceNumber === 'string' && (
            <Button
              style={{ marginLeft: 20 }}
              onClick={() => {
                Modal.info({
                  title: '배송조회',
                  content: <ShowFormForSweetTracker invoiceNumber={contents.deliveryInfo.invoiceNumber} />,
                  centered: true,
                  width: 500,
                  okText: '확인',
                  closable: true,
                });
              }}
            >
              배송조회
            </Button>
          )}
        </Descriptions.Item>
        <Descriptions.Item label="오류송장등록사유" span={1}>
          {contents.deliveryInfo?.wrongInvoiceReason}
        </Descriptions.Item>
        <Descriptions.Item label="오류송장 등록일" span={1}>
          {contents.deliveryInfo?.wrongInvoiceAt &&
            moment(contents.deliveryInfo.wrongInvoiceAt).format('YYYY-MM-DD HH:mm:ss')}
        </Descriptions.Item>
        <Descriptions.Item label="발송기한" span={1}>
          {contents.deliveryInfo?.sendLimitAt &&
            moment(contents.deliveryInfo.sendLimitAt).format('YYYY-MM-DD HH:mm:ss')}
        </Descriptions.Item>
        <Descriptions.Item label="예약구매여부" span={1}>
          {contents.deliveryInfo?.isPreOrder ? 'Y' : 'N'}
        </Descriptions.Item>
        <Descriptions.Item label="개인통관고유부호" span={2}>
          {contents.deliveryInfo?.pcc}
        </Descriptions.Item>
      </Descriptions>
      <Descriptions
        colon={false}
        bordered
        column={1}
        title="주문처리이력"
        labelStyle={{ width: 140 }}
        className="descriptions-table-layout-fixed"
      >
        <Descriptions.Item label="주문">
          {contents.orderLog?.orderAt && moment(contents.orderLog?.orderAt).format('YYYY-MM-DD')}
        </Descriptions.Item>
        <Descriptions.Item label="결제완료">
          {contents.orderLog?.payAt && moment(contents.orderLog?.payAt).format('YYYY-MM-DD')}
        </Descriptions.Item>
        <Descriptions.Item label="발송처리">
          {contents.orderLog?.sendAt && moment(contents.orderLog?.sendAt).format('YYYY-MM-DD')}
        </Descriptions.Item>
        <Descriptions.Item label="발주확인">
          {contents.orderLog?.placingOrderAt && moment(contents.orderLog?.placingOrderAt).format('YYYY-MM-DD')}
        </Descriptions.Item>
        <Descriptions.Item label="집화처리">
          {contents.orderLog?.pickUpAt && moment(contents.orderLog?.pickUpAt).format('YYYY-MM-DD')}
        </Descriptions.Item>
        <Descriptions.Item label="시스템 배송완료처리">
          {contents.orderLog?.systemDeliveryCompleteAt &&
            moment(contents.orderLog?.systemDeliveryCompleteAt).format('YYYY-MM-DD')}
        </Descriptions.Item>
        <Descriptions.Item label="배송상태최종변경">
          {contents.orderLog?.lastUpdateDeliveryAt &&
            moment(contents.orderLog?.lastUpdateDeliveryAt).format('YYYY-MM-DD')}
        </Descriptions.Item>
        <Descriptions.Item label="구매확정">
          {contents.orderLog?.confirmAt && moment(contents.orderLog?.confirmAt).format('YYYY-MM-DD')}
        </Descriptions.Item>
      </Descriptions>
    </Space>
  );
};

export default OrderInfoModalContent;
