import React from 'react';
import { Button, Col, DatePicker, Divider, Form, Modal, Row, Select, Space } from 'antd';
import CardWrapper from '#components/common/CardWrapper';
import SearchForm from '#components/SearchForm';
import CommTable from '#components/table/CommTable';
import produce from 'immer';
import useIndex from '#hooks/useIndex';
import formatComma from '#utils/formatComma';
import handleError from '#utils/handleError';
import moment from 'moment';
import { useReduxStore } from '#hooks/useReduxStore';
import RequiredOrNotLabel from '#components/RequiredOrNotLabel';
import { restApi } from '#apis';
import InputMoney from '#components/Input/InputMoney';

const InnerRequestCalculationModal = ({ enableCalculateAmount = 0, onFinishRequest, ...args }) => {
  const [requesting, setRequesting] = React.useState(false);
  const [amount, setAmount] = React.useState('');

  const handleRequest = async () => {
    try {
      if (requesting) return;

      if (!amount) {
        Modal.warning({
          content: '신청할 정산 금액을 입력하세요',
        });
      }

      setRequesting(true);
      await restApi.post('/partners/calculate-request', {
        amount,
      });

      Modal.success({
        content: '예치금 정산이 신청되었습니다.​',
        onOk: () => {
          onFinishRequest();
          Modal.destroyAll();
          setRequesting(false);
        },
      });
    } catch (e) {
      handleError(e);
      Modal.error({
        content: e?.response?.data?.message || '정산 신청에 실패했습니다. 관리자에 문의해 주세요.',
        onOk: Modal.destroyAll,
      });
      setRequesting(false);
    }
  };

  return (
    <div {...args}>
      <p>정산신청</p>
      <Form style={{ marginTop: 20 }}>
        <div style={{ display: 'flex', alignItems: 'center', marginBottom: 10 }}>
          <RequiredOrNotLabel style={{ marginRight: 15 }} label="정산가능 금액" />
          <span style={{ marginLeft: 10 }}>{formatComma(enableCalculateAmount)}원</span>
        </div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <RequiredOrNotLabel style={{ marginRight: 15 }} label="정산신청 금액" required />
          <InputMoney
            style={{ width: 200 }}
            disabled={requesting}
            value={amount}
            onChange={(value) => {
              setAmount(value);
            }}
            max={enableCalculateAmount}
            onMax={() => {
              Modal.warning({
                content: '정산 가능 금액을 넘을 수 없습니다.',
              });
            }}
          />
        </div>
      </Form>
      <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: -25, marginTop: 30 }}>
        <Button
          onClick={() => {
            Modal.destroyAll();
          }}
        >
          취소
        </Button>
        <Button disabled={!amount || requesting} onClick={handleRequest} type="primary" style={{ marginLeft: 10 }}>
          신청
        </Button>
      </div>
    </div>
  );
};

function SalesCalculate() {
  const { getIndex } = useIndex();
  const { map } = useReduxStore('codes');
  const tableRef = React.useRef();
  const initailParams = React.useMemo(
    () => ({
      dateType: null,
      startAt: null,
      endAt: null,
      isProcess: null,
      searchType: null,
    }),
    [],
  );

  const [params, setParams] = React.useState(initailParams);
  const [requesting, setRequesting] = React.useState(false);
  const [dashboardInfo, setDashboardInfo] = React.useState(null);

  const init = async () => {
    try {
      const result = await Promise.all([
        restApi.get('/partners/calculate-request/amount'),
        restApi.get('/partners/calculate-request/paid/amount'),
        restApi.get('/partners/calculate-request/unpaid/amount'),
      ]);

      setDashboardInfo({
        processing: result[0].data,
        finished: result[1].data,
        enableAmount: result[2].data,
      });

      if (tableRef.current) {
        tableRef.current.loadData();
      }
    } catch (e) {
      handleError(e);
      Modal.error('정산할 금액을 불러오는데 실패했습니다. 관리자에 문의해주세요.');
    }
  };

  const handleRequestCalculation = async () => {
    try {
      if (requesting) return;
      setRequesting(true);
      Modal.info({
        content: (
          <InnerRequestCalculationModal onFinishRequest={init} enableCalculateAmount={dashboardInfo.enableAmount} />
        ),
        okButtonProps: { style: { display: 'none' } },
      });
      setRequesting(false);
    } catch (e) {
      setRequesting(false);

      Modal.warning({
        content: '정산 신청에 실패했습니다. 관리자에게 문의해주세요.',
      });
    }
  };

  React.useEffect(() => {
    init();
  }, []);

  return (
    <div>
      {dashboardInfo && (
        <>
          <div style={{ flexDirection: 'row', display: 'flex', flexWrap: 'wrap' }}>
            <CardWrapper
              title="정산가능금액(예치금)"
              value={formatComma(dashboardInfo.enableAmount)}
              content={
                dashboardInfo.enableAmount ? (
                  <Button onClick={handleRequestCalculation} style={{ width: '100%' }}>
                    정산금신청
                  </Button>
                ) : null
              }
            />
            <CardWrapper title="현재 정산 신청중인 금액" value={formatComma(dashboardInfo.processing)} />
            <CardWrapper title="정산 완료 금액(일주일)" value={formatComma(dashboardInfo.finished)} />
          </div>
          <div style={{ color: '#999', fontSize: 13, marginTop: 5 }}>
            * 구매확정 기준 00시부터 24시까지 집계된 정산금액은 다음날 04:00시에 합산됩니다.
          </div>
        </>
      )}

      <Divider />
      <SearchForm
        params={params}
        onInit={setParams}
        onReset={() => {
          setParams(initailParams);
          return initailParams;
        }}
      >
        <Row gutter={[50, 15]}>
          <Col span={24}>
            <Space style={{ width: '100%' }}>
              <Select
                value={params.dateType}
                onChange={(value) => {
                  setParams(
                    produce((draft) => {
                      draft.dateType = value;
                    }),
                  );
                }}
                options={[
                  { label: '신청일', value: 'CreatedAt' },
                  { label: '처리완료일', value: 'ProcessAt' },
                ]}
                placeholder="날짜옵션"
                style={{ width: 120 }}
              />
              <DatePicker.RangePicker
                disabled={!params.dateType}
                value={[params.startAt ? moment(params.startAt) : null, params.endAt ? moment(params.endAt) : null]}
                onChange={([startAt, endAt]) => {
                  setParams(
                    produce((draft) => {
                      draft.startAt = startAt.format('YYYY-MM-DD');
                      draft.endAt = endAt.format('YYYY-MM-DD');
                    }),
                  );
                }}
              />
              <Select
                value={params.isProcess}
                onChange={(value) => {
                  setParams(
                    produce((draft) => {
                      draft.isProcess = value;
                    }),
                  );
                }}
                options={[
                  { label: '미처리', value: 'false' },
                  { label: '처리완료', value: 'true' },
                ]}
                placeholder="처리여부"
                style={{ width: 120 }}
              />
              <Button type="primary" htmlType="submit">
                검색
              </Button>
              <Button htmlType="reset">초기화</Button>
            </Space>
          </Col>
        </Row>
      </SearchForm>
      <Divider />
      <CommTable
        ref={tableRef}
        url="/partners/calculate-request"
        columns={[
          {
            title: '번호',
            dataIndex: 'orderNum',
            key: 'orderNum',
            width: 100,
            render: (_, __, index) => getIndex(index),
          },
          {
            title: '신청금액',
            dataIndex: 'amount',
            key: 'amount',
            width: 150,
            render: (amount) => {
              return typeof amount === 'number' ? `${formatComma(amount)} 원` : null;
            },
          },
          {
            title: '신청일',
            dataIndex: 'createdAt',
            width: 100,
            key: 'createdAt',
            render: (createdAt) => createdAt,
          },
          {
            title: '처리여부',
            dataIndex: 'calculateStateCode',
            key: 'calculateStateCode',
            width: 200,
            render: (calculateStateCode) => map[calculateStateCode],
          },
          {
            title: '처리완료일',
            dataIndex: 'processAt',
            key: 'processAt',
            width: 200,
            render: (processAt) => processAt,
          },
        ]}
        scroll={{ x: 1120 }}
      />
    </div>
  );
}

export default SalesCalculate;
