import React from 'react';
import { useLocation, useParams } from 'react-router-dom';
import qs from 'query-string';

// TODO :: 디테일 페이지 확인
function GoodsItemsDetail(props) {
  const params = useParams();
  const { search } = useLocation();

  return (
    <div>
      <h1>GoodsItemsDetail</h1>
      <div>{JSON.stringify(params, null, 2)}</div>
      <div>{JSON.stringify(qs.parse(search), null, 2)}</div>
    </div>
  );
}

export default GoodsItemsDetail;
