import React from 'react';
import {
  Button,
  Modal,
  Descriptions,
  Space,
  Typography,
  Row,
  Col,
  Table,
  Input,
  Form,
  Switch,
} from 'antd';
import { useNavigate } from 'react-router-dom';
import { arrayMoveImmutable } from 'array-move';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import classNames from 'classnames/bind';
import { MenuOutlined } from '@ant-design/icons';
import produce from 'immer';
import { getFileHost, restApi } from '#apis/index';
import styles from '../../Page.module.less';
import FileUpload from '#components/datalist/FileUpload';
import AddressModal from '#components/AddressModal';
import RegexObj from '#utils/regex';
import RequiredOrNotLabel from '#components/RequiredOrNotLabel';
import SettingOpenAndCloseTime from '#components/Picker/SettingOpenAndCloseTime';
import { kakaoLocalApi } from '../../../apis';
import { setWriteInfo } from '../../../data/reducers/authReducer';
import { useDispatch } from 'react-redux';
import { useReduxStore } from '../../../hooks/useReduxStore';

const cx = classNames.bind(styles);

const SortableTable = React.memo(({ dataSource: initialDataSource = [], columns = [], onOk, onCancel }) => {
  const [dataSource, setDataSource] = React.useState(initialDataSource.map((e, index) => ({ ...e, index })));
  const DragHandle = React.useMemo(
    () => SortableHandle(() => <MenuOutlined style={{ cursor: 'grab', color: '#999' }} />),
    [],
  );
  const SortableItem = React.useMemo(() => SortableElement((props) => <tr {...props} />), []);
  const SortableBody = React.useMemo(() => SortableContainer((props) => <tbody {...props} />), []);

  return (
    <Space direction="vertical" style={{ width: '100%' }}>
      <Table
        pagination={false}
        dataSource={dataSource}
        columns={[
          {
            title: '정렬',
            width: 45,
            render: () => <DragHandle />,
          },
          ...columns,
        ]}
        rowKey="index"
        components={{
          body: {
            wrapper: (props) => (
              <SortableBody
                useDragHandle
                disableAutoscroll
                helperClass="row-dragging"
                onSortEnd={({ oldIndex, newIndex }) => {
                  if (oldIndex !== newIndex) {
                    setDataSource(arrayMoveImmutable([].concat(dataSource), oldIndex, newIndex).filter((e) => !!e));
                  }
                }}
                {...props}
              />
            ),
            row: ({ className, style, ...props }) => {
              return <SortableItem index={dataSource.findIndex((e) => e.index === props['data-row-key'])} {...props} />;
            },
          },
        }}
      />
      <Row justify="end" style={{ marginBottom: -15 }}>
        <Col>
          <Space>
            <Button
              onClick={() => {
                Modal.destroyAll();
                onCancel?.(dataSource);
              }}
            >
              취소
            </Button>
            <Button
              type="primary"
              onClick={() => {
                Modal.destroyAll();
                onOk?.(dataSource);
              }}
            >
              저장
            </Button>
          </Space>
        </Col>
      </Row>
    </Space>
  );
});

const DATA_KEYS = {
  STORE_NAME: 'storeName',
  LOGO: 'logo',
  STORE_ADDR_MAP: 'addrMap',
  STORE_ADDR_DETAIL: 'addrDetail',
  STORE_PHONE: 'phone',
  STORE_OPEN_LIST: 'openInfos',
  HOLIDAY: 'holiday',
  INTRODUCTION: 'introduction',
  ADDTION_IMAGES: 'storeImages',
  BIZ_X: 'locX',
  BIZ_Y: 'locY',
};

function PartnersStoreDetailsModify() {
  const navigate = useNavigate();
  const [showPostCode, setShowPostCode] = React.useState(false);
  const detailAddrInput = React.useRef(null);
  const dispatch = useDispatch();
  const { principal } = useReduxStore('auth');

  const sectionTitle = principal?.writeInfo ? '수정' : '등록';

  // 초기값이 없으면 데이터 아직 받을 준비가 안된거
  const STORE_TIME_LIST = React.useMemo(
    () => [
      {
        closeAt: null,
        day: 'MON',
        isOpen: true,
        openAt: null,
      },
      {
        closeAt: null,
        day: 'TUE',
        isOpen: true,
        openAt: null,
      },
      {
        closeAt: null,
        day: 'WED',
        isOpen: true,
        openAt: null,
      },
      {
        closeAt: null,
        day: 'THU',
        isOpen: true,
        openAt: null,
      },
      {
        closeAt: null,
        day: 'FRI',
        isOpen: true,
        openAt: null,
      },
      {
        closeAt: null,
        day: 'SAT',
        isOpen: true,
        openAt: null,
      },
      {
        closeAt: null,
        day: 'SUN',
        isOpen: true,
        openAt: null,
      },
    ],
    [],
  );

  const [requestBody, setRequestBody] = React.useState({
    [DATA_KEYS.STORE_NAME]: '',
    [DATA_KEYS.INTRODUCTION]: '',
    [DATA_KEYS.STORE_ADDR_MAP]: '',
    [DATA_KEYS.STORE_ADDR_DETAIL]: '',
    [DATA_KEYS.STORE_PHONE]: '',
    [DATA_KEYS.STORE_OPEN_LIST]: STORE_TIME_LIST.map((time) => time),
    [DATA_KEYS.HOLIDAY]: '',
    [DATA_KEYS.ADDTION_IMAGES]: [],
    [DATA_KEYS.LOGO]: '',
    [DATA_KEYS.BIZ_X]: '',
    [DATA_KEYS.BIZ_Y]: '',
  });

  // map 돌면서 라벨 박아주는 용도 입니다.
  const dateObj = React.useMemo(
    () => ({
      MON: '월요일',
      TUE: '화요일',
      WED: '수요일',
      THU: '목요일',
      FRI: '금요일',
      SAT: '토요일',
      SUN: '일요일',
    }),
    [],
  );

  const REQUEST_BODY_DATA_INFO = React.useMemo(
    () => ({
      [DATA_KEYS.STORE_NAME]: { label: '상점명', required: true },
      [DATA_KEYS.LOGO]: { label: '상점로고', required: false },
      [DATA_KEYS.INTRODUCTION]: { label: '상점 소개', required: true },
      [DATA_KEYS.STORE_ADDR_MAP]: { label: '상점 주소', required: true },
      [DATA_KEYS.STORE_ADDR_DETAIL]: { label: '상점 상세 주소', required: true },
      [DATA_KEYS.STORE_PHONE]: { label: '상점 연락처', required: true },
      [DATA_KEYS.STORE_OPEN_LIST]: { label: '상점 이용시간', required: true },
      [DATA_KEYS.HOLIDAY]: { label: '공휴일' },
      [DATA_KEYS.ADDTION_IMAGES]: { label: '상점 이미지', required: false },
    }),
    [],
  );

  const handleAskGoBeforePage = React.useCallback(() => {
    Modal.confirm({
      onOk: () => {
        navigate(-1);
      },
      content: '입력했던 내용이 전부 사라집니다. 계속 하시겠습니까?',
      okText: '확인',
      cancelText: '취소',
      centered: true,
      closable: true,
    });
  }, [navigate]);

  const handleModify = async () => {
    try {
      const checkList = Object.values(DATA_KEYS);

      for (let key of checkList) {
        if (REQUEST_BODY_DATA_INFO[key]?.required && !requestBody[key]) {
          Modal.warning({
            content: `${REQUEST_BODY_DATA_INFO[key].label} 입력하세요.`,
          });
          return;
        }
      }

      for (let day of requestBody[DATA_KEYS.STORE_OPEN_LIST]) {
        if (day.isOpen) {
          if (!day.openAt) {
            Modal.warning({
              content: `${dateObj[day.day]} 오픈 시간을 입력해주세요.`,
            });
            return;
          }
          if (!day.closeAt) {
            Modal.warning({
              content: `${dateObj[day.day]} 마감 시간을 입력해주세요.`,
            });
            return;
          }
        }
      }
      await restApi.put(`/partners/stores`, requestBody);
      dispatch(setWriteInfo());
      Modal.success({
        content: `${sectionTitle}이 완료 되었습니다.`,
        onOk: () => {
          navigate('/store/detail');
        },
        centered: true,
      });
    } catch (e) {
      Modal.error({
        title: '실패',
        content: `${sectionTitle}에 실패했습니다. 잠시후 시도해주세요.`,
        centered: true,
        okText: '확인',
      });
    }
  };

  React.useEffect(() => {
    if (!STORE_TIME_LIST || !DATA_KEYS) return;
    restApi
      .get('/partners/stores')
      .then(({ data }) => {
        // console.log('data ::::: ', data);
        // 받아온 데이터의 storeOpenList를 키 값으로 사용할 수 있도록 객체 형태로 먼저 만들어 줍니다.
        const dateObj = data.openInfos.reduce((reduced, now) => {
          reduced[now.day] = now;
          return reduced;
        }, {});

        const settedDateList = STORE_TIME_LIST.map((time) => dateObj[time.day] || time);

        setRequestBody(
          produce((draft) => {
            draft[DATA_KEYS.STORE_NAME] = data.storeName;
            draft[DATA_KEYS.INTRODUCTION] = data.introduction;
            draft[DATA_KEYS.STORE_ADDR_MAP] = data.addrMap;
            draft[DATA_KEYS.STORE_ADDR_DETAIL] = data.addrDetail;
            draft[DATA_KEYS.STORE_PHONE] = data.phone;
            draft[DATA_KEYS.STORE_OPEN_LIST] = settedDateList;
            draft[DATA_KEYS.HOLIDAY] = data.holiday;
            draft.youtubeUrl = data.youtubeUrl;
            draft[DATA_KEYS.ADDTION_IMAGES] = data.storeImages?.map((value) => value.imageId>-1?value.imageId:value?.youtubeUrl) || [];
            draft[DATA_KEYS.LOGO] = data.logo;
          }),
        );
      })
      .catch((e) => console.warn(e));
  }, [STORE_TIME_LIST]);

  console.log(requestBody?.[DATA_KEYS.ADDTION_IMAGES])

  return (
    <div>
      <div>
        <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
          <Form>
            <Descriptions bordered>
              <Descriptions.Item
                span={3}
                labelStyle={{ width: 140 }}
                label={<RequiredOrNotLabel label="상점명" required />}
              >
                <Input
                  type="text"
                  style={{ maxWidth: '500px' }}
                  value={requestBody[DATA_KEYS.STORE_NAME]}
                  placeholder="상점명을 입력하세요."
                  onChange={(event) => {
                    setRequestBody(
                      produce((draft) => {
                        draft[DATA_KEYS.STORE_NAME] = event.target.value;
                      }),
                    );
                  }}
                />
              </Descriptions.Item>
              <Descriptions.Item labelStyle={{ width: 180 }} label={<RequiredOrNotLabel label="상점로고" />} span={3}>
                <Space direction="vertical">
                  <FileUpload
                    listType="picture-card"
                    items={requestBody?.[DATA_KEYS.LOGO] || []}
                    onDoneChange={(fileListDone) => {
                      setRequestBody(
                        produce((draft) => {
                          draft[DATA_KEYS.LOGO] = fileListDone.map(({ response }) => response[0].id)[0];
                        }),
                      );
                    }}
                    hideUpload={({ length }) => length > 0}
                    maxCount={1}
                  />
                  <Typography.Paragraph>
                    <ul>
                      <li>첨부파일은 jpg, jpeg, png, gif 만 첨부가 가능합니다.(1개만 등록 가능)</li>
                      <li>권장크기: 300 x 300</li>
                    </ul>
                  </Typography.Paragraph>
                  <Input
                      value={requestBody?.youtubeUrl}
                      style={{width: 300}}
                      placeholder={'유튜브 주소를 입력해주세요.'}
                      onChange={(event) => {
                        setRequestBody(
                            produce((draft) => {
                              draft.youtubeUrl = event.target.value;
                            }),
                        );
                      }}
                  />
                </Space>
              </Descriptions.Item>
              <Descriptions.Item
                span={3}
                labelStyle={{ width: 140 }}
                label={<RequiredOrNotLabel label="상점주소" required />}
              >
                <div>
                  <Button
                    type="primary"
                    onClick={() => {
                      setShowPostCode(true);
                    }}
                  >
                    주소 검색
                  </Button>
                  <div style={{ display: 'flex', marginTop: '10px' }}>
                    <Input
                      style={{ minWidth: '350px', marginRight: '10px' }}
                      disabled
                      value={requestBody[DATA_KEYS.STORE_ADDR_MAP]}
                    />
                    <Input
                      ref={detailAddrInput}
                      value={requestBody[DATA_KEYS.STORE_ADDR_DETAIL]}
                      onChange={(event) => {
                        setRequestBody(
                          produce((draft) => {
                            draft[DATA_KEYS.STORE_ADDR_DETAIL] = event.target.value;
                          }),
                        );
                      }}
                    />
                  </div>
                </div>
              </Descriptions.Item>
              <Descriptions.Item
                span={3}
                labelStyle={{ width: 140 }}
                label={<RequiredOrNotLabel label="전화번호" required />}
              >
                <Input
                  type="tel"
                  style={{ maxWidth: '400px' }}
                  value={requestBody?.phone}
                  onChange={(event) => {
                    if (RegexObj.onlyNumber.test(event.target.value) || event.target.value === '') {
                      setRequestBody(
                        produce((draft) => {
                          draft[DATA_KEYS.STORE_PHONE] = event.target.value;
                        }),
                      );
                    }
                  }}
                />
              </Descriptions.Item>
              <Descriptions.Item
                span={3}
                labelStyle={{ width: 140 }}
                label={<RequiredOrNotLabel label="운영시간/공휴일" required />}
                contentStyle={{ padding: 0 }}
              >
                <Descriptions bordered>
                  {requestBody?.[DATA_KEYS.STORE_OPEN_LIST]?.map((timeDate, index) => (
                    <Descriptions.Item
                      key={timeDate.day}
                      span={3}
                      labelStyle={{ width: 140 }}
                      label={dateObj[timeDate.day]}
                    >
                      <SettingOpenAndCloseTime
                        startAt={requestBody[DATA_KEYS.STORE_OPEN_LIST][index].openAt}
                        onStartAt={(event) => {
                          setRequestBody(
                            produce((draft) => {
                              if (!event) {
                                draft[DATA_KEYS.STORE_OPEN_LIST][index].openAt = null;
                                draft[DATA_KEYS.STORE_OPEN_LIST][index].closeAt = null;
                                draft[DATA_KEYS.STORE_OPEN_LIST][index].isOpen = false;
                              } else {
                                if (!draft[DATA_KEYS.STORE_OPEN_LIST][index].isOpen) {
                                  draft[DATA_KEYS.STORE_OPEN_LIST][index].isOpen = true;
                                }
                                draft[DATA_KEYS.STORE_OPEN_LIST][index].openAt = event.format('HH:mm');
                              }
                            }),
                          );
                        }}
                        closeAt={requestBody[DATA_KEYS.STORE_OPEN_LIST][index].closeAt}
                        onCloseAt={(event) => {
                          setRequestBody(
                            produce((draft) => {
                              if (!event) {
                                draft[DATA_KEYS.STORE_OPEN_LIST][index].closeAt = null;
                              } else {
                                if (!draft[DATA_KEYS.STORE_OPEN_LIST][index].isOpen) {
                                  draft[DATA_KEYS.STORE_OPEN_LIST][index].isOpen = true;
                                }
                                draft[DATA_KEYS.STORE_OPEN_LIST][index].closeAt = event.format('HH:mm');
                              }
                            }),
                          );
                        }}
                        onClickCheckOffday={(event) => {
                          setRequestBody(
                            produce((draft) => {
                              draft[DATA_KEYS.STORE_OPEN_LIST][index].closeAt = null;
                              draft[DATA_KEYS.STORE_OPEN_LIST][index].openAt = null;
                              draft[DATA_KEYS.STORE_OPEN_LIST][index].isOpen = !event.target.checked;
                            }),
                          );
                        }}
                        checkOffDay={!requestBody[DATA_KEYS.STORE_OPEN_LIST][index].isOpen}
                      />
                    </Descriptions.Item>
                  ))}
                  <Descriptions.Item span={3} labelStyle={{ width: 140 }} label="공휴일">
                    <Input
                      value={requestBody[DATA_KEYS.HOLIDAY]}
                      onChange={(event) => {
                        setRequestBody(
                          produce((draft) => {
                            draft[DATA_KEYS.HOLIDAY] = event.target.value;
                          }),
                        );
                      }}
                    />
                  </Descriptions.Item>
                </Descriptions>
              </Descriptions.Item>
              <Descriptions.Item
                span={3}
                labelStyle={{ width: 140 }}
                label={<RequiredOrNotLabel label="상점소개" required />}
              >
                <Input
                  value={requestBody[DATA_KEYS.INTRODUCTION]}
                  placeholder="상점 소개를 입력하세요."
                  onChange={(event) => {
                    setRequestBody(
                      produce((draft) => {
                        draft[DATA_KEYS.INTRODUCTION] = event.target.value;
                      }),
                    );
                  }}
                  type="text"
                />
              </Descriptions.Item>
              <Descriptions.Item labelStyle={{ width: 180 }} label={<RequiredOrNotLabel label="상점사진" />} span={3}>
                <Space direction="vertical">
                  {requestBody?.[DATA_KEYS.ADDTION_IMAGES]&&requestBody?.[DATA_KEYS.ADDTION_IMAGES]?.map(
                      (value, index) => {

                        return (
                            <div key={index}>
                              <Switch
                                  checkedChildren="이미지"
                                  unCheckedChildren="유튜브"
                                  className="img-youtube-switch"
                                  checked={parseInt(
                                          requestBody[DATA_KEYS.ADDTION_IMAGES][index]) >
                                      -1}
                                  onChange={(value) => {
                                    setRequestBody(produce((draft) => {
                                      draft[DATA_KEYS.ADDTION_IMAGES][index] = value
                                          ? 0
                                          : null;
                                    }));
                                  }}
                              />
                              {parseInt(
                                  requestBody[DATA_KEYS.ADDTION_IMAGES][index]) >
                              -1 ?

                                  <FileUpload
                                      listType="picture-card"

                                      items={value}
                                      onDoneChange={(fileListDone) => {
                                        setRequestBody(
                                            produce((draft) => {
                                              draft[DATA_KEYS.ADDTION_IMAGES][index] = fileListDone.map(
                                                  ({response}) => response[0].id)?.[0];
                                            }),
                                        );
                                      }}
                                      maxCount={1}
                                  /> : <Input defaultValue={'https://'} value={value} onChange={(e) => {
                                    setRequestBody(produce((draft) => {
                                      draft[DATA_KEYS.ADDTION_IMAGES][index] = e?.target?.value;
                                    }));
                                  }} placeholder={'youtubeUrl을 입력해주세요.'}/>
                              }
                              <Button onClick={()=>setRequestBody(
                                  produce((draft) => {
                                    draft[DATA_KEYS.ADDTION_IMAGES].splice(index,1);
                                  })

                              )}>제거</Button>
                            </div>
                        );
                      })}
                  <div style={{display:'flex'}}>
                  <Button
                    onClick={() => {
                      Modal.info({
                        title: '추가이미지 순서 변경',
                        centered: true,
                        content: (
                          <Space direction="vertical">
                            <Typography.Text>이미지를 선택해서 원하는 위치로 끌어서 옮겨주세요</Typography.Text>
                            <SortableTable
                              dataSource={requestBody?.[DATA_KEYS.ADDTION_IMAGES].map((image) => ({ image }))}
                              columns={[
                                {
                                  title: '이미지',
                                  width: 150,
                                  dataIndex: 'image',
                                  render: (value) => (
                                    <img src={`${getFileHost()}/${value}`} alt="이미지" style={{ width: '100%' }} />
                                  ),
                                },
                                {
                                  title: '이미지 순서',
                                  render: (_, $, index) => `순서 ${index + 1}`,
                                },
                              ]}
                              onOk={(dataSource) => {
                                setRequestBody(
                                  produce((draft) => {
                                    draft[DATA_KEYS.ADDTION_IMAGES] = dataSource?.map(({ image }) => image);
                                  }),
                                );
                              }}
                              onCancel={() => {}}
                            />
                          </Space>
                        ),
                        okButtonProps: { style: { display: 'none' } },
                      });
                    }}
                  >
                    순서변경
                  </Button>
                  <Button
                      style={{marginLeft:'10px'}}
                      onClick={() => {
                        setRequestBody(
                            produce((draft) => {
                              draft[DATA_KEYS.ADDTION_IMAGES].push(0);
                            }),
                        );
                      }}
                  >
                    추가
                  </Button>
                  </div>
                  <Typography.Paragraph>
                    <ul>
                      <li>첨부파일은 jpg, jpeg, png, gif 만 첨부가 가능합니다.(최대 9개까지 추가 가능)</li>
                      <li>권장크기: 1000 x 1000</li>
                    </ul>
                  </Typography.Paragraph>
                </Space>
              </Descriptions.Item>
            </Descriptions>
          </Form>
        </Space>
        <div className={cx({ buttonBox: true })}>
          <Button onClick={handleAskGoBeforePage}>취소</Button>
          <Button type="primary" onClick={handleModify}>
            {sectionTitle}
          </Button>
        </div>
      </div>
      <AddressModal
        showPostCode={showPostCode}
        onCancel={() => {
          setShowPostCode(false);
        }}
        onSelected={async (data) => {
          try {
            const {
              data: { documents },
            } = await kakaoLocalApi.get('/v2/local/search/address', {
              params: {
                query: data.address || data.roadAddress || data.jibunAddress,
              },
            });

            setRequestBody(
              produce((draft) => {
                if (draft.storeAddrMap !== data.address) {
                  draft['sigunguCode'] = data.sigunguCode;
                  draft['roadNameCode'] = data.roadnameCode;
                  draft['areaCode'] = data.bcode;
                  draft['locX'] = documents[0]?.x;
                  draft['locY'] = documents[0]?.y;
                  draft[DATA_KEYS.STORE_ADDR_MAP] = data.address;
                  draft[DATA_KEYS.STORE_ADDR_DETAIL] = '';
                  detailAddrInput?.current?.focus();
                }
              }),
            );
            console.log(requestBody);
          } catch (e) {
            alert('주소를 등록하는데 실패했습니다. 관리자에 문의해주세요.');
            console.warn('error :::: ', e);
          } finally {
            setShowPostCode(false);
          }
        }}
      />
    </div>
  );
}

export default PartnersStoreDetailsModify;
