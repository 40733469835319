import React from 'react';
import { Button, Space, Descriptions, Spin, Input, Select, Modal, Statistic } from 'antd';
import classNames from 'classnames/bind';
import styles from '../../Page.module.less';
import { restApi } from '#apis';
import { useReduxStore } from '#hooks/useReduxStore';
import produce from 'immer';
import RegexObj from '#utils/regex';
import RequiredOrNotLabel from '#components/RequiredOrNotLabel';
import AddressModal from '#components/AddressModal';
import FileUpload from '#components/datalist/FileUpload';
import { useNavigate } from 'react-router-dom';
import handleError from '#utils/handleError';
import { kakaoLocalApi } from '#apis';
import useSms from '#hooks/useSms';
import { restApiNotUsingToken } from '../../../apis';

const cx = classNames.bind(styles);
const { Countdown } = Statistic;

const REQUESTBODY_KEYS = {
  PHONE: 'phone',
  EMAIL: 'email',
  REPRESENTATIVE: 'representative',
  BUSINESS_NAME: 'businessName',
  BANKNAME: 'bankName',
  ACCOUNT_HOLDER: 'accountHolder',
  ACCOUNT_NUMBER: 'accountNumber',
  ORDER_DEALER_NUMBER: 'orderDealerNumber',
  BUSINESS_NUMBER: 'businessNumber',
  BIZ_ADDRESS: 'bizAddress',
  BIZ_ADDRESS_DETAIL: 'addrDetail',
  BIZ_ZIP_CODE: 'bizZipcode',
  DELIVERIES: 'deliveries',
  BIZ_IMG_ID: 'bizImg',
  UID: 'uuid',
  LOCX: 'locX',
  LOCY: 'locY',
};

function PartnersStoreAccountInfoModify() {
  const {
    map,
    tree: {
      PAAB: { items: bankItems },
      DV: { items: deliveriesTree },
    },
  } = useReduxStore('codes');
  const { verify, check } = useSms();
  const navigate = useNavigate();

  // 핸드폰 인증
  const [timer, setTimer] = React.useState(null);
  const [showPostCode, setShowPostCode] = React.useState(false);
  const [authNum, setAuthNum] = React.useState('');

  const [partner, setPartner] = React.useState(null);
  const [changing, setChanging] = React.useState(false);

  const [defaultShouldDuplicateKeys, setDefaultShouldDuplicateKeys] = React.useState({
    [REQUESTBODY_KEYS.ORDER_DEALER_NUMBER]: null,
    [REQUESTBODY_KEYS.BUSINESS_NUMBER]: null,
  });

  const [duplicateCheck, setDuplicateCheck] = React.useState({
    [REQUESTBODY_KEYS.ORDER_DEALER_NUMBER]: true,
    [REQUESTBODY_KEYS.BUSINESS_NUMBER]: true,
  });

  const VALIDATION_INFO_OBJ = React.useMemo(
    () => ({
      [REQUESTBODY_KEYS.PHONE]: { label: '전화번호', regex: RegexObj.tel },
      [REQUESTBODY_KEYS.EMAIL]: { label: '이메일', regex: RegexObj.email },
      [REQUESTBODY_KEYS.REPRESENTATIVE]: { label: '대표명', required: true },
      [REQUESTBODY_KEYS.BUSINESS_NAME]: { label: '상호명', required: true },
      [REQUESTBODY_KEYS.BANKNAME]: { label: '은행명', required: true },
      [REQUESTBODY_KEYS.ACCOUNT_HOLDER]: { label: '예금주', required: true },
      [REQUESTBODY_KEYS.ACCOUNT_NUMBER]: { label: '계좌번호', required: true },
      [REQUESTBODY_KEYS.ORDER_DEALER_NUMBER]: {
        label: '통신판매업자번호',
        required: true,
        regex: RegexObj.orderDealNumber,
        url: 'odNum',
      },
      [REQUESTBODY_KEYS.BUSINESS_NUMBER]: {
        label: '사업자번호',
        required: true,
        regex: RegexObj.businessNumber,
        url: 'bizNum',
      },
      [REQUESTBODY_KEYS.BIZ_ADDRESS]: { label: '사업자 주소', required: true },
      [REQUESTBODY_KEYS.BIZ_ADDRESS_DETAIL]: { label: '상세 주소', required: true },
      [REQUESTBODY_KEYS.BIZ_ZIP_CODE]: { label: '우편번호', required: true },
      [REQUESTBODY_KEYS.BIZ_IMG_ID]: { label: '사업자 등록증', required: true },
      [REQUESTBODY_KEYS.DELIVERIES]: { label: '택배사' },
      [REQUESTBODY_KEYS.UID]: { label: '핸드폰 인증' },
      [REQUESTBODY_KEYS.LOCX]: { label: 'x 좌표', required: true },
      [REQUESTBODY_KEYS.LOCY]: { label: 'y 좌표', required: true },
    }),
    [],
  );

  const handleCheckAleadyExist = async (keyword) => {
    try {
      if (!partner[keyword]) {
        Modal.warning({
          content: `${VALIDATION_INFO_OBJ[keyword].label} 입력해주세요`,
        });
        return;
      }

      await restApi.get(`/partners/accounts/check/${VALIDATION_INFO_OBJ[keyword].url}`, {
        params: {
          [keyword]: partner[keyword],
        },
      });

      setDuplicateCheck(
        produce((draft) => {
          draft[keyword] = true;
        }),
      );

      Modal.success({
        content: `사용할 수 있는 ${VALIDATION_INFO_OBJ[keyword].label} 입니다.`,
      });
    } catch (e) {
      if (e?.response?.status === 409) {
        Modal.warning({
          content: `이미 존재하는 ${VALIDATION_INFO_OBJ[keyword].label} 입니다.`,
        });
      } else if (e?.response?.status === 422) {
        Modal.warning({
          content: `알맞은 ${VALIDATION_INFO_OBJ[keyword].label} 을/를 입력하세요.`,
        });
      }
    }
  };

  const checkValidation = React.useCallback(
    (_partner) => {
      const checkList = Object.values(REQUESTBODY_KEYS);
      for (const key of checkList) {
        if (VALIDATION_INFO_OBJ[key].required && !_partner[key]) {
          Modal.warning({
            content: `${VALIDATION_INFO_OBJ[key].label} 입력해주세요.`,
          });
          return;
        }

        if (
          VALIDATION_INFO_OBJ[key].regex &&
          _partner[key] !== null &&
          !VALIDATION_INFO_OBJ[key].regex.test(_partner[key])
        ) {
          Modal.warning({
            content: `알맞은 ${VALIDATION_INFO_OBJ[key].label} 입력해주세요.`,
          });
          return;
        }
      }
      for (const key of Object.keys(duplicateCheck)) {
        if (!duplicateCheck[key]) {
          Modal.warning({
            content: `${VALIDATION_INFO_OBJ[key].label} 중복 확인을 완료해주세요.`,
          });
          return;
        }
      }

      if (partner[REQUESTBODY_KEYS.PHONE] !== defaultShouldDuplicateKeys[REQUESTBODY_KEYS.PHONE]) {
        if (!partner[REQUESTBODY_KEYS.UID]) {
          Modal.warning({
            centered: true,
            content: '핸드폰 인증을 완료해주세요.',
            okText: '확인',
          });
          return;
        }
      }
      return true;
    },
    [VALIDATION_INFO_OBJ, duplicateCheck, defaultShouldDuplicateKeys, partner],
  );

  const handleSave = async () => {
    if (changing) return;
    try {
      if (checkValidation(partner)) {
        setChanging(true);
        const partnerObjToSave = { ...partner };

        partnerObjToSave[REQUESTBODY_KEYS.PHONE] = '+82' + partner[[REQUESTBODY_KEYS.PHONE]].slice(1);

        await restApi.put('/partners/stores/seller', partnerObjToSave);
        // console.log('resposne :::: ', response);

        Modal.success({
          content: '수정을 완료했습니다.',
          onOk: () => {
            navigate('/store/account');
          },
        });

        setChanging(false);
      }
    } catch (e) {
      console.log(e);
      Modal.warning({
        centered: true,
        content: '수정하는데 실패했습니다. \n 관리자에게 문의해주세요.',
      });
      setChanging(false);
      handleError(e);
    }
  };

  const askCancel = React.useCallback(() => {
    Modal.confirm({
      centered: true,
      onOk: () => {
        navigate('/store/account');
      },
      content: '입력했던 내용이 모두 사라집니다. \n취소 하실 건가요? ',
      okText: '확인',
      cancelText: '취소',
    });
  }, [navigate]);

  const askAuthNumber = () => {
    if (!RegexObj.phone.test(partner[REQUESTBODY_KEYS.PHONE])) {
      alert('알맞은 핸드폰 번호를 입력해주세요');
      return;
    }

    const newPhoneNumber = '+82' + partner[REQUESTBODY_KEYS.PHONE].slice(1);

    restApiNotUsingToken
      .get('/partners/accounts', {
        params: {
          phone: newPhoneNumber,
        },
      })
      .then(async () => {
        await verify(partner[REQUESTBODY_KEYS.PHONE]);
        setAuthNum('');
        setPartner(
          produce((draft) => {
            draft[REQUESTBODY_KEYS.UID] = null;
          }),
        );
        setTimer(Date.now() + 180 * 1000);
      })
      .catch((e) => {
        if (e?.response?.status === 409) {
          Modal.warning({ content: '이미 가입된 아이디가 존재합니다.', centered: true, okText: '확인' });
          return;
        }

        if (e?.response?.status === 422) {
          Modal.warning({
            content: '알맞은 핸드폰 번호를 입력해주세요.',
            centered: true,
            okText: '확인',
          });
          return;
        }

        Modal.warning({
          content: '인증번호 전송에 실패했습니다. 잠시후 시도해주세요.',
          centered: true,
          okText: '확인',
        });
      });
  };

  const handleAuth = () => {
    check({ code: authNum })
      .then((uuid) => {
        setPartner(
          produce((draft) => {
            draft[REQUESTBODY_KEYS.UID] = uuid;
          }),
        );
        setTimer(null);
      })
      .catch((error) => {
        Modal.error({
          title: '인증 실패',
          content: error?.response?.data?.message || '인증에 실패했습니다.',
          centered: true,
          okText: '확인',
        });
      });
  };

  React.useEffect(() => {
    restApi
      .get(`/partners/stores/seller`)
      .then((response) => {
        const { bizImg, phone, ...rest } = response.data;
        const newPartnerObj = rest;

        newPartnerObj[REQUESTBODY_KEYS.PHONE] = '0' + phone.split('+82')[1];

        if (bizImg) {
          const parsedBizImg = +bizImg.split('/').reverse()[0];
          newPartnerObj[REQUESTBODY_KEYS.BIZ_IMG_ID] = parsedBizImg;
        }

        setPartner(newPartnerObj);

        // 초기 값을 가지고 있어야 되는 key
        setDefaultShouldDuplicateKeys({
          [REQUESTBODY_KEYS.BUSINESS_NUMBER]: newPartnerObj[REQUESTBODY_KEYS.BUSINESS_NUMBER],
          [REQUESTBODY_KEYS.ORDER_DEALER_NUMBER]: newPartnerObj[REQUESTBODY_KEYS.ORDER_DEALER_NUMBER],
          [REQUESTBODY_KEYS.PHONE]: newPartnerObj[REQUESTBODY_KEYS.PHONE],
        });
      })
      .catch((e) => console.warn(e));
  }, []);

  if (!partner) return <Spin />;

  return (
    <div>
      <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
        <Descriptions bordered>
          <Descriptions.Item span={3} labelStyle={{ width: 140 }} label="파트너사아이디">
            {partner.userId}
          </Descriptions.Item>
          <Descriptions.Item span={3} labelStyle={{ width: 140 }} label="파트너사이름">
            {partner.userName}
          </Descriptions.Item>
          <Descriptions.Item span={3} labelStyle={{ width: 140 }} label="휴대전화">
            <div style={{ display: 'flex' }}>
              <Input
                style={{ width: 300, marginRight: 10 }}
                disabled={partner[REQUESTBODY_KEYS.UID]}
                value={partner[REQUESTBODY_KEYS.PHONE]}
                placeholder="'-'를 제외한 핸드폰 번호를 입력하세요."
                onChange={(event) => {
                  setPartner(
                    produce((draft) => {
                      draft[REQUESTBODY_KEYS.PHONE] = event.target.value;
                      draft[REQUESTBODY_KEYS.UID] = null;
                    }),
                  );
                }}
              />
              <Button
                disabled={
                  partner[REQUESTBODY_KEYS.UID] ||
                  partner[REQUESTBODY_KEYS.PHONE] === defaultShouldDuplicateKeys[REQUESTBODY_KEYS.PHONE]
                }
                onClick={askAuthNumber}
              >
                {partner[REQUESTBODY_KEYS.UID] ? '휴대폰 인증 완료' : '인증 번호 전송'}
              </Button>
            </div>
            {!!timer && (
              <div style={{ display: 'flex', alignItems: 'center', marginTop: 10 }}>
                <Input
                  onChange={(e) => {
                    setAuthNum(e.target.value);
                  }}
                  value={authNum}
                  style={{ width: 200, marginRight: 10 }}
                />
                <Button disabled={authNum?.length === 0} onClick={handleAuth}>
                  확인
                </Button>
                <div style={{ width: 10 }} />
                <Countdown
                  valueStyle={{ fontSize: 10 }}
                  value={timer}
                  onFinish={() => {
                    setTimer(null);
                  }}
                />
              </div>
            )}
          </Descriptions.Item>
          <Descriptions.Item span={3} labelStyle={{ width: 140 }} label="이메일">
            <Input
              style={{ width: 400 }}
              value={partner[REQUESTBODY_KEYS.EMAIL]}
              onChange={(event) => {
                setPartner(
                  produce((draft) => {
                    draft[REQUESTBODY_KEYS.EMAIL] = event.target.value;
                  }),
                );
              }}
            />
          </Descriptions.Item>
        </Descriptions>

        <Descriptions bordered>
          <Descriptions.Item span={3} labelStyle={{ width: 140 }} label="택배사">
            <Select
              mode="tags"
              style={{ width: 540 }}
              options={deliveriesTree?.map((value) => ({ label: value.label, value: value.code }))}
              placeholder="택배사를 선택해주세요"
              onChange={(e) => {
                setPartner(
                  produce((draft) => {
                    draft[REQUESTBODY_KEYS.DELIVERIES] = e;
                  }),
                );
              }}
              value={partner[REQUESTBODY_KEYS.DELIVERIES] || []}
            />
          </Descriptions.Item>
        </Descriptions>

        <Descriptions bordered>
          <Descriptions.Item
            span={3}
            labelStyle={{ width: 140 }}
            label={<RequiredOrNotLabel label="대표자명" required />}
          >
            <Input
              value={partner[REQUESTBODY_KEYS.REPRESENTATIVE]}
              onChange={(event) => {
                setPartner(
                  produce((draft) => {
                    draft[REQUESTBODY_KEYS.REPRESENTATIVE] = event.target.value;
                  }),
                );
              }}
            />
          </Descriptions.Item>
          <Descriptions.Item
            span={3}
            labelStyle={{ width: 140 }}
            label={<RequiredOrNotLabel label="상호명" required />}
          >
            <Input
              value={partner[REQUESTBODY_KEYS.BUSINESS_NAME]}
              onChange={(event) => {
                setPartner(
                  produce((draft) => {
                    draft[REQUESTBODY_KEYS.BUSINESS_NAME] = event.target.value;
                  }),
                );
              }}
            />
          </Descriptions.Item>
          <Descriptions.Item
            span={3}
            labelStyle={{ width: 140 }}
            label={<RequiredOrNotLabel label="계좌번호" required />}
          >
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <label style={{ width: '60px' }}>은행</label>
                  <Select
                    value={partner[REQUESTBODY_KEYS.BANKNAME]}
                    style={{ width: 200, marginRight: 30 }}
                    placeholder="은행 선택"
                    options={bankItems?.map((value) => ({ label: value.label, value: value.code })) || []}
                    onChange={(bankValue) =>
                      setPartner(
                        produce((draft) => {
                          draft[REQUESTBODY_KEYS.BANKNAME] = bankValue;
                        }),
                      )
                    }
                  />
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <label style={{ width: '100px' }}>예금주</label>
                  <Input
                    value={partner[REQUESTBODY_KEYS.ACCOUNT_HOLDER]}
                    onChange={(event) => {
                      setPartner(
                        produce((draft) => {
                          draft[REQUESTBODY_KEYS.ACCOUNT_HOLDER] = event.target.value;
                        }),
                      );
                    }}
                  />
                </div>
              </div>
              <div style={{ display: 'flex', alignItems: 'center', marginTop: 10 }}>
                <label style={{ width: '65px' }}>계좌번호</label>
                <Input
                  value={partner[REQUESTBODY_KEYS.ACCOUNT_NUMBER]}
                  placeholder="정확한 계좌번호를 입력해주세요"
                  onChange={(event) => {
                    setPartner(
                      produce((draft) => {
                        draft[REQUESTBODY_KEYS.ACCOUNT_NUMBER] = event.target.value;
                      }),
                    );
                  }}
                />
              </div>
            </div>
          </Descriptions.Item>
          <Descriptions.Item
            span={3}
            labelStyle={{ width: 140 }}
            label={<RequiredOrNotLabel label="통신판매업자번호" required />}
          >
            <div style={{ display: 'flex' }}>
              <Input
                style={{ width: 400, marginRight: 10 }}
                value={partner[REQUESTBODY_KEYS.ORDER_DEALER_NUMBER]}
                onChange={(event) => {
                  if (duplicateCheck[REQUESTBODY_KEYS.ORDER_DEALER_NUMBER]) {
                    setDuplicateCheck(
                      produce((draft) => {
                        draft[REQUESTBODY_KEYS.ORDER_DEALER_NUMBER] = null;
                      }),
                    );
                  }

                  if (event.target.value === defaultShouldDuplicateKeys[REQUESTBODY_KEYS.ORDER_DEALER_NUMBER]) {
                    setDuplicateCheck(
                      produce((draft) => {
                        draft[REQUESTBODY_KEYS.ORDER_DEALER_NUMBER] = true;
                      }),
                    );
                  }

                  setPartner(
                    produce((draft) => {
                      draft[REQUESTBODY_KEYS.ORDER_DEALER_NUMBER] = event.target.value;
                    }),
                  );
                }}
              />
              <Button
                disabled={duplicateCheck[REQUESTBODY_KEYS.ORDER_DEALER_NUMBER]}
                onClick={() => {
                  handleCheckAleadyExist(REQUESTBODY_KEYS.ORDER_DEALER_NUMBER);
                }}
              >
                중복체크
              </Button>
            </div>
          </Descriptions.Item>
          <Descriptions.Item
            span={3}
            labelStyle={{ width: 140 }}
            label={<RequiredOrNotLabel label="사업자번호" required />}
          >
            <div style={{ display: 'flex' }}>
              <Input
                style={{ width: 400, marginRight: 10 }}
                value={partner[REQUESTBODY_KEYS.BUSINESS_NUMBER]}
                onChange={(event) => {
                  if (duplicateCheck[REQUESTBODY_KEYS.BUSINESS_NUMBER]) {
                    setDuplicateCheck(
                      produce((draft) => {
                        draft[REQUESTBODY_KEYS.BUSINESS_NUMBER] = null;
                      }),
                    );
                  }

                  if (event.target.value === defaultShouldDuplicateKeys[REQUESTBODY_KEYS.BUSINESS_NUMBER]) {
                    setDuplicateCheck(
                      produce((draft) => {
                        draft[REQUESTBODY_KEYS.BUSINESS_NUMBER] = true;
                      }),
                    );
                  }
                  setPartner(
                    produce((draft) => {
                      draft[REQUESTBODY_KEYS.BUSINESS_NUMBER] = event.target.value;
                    }),
                  );
                }}
              />
              <Button
                disabled={duplicateCheck[REQUESTBODY_KEYS.BUSINESS_NUMBER]}
                onClick={() => {
                  handleCheckAleadyExist(REQUESTBODY_KEYS.BUSINESS_NUMBER);
                }}
              >
                중복체크
              </Button>
            </div>
          </Descriptions.Item>
          <Descriptions.Item
            span={3}
            labelStyle={{ width: 140 }}
            label={<RequiredOrNotLabel label="사업자 주소" required />}
          >
            <div style={{ display: 'flex', marginBottom: 5 }}>
              <Input value={partner[REQUESTBODY_KEYS.BIZ_ZIP_CODE]} disabled style={{ width: 200, marginRight: 10 }} />
              <Button
                onClick={() => {
                  setPartner(
                    produce((draft) => {
                      draft[REQUESTBODY_KEYS.BIZ_ADDRESS] = null;
                      draft[REQUESTBODY_KEYS.BIZ_ZIP_CODE] = null;
                    }),
                  );

                  setShowPostCode(true);
                }}
              >
                주소 검색
              </Button>
            </div>
            <Input
              value={partner[REQUESTBODY_KEYS.BIZ_ADDRESS]}
              disabled
              style={{ marginRight: 10, marginBottom: 5 }}
              onChange={(event) => {
                setPartner(
                  produce((draft) => {
                    draft[REQUESTBODY_KEYS.BIZ_ADDRESS] = event.target.value;
                  }),
                );
              }}
            />
            <Input
              value={partner[REQUESTBODY_KEYS.BIZ_ADDRESS_DETAIL]}
              disabled={!partner[REQUESTBODY_KEYS.BIZ_ADDRESS]}
              style={{ marginRight: 10 }}
              onChange={(e) => {
                setPartner(
                  produce((draft) => {
                    draft[REQUESTBODY_KEYS.BIZ_ADDRESS_DETAIL] = e.target.value;
                  }),
                );
              }}
            />
          </Descriptions.Item>
          <Descriptions.Item
            span={3}
            labelStyle={{ width: 140 }}
            label={<RequiredOrNotLabel label="사업자 등록증" required />}
          >
            <Space direction="vertical">
              <FileUpload
                listType="picture-card"
                items={partner?.[REQUESTBODY_KEYS.BIZ_IMG_ID] || []}
                onDoneChange={(fileListDone) => {
                  setPartner(
                    produce((draft) => {
                      draft[REQUESTBODY_KEYS.BIZ_IMG_ID] = fileListDone.map(({ response }) => response[0].id)[0];
                    }),
                  );
                }}
                hideUpload={({ length }) => length > 0}
                maxCount={1}
              />
            </Space>
          </Descriptions.Item>
        </Descriptions>
      </Space>

      <div className={cx({ buttonBox: true })}>
        <Button onClick={askCancel}>취소</Button>
        <Button onClick={handleSave}>저장</Button>
      </div>

      <AddressModal
        onCancel={() => {
          showPostCode(false);
        }}
        showPostCode={showPostCode}
        onSelected={async (data) => {
          try {
            const {
              data: { documents },
            } = await kakaoLocalApi.get('/v2/local/search/address', {
              params: {
                query: data.address || data.roadAddress || data.jibunAddress,
              },
            });
            setPartner(
              produce((draft) => {
                draft[REQUESTBODY_KEYS.BIZ_ZIP_CODE] = data.zonecode;
                draft[REQUESTBODY_KEYS.BIZ_ADDRESS] = data.roadAddress || data.jibunAddress;
                draft['locX'] = documents[0]?.x;
                draft['locY'] = documents[0]?.y;
              }),
            );
          } catch (e) {
            Modal.error({
              title: '실패',
              content: '주소 변경에 실패했습니다. 잠시후 시도해주세요.',
              centered: true,
              okText: '확인',
            });
          } finally {
            setShowPostCode(false);
          }
        }}
      />
    </div>
  );
}

export default PartnersStoreAccountInfoModify;
