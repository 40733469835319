import { Button, Descriptions, Modal, Space, Typography } from 'antd';
import React from 'react';
import produce from 'immer';
import AddAdvertisementList from '../../components/advertise/AddAdvertisementList';
import { restApi } from '../../apis';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import InputMoney from '../../components/Input/InputMoney';

const Create = () => {
  const { principal } = useSelector((state) => state?.auth);
  const curIndex = React.useRef(1);
  const navigate = useNavigate();

  const { IMP } = window;

  IMP.init('imp99207937');

  const [listToAdd, setListToAdd] = React.useState([
    {
      extra1: null,
      extra2: null,
      startAt: null,
      endAt: null,
      period: 10,
      id: curIndex.current,
      isChecked: false,
      adPrice: null,
    },
  ]);
  const [firstArea, setFirstArea] = React.useState([]);
  const [balance, setBalance] = React.useState(0);
  const [usedBalance, setUsedBalance] = React.useState(0);
  const handleChangeData = (id) => (object) => {
    setListToAdd(
      produce((draft) => {
        const foundIndex = draft.findIndex((item) => item.id === id);
        if (foundIndex > -1) {
          draft[foundIndex] = { ...draft[foundIndex], ...object, isChecked: false, adPrice: null };
        }
      }),
    );
  };

  const removeListItem = (id) => () => {
    if (listToAdd.length === 1) {
      return;
    }
    setListToAdd(
      produce((draft) => {
        const foundIndex = draft.findIndex((item) => item.id === id);
        if (foundIndex > -1) {
          draft.splice(foundIndex, 1);
        }
      }),
    );
  };

  const handleIfAvailable = (id) => async () => {
    const item = listToAdd.find((value) => value.id === id);

    try {
      const { data } = await restApi.post('/partners/advertisement/ready', {
        extra1: item.extra1,
        extra2: item.extra2,
        period: item.period,
        startAt: item.startAt,
      });

      setListToAdd(
        produce((draft) => {
          const foundItem = draft.find((value) => value.id === id);
          foundItem.isChecked = true;
          foundItem.adPrice = data;
        }),
      );
    } catch (e) {
      Modal.error({
        title: '광고 불가능',
        content: e?.response?.data?.message || '광고가 불가능한 날짜입니다.',
        centered: true,
      });
    }
  };

  const handleAddList = () => {
    setListToAdd(
      produce((draft) => {
        draft.push({
          extra1: null,
          extra2: null,
          startAt: null,
          endAt: null,
          period: 10,
          id: ++curIndex.current,
          isChecked: false,
          adPrice: null,
        });
      }),
    );
  };

  const handleApply = async () => {
    if (listToAdd.some((item) => !item.isChecked))
      return Modal.warning({
        content: '광고 가능 여부를 확인해주세요',
        centered: true,
      });
    if (listToAdd.some((item) => !item.extra1 || !item.extra2))
      return Modal.warning({
        content: '지역을 선택해주세요',
        centered: true,
      });

    if (listToAdd.some((item) => !item.startAt))
      return Modal.warning({
        content: '시작일을 선택해주세요',
        centered: true,
      });

    const {
      data: { merchant_uid, settleAmount, payAmount, adPrice },
    } = await restApi.post('/partners/advertisement/ready/pay', {
      createReadyRequests: listToAdd.map((value) => ({
        extra1: value.extra1,
        extra2: value.extra2,
        period: value.period,
        startAt: value.startAt,
      })),
      useSettlement: usedBalance,
    });

    async function callback(response) {
      const { success, merchant_uid, error_msg, imp_uid } = response;
      if (success) {
        try {
          const { data } = await restApi.post('/partners/advertisement/check', {
            impUid: imp_uid,
            merchantUid: merchant_uid,
          });
        } catch (e) {
          // Modal.error({ title: '광고 등록 실패', content: '광고 등록에 실패했습니다.', centered: true });
        } finally {
          Modal.success({
            content: '결제가 완료되었습니다.',
            onOk: () => {
              navigate('/advertise', {
                replace: true,
              });
            },
            centered: true,
          });
        }
      } else {
        Modal.error({ title: '결제 실패', content: error_msg, centered: true });
      }
    }

    const data = {
      pg: 'html5_inicis', // PG사
      pay_method: 'card', // 결제수단
      merchant_uid, // 주문번호
      amount: payAmount, // 결제금액
      name: '홈그릿 광고 신청', // 주문명
      buyer_name: principal?.userName, // 구매자 이름
      buyer_tel: principal?.phone || '0000000000', // 구매자 전화번호
      // buyer_email: '', // 구매자 이메일
      // buyer_addr: '', // 구매자 주소
      // buyer_postcode: '', // 구매자 우편번호
    };

    IMP.request_pay(data, callback);
  };

  React.useEffect(() => {
    restApi.get('/area-codes/filter').then(({ data }) => {
      setFirstArea(data);
    });
    restApi.get('/partners/advertisement/my/settle').then(({ data }) => {
      setBalance(data);
    });
  }, []);

  const totalPrices = listToAdd.reduce((p, c) => {
    if (c.isChecked && typeof c.adPrice === 'number') {
      return p + c.adPrice;
    }
    return p;
  }, 0);

  return (
    <Space direction="vertical" style={{ width: '100%' }} size="middle">
      <Descriptions colon={false} bordered>
        <Descriptions.Item labelStyle={{ width: 180, minWidth: 180 }} label={'광고등록'} span={3}>
          <Space direction="vertical" style={{ width: '100%' }}>
            <Space direction="row" style={{ width: '100%', justifyContent: 'flex-end', alignItems: 'center' }}>
              <Button style={{ width: 100 }} type="primary" onClick={handleAddList}>
                추가
              </Button>
            </Space>
            <AddAdvertisementList
              onRemove={removeListItem}
              onChangeList={handleChangeData}
              list={listToAdd}
              firstArea={firstArea}
              onCheck={handleIfAvailable}
            />
          </Space>
        </Descriptions.Item>
        <Descriptions.Item labelStyle={{ width: 180, minWidth: 180 }} label="비용" span={3}>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <>
              <Typography style={{ marginLeft: 16 }}>예치금 사용</Typography>
              <Space style={{ marginTop: 20, marginLeft: 16 }}>
                <InputMoney
                  disabled={!balance}
                  max={balance}
                  onChange={setUsedBalance}
                  value={usedBalance}
                  style={{ marginRight: 20 }}
                />
                <div style={{ paddingLeft: 20 }}>원 (현재 예치금 : {(balance ?? 0).toLocaleString()} 원)</div>
              </Space>
            </>

            <Space style={{ width: '100%', marginBlock: 30 }} size="middle">
              {' '}
              <Descriptions bordered>
                <Descriptions.Item label="광고비용">{(totalPrices ?? 0).toLocaleString()}원</Descriptions.Item>
                <Descriptions.Item label="예치금 차감">{(usedBalance ?? 0).toLocaleString()}원</Descriptions.Item>
                <Descriptions.Item label="결제 금액" style={{ color: 'red' }} labelStyle={{ color: 'black' }}>
                  {(totalPrices - (usedBalance ?? 0) ?? 0).toLocaleString()}원
                </Descriptions.Item>
              </Descriptions>
            </Space>
          </div>
        </Descriptions.Item>
      </Descriptions>
      <Space style={{ width: '100%', alignItems: 'center', justifyContent: 'flex-end' }}>
        <Button
          onClick={() => {
            navigate('/advertise', {
              replace: true,
            });
          }}
        >
          취소
        </Button>
        <Button onClick={handleApply} type="primary">
          결제 및 광고신청
        </Button>
      </Space>
    </Space>
  );
};

export default Create;
