import React from 'react';
import { Button, Checkbox, Descriptions, Radio, Row, Space, Spin, Typography } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import handleError from '#utils/handleError';
import { restApi, getFileHost } from '#apis';

function PartnerNoticeDetail() {
  const navigate = useNavigate();
  const { noticeId } = useParams();
  const [noticeInfo, setNoticeInfo] = React.useState(null);

  React.useEffect(() => {
    if (!noticeId) return;

    restApi
      .get(`/partners/notices/${noticeId}`)
      .then(({ data }) => {
        setNoticeInfo(data);
      })
      .catch((e) => handleError(e));
  }, [noticeId]);

  if (!noticeInfo) {
    return <Spin />;
  }

  return (
    <div>
      <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
        <Descriptions bordered>
          <Descriptions.Item span={3} labelStyle={{ width: 140 }} label="제목">
            <Typography.Text>{noticeInfo.title}</Typography.Text>
          </Descriptions.Item>
          <Descriptions.Item span={3} labelStyle={{ width: 140 }} label="내용">
            <div dangerouslySetInnerHTML={{ __html: noticeInfo.contents }} />
          </Descriptions.Item>
          <Descriptions.Item span={3} labelStyle={{ width: 140 }} label="첨부파일">
            {noticeInfo.images.map((imageInfo) => (
              <a rel="noreferrer" target="_blank" href={`${getFileHost()}/${imageInfo.id}`} download>
                {imageInfo.fileName}
              </a>
            ))}
          </Descriptions.Item>
        </Descriptions>
        <Row justify="end">
          <Space>
            <Button
              onClick={() => {
                navigate(-1);
              }}
            >
              목록
            </Button>
          </Space>
        </Row>
      </Space>
    </div>
  );
}

export default PartnerNoticeDetail;
