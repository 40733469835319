import { restApi } from '#apis/index';
import formatComma from '#utils/formatComma';
import handleError from '#utils/handleError';
import Postcode from '@actbase/react-daum-postcode';
import produce from 'immer';
import moment from 'moment';
import { useReduxStore } from '#hooks/useReduxStore';
import React from 'react';
import {
  Button,
  Modal,
  Input,
  Descriptions,
  Space,
  Select,
  Radio,
  Row,
  Col,
  DatePicker,
  Form,
  Typography,
  Divider,
  Upload,
} from 'antd';
import CardWrapper from '#components/common/CardWrapper';
import SearchForm from '#components/SearchForm';
import CommTable from '#components/table/CommTable';
import ShowOrderDetailBtn from '#components/Buttons/ShowOrderDetailBtn';
import useCheckOrderStatusCodeRowKeys from '#hooks/useCheckOrderStatusCodeRowKeys';
import * as XLSX from 'xlsx';

const DATE_TYPE = {
  PlACING_ORDER_AT: 'PlacingOrderAt',
  PAY_AT: 'PayAt',
  SEND_AT: 'SendAt',
};

const SEARCH_TYPE = {
  BUYER: 'BuyerName',
  BUYER_ID: 'BuyeUserrId',
  BUYER_PHONE: 'BuyerPhone',
  INVOICE_NUMBER: 'InvoiceNumber',
  ORDER_NUMBER: 'OrderId',
  ORDER_PRODUCT_NUMBER: 'OrderProductId',
  PRODUCT_NUMBER: 'ProductId',
  RECIPIENT: 'Recipient',
};

const DeliverySendExcelForm = ({ codesTree, onRefresh }) => {
  const [filename, setFilename] = React.useState('');
  const [file, setFile] = React.useState(null);
  const DELIVERY_SEND_COURIER_MAP = React.useMemo(
    () => codesTree?.DV?.items?.reduce((p, { label, code }) => ({ ...p, [label]: code }), {}),
    [codesTree?.DV],
  );
  const DELIVERY_SEND_METHOD_MAP = React.useMemo(
    () => ({
      '택배,등기,소포': 'DM001',
      방문수령: 'DM003',
      직접전달: 'DM004',
    }),
    [],
  );

  return (
    <Space style={{ width: '100%' }} direction="vertical">
      <Typography.Text>엑셀 파일을 업로드 해주세요.</Typography.Text>
      <Row gutter={15}>
        <Col flex={1}>
          <Input value={filename} style={{ width: '100%' }} readOnly />
        </Col>
        <Col>
          <Upload
            fileList={[]}
            beforeUpload={() => false}
            onChange={({ file }) => {
              setFilename(file.name);
              const fileReader = new FileReader();
              fileReader.readAsArrayBuffer(file);
              fileReader.onload = (event) => {
                setFile(event.target.result);
              };
            }}
          >
            <Button type="primary">찾아보기</Button>
          </Upload>
        </Col>
      </Row>
      <Typography.Text>※ 정해진 양식으로 작성 후 업로드 해주세요.</Typography.Text>
      <Typography.Link href="/assets/excels/DeliverySendTemplate.xls" download>
        업로드 양식 다운
      </Typography.Link>
      <Row style={{ marginTop: 15, marginBottom: -25 }} justify="end">
        <Col>
          <Space>
            <Button
              type="primary"
              onClick={() => {
                const xlsx = XLSX.read(file, { type: 'array', cellDates: true });
                const [sheet] = Object.values(xlsx.Sheets);
                const rows = Object.entries(sheet)
                  .filter(([key]) => !key.startsWith('!'))
                  .reduce((p, [key, { v }]) => {
                    const [column, index] = key
                      .split('')
                      .reduce((p, c, i) => (i > 0 ? [p[0], (p[1] ?? '') + c] : [...p, c]));
                    if (index === '1') {
                      return p;
                    }
                    const columnIndex = ['A', 'B', 'C', 'D'].indexOf(column);
                    if (!p[index - 2]) {
                      p[index - 2] = [];
                    }
                    p[index - 2][columnIndex] = v;
                    return p;
                  }, []);

                Modal.destroyAll();

                return Promise.all(
                  rows.map(([orderProductId, method, courier, invoiceNumber]) => {
                    const courierCode = DELIVERY_SEND_COURIER_MAP[courier];
                    const methodCode = DELIVERY_SEND_METHOD_MAP[method];

                    return restApi.put(`/partners/account-orders/${orderProductId}/send`, {
                      courierCode,
                      invoiceNumber,
                      methodCode,
                    });
                  }),
                )
                  .catch((error) => {
                    Modal.error({
                      title: '발송처리',
                      content: error?.response?.data?.message ?? '발송처리에 실패했습니다.',
                      centered: true,
                    });
                  })
                  .finally(onRefresh);
              }}
            >
              발송처리
            </Button>
            <Button onClick={Modal.destroyAll}>닫기</Button>
          </Space>
        </Col>
      </Row>
    </Space>
  );
};

const DeliverySendForm = ({ selectedRowKeys, onRefresh, codesTree }) => {
  const initialForm = React.useMemo(
    () => ({
      methodCode: 'DM001',
      courierCode: null,
      invoiceNumber: null,
    }),
    [],
  );
  const [form, setForm] = React.useState(initialForm);

  return (
    <Form
      onFinish={() => {
        Modal.confirm({
          title: '발송처리',
          content: '발송처리 후에는 구매고객에게는 배송중으로 노출됩니다.\n선택한 내역을 발송 처리를 하시겠습니까 ?',
          centered: true,
          async onOk() {
            if (form.methodCode === 'DM001') {
              if (!form.courierCode) {
                return Modal.warn({
                  title: '발송처리',
                  content: '택배사를 선택해주세요.',
                  centered: true,
                });
              }
              if (!form.invoiceNumber) {
                return Modal.warn({
                  title: '발송처리',
                  content: '송장번호를 입력해주세요.',
                  centered: true,
                });
              }
            }

            try {
              // 발송처리
              await Promise.all(
                selectedRowKeys.map((orderProductId) =>
                  restApi.put(`/partners/account-orders/${orderProductId}/send`, {
                    methodCode: form.methodCode,
                    courierCode: form.courierCode,
                    invoiceNumber: form.invoiceNumber,
                  }),
                ),
              );
              Modal.destroyAll();
              onRefresh();
              Modal.success({
                title: '발송처리',
                content: '발송처리가 되었습니다.',
                centered: true,
              });
            } catch (error) {
              // 발주확인 실패
              handleError(error);
              Modal.error({
                title: '발송처리',
                content: error?.response?.data?.meesage ?? '발송처리에 실패했습니다.',
                centered: true,
              });
            }
          },
        });
      }}
    >
      <Space direction="vertical" size="middle" style={{ width: '100%' }}>
        <Form.Item label="배송방법" initialValue="DM001">
          <Select
            value={form.methodCode}
            onChange={(value) => {
              setForm(
                produce((draft) => {
                  draft.methodCode = value;
                  draft.invoiceNumber = null;
                  draft.courierCode = null;
                }),
              );
            }}
            options={codesTree?.DM?.items?.map(({ code, label }) => ({ label, value: code })) ?? []}
          />
        </Form.Item>
        <Form.Item label="택배사">
          <Select
            value={form.courierCode}
            onChange={(value) => {
              setForm(
                produce((draft) => {
                  draft.courierCode = value;
                }),
              );
            }}
            options={codesTree?.DV?.items?.map(({ code, label }) => ({ label, value: code })) ?? []}
            placeholder="택배사명 선택"
            disabled={form.methodCode !== 'DM001'}
          />
        </Form.Item>
        <Form.Item label="송장정보 입력">
          <Input
            value={form.invoiceNumber}
            onChange={(event) => {
              setForm(
                produce((draft) => {
                  draft.invoiceNumber = event.target.value;
                }),
              );
            }}
            placeholder="송장정보 입력"
            disabled={form.methodCode !== 'DM001'}
          />
        </Form.Item>
        <Row justify="end" style={{ marginBottom: -25 }} gutter={10}>
          <Col>
            <Button type="primary" htmlType="submit">
              적용
            </Button>
          </Col>
          <Col>
            <Button
              onClick={() => {
                Modal.destroyAll();
              }}
            >
              취소
            </Button>
          </Col>
        </Row>
      </Space>
    </Form>
  );
};

const ModifyInvoice = ({ selectedRowKeys, onRefresh, codesTree }) => {
  const initialForm = React.useMemo(
    () => ({
      courierCode: null,
      invoiceNumber: null,
    }),
    [],
  );
  const [form, setForm] = React.useState(initialForm);

  return (
    <Form
      onFinish={() => {
        Modal.confirm({
          title: '송장수정',
          content: '송장 내용을 변경하시겠습니까?',
          centered: true,
          async onOk() {
            if (!form.courierCode) {
              return Modal.warn({
                title: '송장수정',
                content: '택배사를 선택해주세요.',
                centered: true,
              });
            }
            if (!form.invoiceNumber) {
              return Modal.warn({
                title: '송장수정',
                content: '송장번호를 입력해주세요.',
                centered: true,
              });
            }

            try {
              // 발송처리
              await Promise.all(
                selectedRowKeys.map((orderProductId) =>
                  restApi.put(`/partners/account-orders/${orderProductId}/invoice`, {
                    courierCode: form.courierCode,
                    invoiceNumber: form.invoiceNumber,
                  }),
                ),
              );
              Modal.destroyAll();
              onRefresh();
              Modal.success({
                title: '송장 수정',
                content: '송장 수정이 완료되었습니다.',
                centered: true,
              });
            } catch (error) {
              handleError(error);
              Modal.error({
                title: '송장 수정',
                content: error?.response?.data?.message ?? '송장 수정에 실패했습니다. 잠시후 시도해주세요',
                centered: true,
              });
            }
          },
        });
      }}
    >
      <Space direction="vertical" size="middle" style={{ width: '100%' }}>
        <Form.Item label="택배사">
          <Select
            value={form.courierCode}
            onChange={(value) => {
              setForm(
                produce((draft) => {
                  draft.courierCode = value;
                }),
              );
            }}
            options={codesTree?.DV?.items?.map(({ code, label }) => ({ label, value: code })) ?? []}
            placeholder="택배사명 선택"
          />
        </Form.Item>
        <Form.Item label="송장정보 입력">
          <Input
            value={form.invoiceNumber}
            onChange={(event) => {
              setForm(
                produce((draft) => {
                  draft.invoiceNumber = event.target.value;
                }),
              );
            }}
            placeholder="송장정보 입력"
          />
        </Form.Item>
        <Row justify="end" style={{ marginBottom: -25 }} gutter={10}>
          <Col>
            <Button type="primary" htmlType="submit">
              적용
            </Button>
          </Col>
          <Col>
            <Button
              onClick={() => {
                Modal.destroyAll();
              }}
            >
              취소
            </Button>
          </Col>
        </Row>
      </Space>
    </Form>
  );
};

const ChangeDelivery = ({ selectedRowKeys = [], onSubmit = () => {} }) => {
  const formRef = React.useRef();
  return (
    <Form
      ref={formRef}
      onFinish={async ({ deliveryAddress, deliveryAddressDetail, phone, recipient, subPhone, zipCode }) => {
        if (!recipient) {
          return Modal.warn({
            title: '배송지 정보 수정',
            content: '수취인명을 입력하세요.',
            centered: true,
          });
        }
        if (!phone) {
          return Modal.warn({
            title: '배송지 정보 수정',
            content: '휴대폰 번호를 입력하세요.',
            centered: true,
          });
        }
        if (!subPhone) {
          return Modal.warn({
            title: '배송지 정보 수정',
            content: '전화번호를 입력하세요.',
            centered: true,
          });
        }
        if (!deliveryAddress) {
          return Modal.warn({
            title: '배송지 정보 수정',
            content: '배송지 주소를 입력하세요.',
            centered: true,
          });
        }
        try {
          // 배송지 정보 수정
          await Promise.all(
            selectedRowKeys.map((orderProductId) =>
              restApi.put(`/partners/account-orders/${orderProductId}/address`, {
                deliveryAddress,
                deliveryAddressDetail,
                phone,
                recipient,
                subPhone,
                zipCode,
              }),
            ),
          );
          Modal.destroyAll();
          onSubmit();
          Modal.success({
            title: '배송지 정보 수정',
            content: '배송지 정보가 변경되었습니다.',
            centered: true,
          });
        } catch (error) {
          // 배송지 정보 수정 실패
          handleError(error);
          Modal.error({
            title: '배송지 정보 수정',
            content: '배송지 정보 수정에 실패했습니다.',
            centered: true,
          });
        }
      }}
    >
      <Space direction="vertical" size="middle" style={{ width: '100%' }}>
        <Space direction="vertical" style={{ width: '100%' }}>
          <Form.Item label="수취인명" name="recipient">
            <Input />
          </Form.Item>
          <Form.Item label="휴대폰 번호" name="phone">
            <Input />
          </Form.Item>
          <Form.Item label="전화번호" name="subPhone">
            <Input />
          </Form.Item>
          <Space>
            <Form.Item label="배송지 주소" name="zipCode">
              <Input style={{ width: 120 }} readOnly />
            </Form.Item>
            <Button
              type="primary"
              onClick={() => {
                const addressModal = Modal.info({
                  title: '주소변경',
                  content: (
                    <Postcode
                      style={{ marginLeft: -30 }}
                      onSelected={({ zonecode, address }) => {
                        formRef.current.setFieldsValue({
                          zipCode: zonecode,
                          deliveryAddress: address,
                        });
                        addressModal.destroy();
                      }}
                    />
                  ),
                  width: 600,
                  closable: true,
                  centered: true,
                  okButtonProps: { style: { display: 'none' } },
                });
              }}
            >
              우편번호 찾기
            </Button>
          </Space>
          <Form.Item name="deliveryAddress" label={'\t'}>
            <Input />
          </Form.Item>
          <Form.Item name="deliveryAddressDetail" label={'\t'}>
            <Input />
          </Form.Item>
        </Space>
        <Row justify="end" style={{ marginBottom: -25 }} gutter={10}>
          <Col>
            <Button type="primary" htmlType="submit">
              변경
            </Button>
          </Col>
          <Col>
            <Button
              onClick={() => {
                Modal.destroyAll();
              }}
            >
              취소
            </Button>
          </Col>
        </Row>
      </Space>
    </Form>
  );
};

function SalesDelivery() {
  const { map: codesMap, tree: codesTree } = useReduxStore('codes');

  const ORDER_STATUS_TYPE = React.useMemo(
    () =>
      codesTree?.OPS.items.reduce((p, v) => {
        p[v.code] = v.code;
        return p;
      }, {}),
    [codesTree],
  );

  const initialParams = React.useMemo(
    () => ({
      dateType: null,
      startAt: null,
      endAt: null,
      orderProductStateCode: null,
      deliveryMethodCode: null,
      searchType: null,
    }),
    [],
  );
  const [params, setParams] = React.useState(initialParams);
  const tableRef = React.useRef();
  const [topInfomation, setTopInfomation] = React.useState(null);
  const [selectedRowKeys, setSelectedRowKeys] = React.useState([]);

  const { checkOrderStatusCodeRowKeys } = useCheckOrderStatusCodeRowKeys(tableRef.current?.dataSource, selectedRowKeys);

  const handleChangePagination = React.useCallback(() => {
    setSelectedRowKeys([]);
  }, []);

  const handleRefresh = React.useCallback(() => {
    setSelectedRowKeys([]);
    getPageTopInfomation();
    tableRef.current?.loadData();
  }, []);

  const getPageTopInfomation = React.useCallback(() => {
    Promise.all([
      restApi.get('/partners/account-orders/placing-order/cancel/count'),
      restApi.get('/partners/account-orders/placing-order/chage-address/count'),
      restApi.get('/partners/account-orders/placing-order/confirm/count'),
      restApi.get('/partners/account-orders/placing-order/new-order/count'),
    ])
      .then((result) => {
        setTopInfomation({
          cancel: result[0].data,
          changeAddr: result[1].data,
          confirm: result[2].data,
          newOrder: result[3].data,
        });
      })
      .catch((e) => {
        handleError(e);
      });
  }, []);

  React.useEffect(() => {
    if (!getPageTopInfomation) return;
    getPageTopInfomation();
  }, [getPageTopInfomation]);

  return (
    <Space direction="vertical" style={{ width: '100%' }}>
      {topInfomation && (
        <div style={{ flexDirection: 'row', display: 'flex', flexWrap: 'wrap', marginLeft: -10, marginRight: -10 }}>
          <div style={{ margin: 10 }}>
            <label>취소/변경</label>
            <div style={{ flexDirection: 'row', display: 'flex', marginLeft: -5 }}>
              <CardWrapper title="발송전 취소요청" value={formatComma(topInfomation.cancel)} />
              <CardWrapper title="발송전 배송지변경" value={formatComma(topInfomation.changeAddr)} />
            </div>
          </div>
          <div style={{ margin: 10 }}>
            <label>발송처리</label>
            <div style={{ flexDirection: 'row', display: 'flex', marginLeft: -5 }}>
              <CardWrapper title="신규주문" value={formatComma(topInfomation.newOrder)} />
              <CardWrapper title="발주확인" value={formatComma(topInfomation.confirm)} />
            </div>
          </div>
        </div>
      )}
      <Divider />
      <SearchForm
        params={params}
        onInit={setParams}
        onReset={() => {
          setParams(initialParams);
          return initialParams;
        }}
      >
        <Row gutter={10} style={{ marginBottom: 10, alignItems: 'center' }}>
          <Col>
            <Select
              value={params.dateType}
              placeholder="날짜 옵션"
              onChange={(value) => {
                setParams(
                  produce((draft) => {
                    draft.dateType = value;
                  }),
                );
              }}
              options={[
                { label: '결제일', value: DATE_TYPE.PAY_AT },
                { label: '발주확인일', value: DATE_TYPE.PlACING_ORDER_AT },
                { label: '발송처리일', value: DATE_TYPE.SEND_AT },
              ]}
              style={{ width: 150 }}
            />
          </Col>
          <Col>
            <DatePicker.RangePicker
              disabled={!params.dateType}
              value={[
                params.startAt ? moment(params.startAt) : undefined,
                params.endAt ? moment(params.endAt) : undefined,
              ]}
              onChange={([startAt, endAt]) => {
                setParams(
                  produce((draft) => {
                    draft.startAt = startAt.format('YYYY-MM-DD');
                    draft.endAt = endAt.format('YYYY-MM-DD');
                  }),
                );
              }}
            />
          </Col>
          <Col>
            <Radio.Group
              disabled={!params.dateType}
              value={moment(params.endAt).diff(params.startAt, 'day')}
              onChange={(event) => {
                setParams(
                  produce((draft) => {
                    draft.startAt = moment().add(-event.target.value, 'day').format('YYYY-MM-DD');
                    draft.endAt = moment().format('YYYY-MM-DD');
                  }),
                );
              }}
              options={[
                { label: '오늘', value: 0 },
                { label: '1주일', value: 7 },
                { label: '1개월', value: 30 },
                { label: '3개월', value: 90 },
              ]}
              buttonStyle="solid"
              optionType="button"
            />
          </Col>
        </Row>
        <Row gutter={10} style={{ alignItems: 'center' }}>
          <Col>
            <Select
              value={params.deliveryMethodCode}
              onChange={(value) => {
                setParams(
                  produce((draft) => {
                    draft.deliveryMethodCode = value;
                  }),
                );
              }}
              options={codesTree?.DM.items.map((value) => ({ label: value.label, value: value.code })) || []}
              placeholder="배송방법"
              allowClear
              style={{ width: 150 }}
            />
          </Col>
          <Col>
            <Select
              value={params.orderProductStateCode}
              onChange={(value) => {
                setParams(
                  produce((draft) => {
                    draft.orderProductStateCode = value;
                  }),
                );
              }}
              options={[
                { label: '결제완료(발송대기)', value: 'OPS002' },
                { label: '배송 중', value: 'OPS005' },
                { label: '배송완료', value: 'OPS006' },
              ]}
              placeholder="주문상태"
              allowClear
              style={{ width: 150 }}
            />
          </Col>
          <Col>
            <Select
              value={params.searchType}
              onChange={(value) => {
                setParams(
                  produce((draft) => {
                    draft.searchType = value;
                  }),
                );
              }}
              placeholder="검색 옵션"
              options={[
                { label: '수취인명', value: SEARCH_TYPE.RECIPIENT },
                { label: '구매자명', value: SEARCH_TYPE.BUYER },
                { label: '구매자 연락처', value: SEARCH_TYPE.BUYER_PHONE },
                { label: '구매자 ID', value: SEARCH_TYPE.BUYER_ID },
                { label: '주문번호', value: SEARCH_TYPE.ORDER_PRODUCT_NUMBER },
                { label: '상품번호', value: SEARCH_TYPE.PRODUCT_NUMBER },
                { label: '송장번호', value: SEARCH_TYPE.INVOICE_NUMBER },
              ]}
              style={{ width: 150 }}
            />
          </Col>
          <Col>
            <Input
              value={params.keyword}
              onChange={(event) => {
                setParams(
                  produce((draft) => {
                    draft.keyword = event.target.value;
                  }),
                );
              }}
              placeholder="키워드를 입력하세요"
              style={{ width: 250 }}
            />
          </Col>
          <Col>
            <Space>
              <Button type="primary" htmlType="submit">
                검색
              </Button>
              <Button htmlType="reset">초기화</Button>
            </Space>
          </Col>
        </Row>
        <CommTable
          ref={tableRef}
          url="/partners/account-orders/placing-order"
          rowSelection={{
            selectedRowKeys,
            onChange: setSelectedRowKeys,
          }}
          onChangePagination={handleChangePagination}
          rowKey={({ orderProductId }) => orderProductId}
          columns={[
            {
              title: '파트너사',
              dataIndex: 'businessName',
              fixed: true,
              width: 120,
              render: (value) => value,
            },
            {
              title: '주문번호',
              dataIndex: 'orderProductId',
              fixed: true,
              width: 150,
              render: (value) => <ShowOrderDetailBtn orderId={value} />,
            },
            {
              title: '주문상태',
              dataIndex: 'orderProductStateCode',
              fixed: true,
              width: 100,
              render: (value) => codesMap[value],
            },
            {
              title: '배송방법',
              dataIndex: 'deliveryMethod',
              width: 100,
              render: (value) => codesMap[value],
            },
            {
              title: '택배사',
              dataIndex: 'courierCode',
              width: 100,
              render: (value) => codesMap[value],
            },
            {
              title: '송장번호',
              dataIndex: 'invoiceNumber',
              width: 100,
              render: (value) => value,
            },
            {
              title: '배송추적',
              dataIndex: 'trace',
              width: 100,
              render: (trace) => trace,
            },
            {
              title: '발송일',
              dataIndex: 'sendAt',
              width: 200,
              render: (value) => value,
            },
            {
              title: '구매자명',
              dataIndex: 'buyerName',
              width: 100,
              render: (value) => value,
            },
            {
              title: '구매자아이디',
              dataIndex: 'buyerUserId',
              width: 100,
              render: (value) => value,
            },
            {
              title: '수취인명',
              dataIndex: 'recipient',
              width: 100,
              render: (value) => value,
            },
            {
              title: '결제일',
              dataIndex: 'payAt',
              width: 200,
              render: (value) => value,
            },
            {
              title: '상품번호',
              dataIndex: 'productId',
              width: 100,
              render: (value) => value,
            },
            {
              title: '상품명',
              dataIndex: 'productName',
              width: 100,
              render: (value) => value,
            },
            {
              title: '옵션정보',
              dataIndex: 'options',
              width: 100,
              render: (value) => value,
            },
            {
              title: '수량',
              dataIndex: 'count',
              width: 100,
              render: (value) => (value ? formatComma(value) : null),
            },
            {
              title: '옵션가격',
              dataIndex: 'optionAmount',
              width: 100,
              render: (value) => (value ? formatComma(value) : null),
            },
            {
              title: '상품가격',
              dataIndex: 'price',
              width: 100,
              render: (value) => (value ? formatComma(value) : null),
            },
            {
              title: '상품별 할인액',
              dataIndex: 'discountAmount',
              width: 100,
              render: (value) => value,
            },
            {
              title: '상품별 총 주문금액',
              dataIndex: 'totalAmount',
              width: 100,
              render: (value) => (value ? formatComma(value) : null),
            },
            {
              title: '발주확인일',
              dataIndex: 'placingOrderAt',
              width: 200,
              render: (value) => value,
            },
            {
              title: '발송기한',
              dataIndex: 'sendLimitAt',
              width: 100,
              render: (value) => value,
            },
            {
              title: '발송처리일',
              dataIndex: 'sendAt',
              width: 200,
              render: (value) => value,
            },
            {
              title: '배송비형태',
              dataIndex: 'deliveryPaymentTypeCode',
              width: 100,
              render: (value) => codesMap[value],
            },
            {
              title: '배송비유형',
              dataIndex: 'deliveryFreeTypeCode',
              width: 100,
              render: (value) => codesMap[value],
            },
            {
              title: '배송비 합계',
              dataIndex: 'deliveryTotalAmount',
              width: 100,
              render: (value) => value,
            },
            {
              title: '제주/도서 추가배송비',
              dataIndex: 'extraDeliveryAmount',
              width: 100,
              render: (value) => value,
            },
            {
              title: '배송비 할인액',
              dataIndex: 'deliveryDiscountAmount',
              width: 100,
              render: (value) => value,
            },
            {
              title: '배송지',
              dataIndex: 'deliveryAddress',
              width: 200,
              render: (value, obj) => value + obj.deliveryAddressDetail,
            },
            {
              title: '구매자연락처',
              dataIndex: 'buyerPhone',
              width: 100,
              render: (value) => value,
            },
            {
              title: '우편번호',
              dataIndex: 'zipCode',
              width: 100,
              render: (value) => value,
            },
            {
              title: '배송메시지',
              dataIndex: 'deliveryMessage',
              width: 200,
              render: (value) => value,
            },
            {
              title: '수취인연락처1',
              dataIndex: 'phone',
              width: 100,
              render: (value) => value,
            },
            {
              title: '수취인연락처2',
              dataIndex: 'subPhone',
              width: 100,
              render: (value) => value,
            },
            {
              title: '결제수단',
              dataIndex: 'paymentMethod',
              width: 100,
              render: (value) => value,
            },
            {
              title: '정산예정금',
              dataIndex: 'settlementAmount',
              width: 100,
              render: (value) => value,
            },
            {
              title: '주문일시',
              dataIndex: 'orderAt',
              width: 200,
              render: (value) => value,
            },
          ]}
          scroll={{ x: 4000 }}
        />
      </SearchForm>
      <Descriptions colon={false} bordered column={1}>
        <Descriptions.Item label="주문확인" labelStyle={{ width: 140, alignItems: 'center' }}>
          <Space>
            <Button
              disabled={!checkOrderStatusCodeRowKeys(ORDER_STATUS_TYPE.OPS002)}
              onClick={() => {
                Modal.confirm({
                  title: '발주확인',
                  content:
                    '발주 확인 후에는 구매고객에게는 상품준비중으로 노출됩니다. 선택한 내역을 발주확인 처리를 하시겠습니까 ?',
                  centered: true,
                  async onOk() {
                    // 선택된 값이 1개 이상일 경우
                    if (selectedRowKeys.length > 0) {
                      // 결제완료 상품 체크
                      if (checkOrderStatusCodeRowKeys(ORDER_STATUS_TYPE.OPS002)) {
                        try {
                          // 발주확인
                          await Promise.all(
                            selectedRowKeys.map((orderProductId) =>
                              restApi.put(`/partners/account-orders/${orderProductId}/confirm-place-order`),
                            ),
                          );
                          handleRefresh();
                          Modal.success({
                            title: '발주확인',
                            content: '발주확인 처리가 완료되었습니다.',
                            centered: true,
                          });
                        } catch (error) {
                          // 발주확인 실패
                          handleError(error);
                          Modal.error({
                            title: '발주확인',
                            content: '발주확인 처리에 실패했습니다.',
                            centered: true,
                          });
                        }
                      }
                      // 결제완료 상품이 아닌 것이 있을 경우
                      else {
                        Modal.warn({
                          title: '발주확인',
                          content: '결제완료 상품만 발주확인처리를 할 수 있습니다.',
                          centered: true,
                        });
                      }
                    }
                    // 선택된 값이 1개도 없을경우
                    else {
                      Modal.warn({
                        title: '발주확인',
                        content: '처리할 상품주문번호를 먼저 선택해주세요.',
                        centered: true,
                      });
                    }
                  },
                });
              }}
            >
              발주확인
            </Button>
            <Button
              disabled={!checkOrderStatusCodeRowKeys(ORDER_STATUS_TYPE.OPS003)}
              onClick={() => {
                // 선택된 값이 1개 이상일 경우
                if (selectedRowKeys.length > 0) {
                  // 상품준비 상품 체크
                  if (checkOrderStatusCodeRowKeys(ORDER_STATUS_TYPE.OPS002, ORDER_STATUS_TYPE.OPS003)) {
                    Modal.info({
                      title: '발송지연 처리',
                      width: 650,
                      content: (
                        <Form
                          onFinish={({ delayReason }) => {
                            Modal.confirm({
                              title: '발송지연 처리',
                              content: (
                                <>
                                  발송지연 처리 후에는 구매고객에게는 발송지연으로 노출됩니다. {delayReason}
                                  <br />
                                  선택한 내역을 발송지연 처리를 하시겠습니까?
                                </>
                              ),
                              centered: true,
                              okText: '확인',
                              cancelText: '취소',
                              async onOk() {
                                if (!delayReason) {
                                  return Modal.warn({
                                    title: '발송지연 처리',
                                    content: '발송지연 사유를 입력하세요.',
                                    centered: true,
                                  });
                                }

                                try {
                                  // 발송처리
                                  await Promise.all(
                                    selectedRowKeys.map((orderProductId) =>
                                      restApi.put(`/partners/account-orders/${orderProductId}/delay-send`, {
                                        delayReason,
                                      }),
                                    ),
                                  );
                                  Modal.destroyAll();
                                  handleRefresh();
                                  Modal.success({
                                    title: '발송지연 처리',
                                    content: '발송지연 처리가 되었습니다.',
                                    centered: true,
                                  });
                                } catch (error) {
                                  // 발송지연처리 실패
                                  handleError(error);
                                  Modal.error({
                                    title: '발송지연 처리',
                                    content: '발송지연 처리애 실패했습니다.',
                                    centered: true,
                                  });
                                }
                              },
                            });
                          }}
                        >
                          <Space direction="vertical" size="middle" style={{ width: '100%' }}>
                            <Form.Item label="발송지연 사유" name="delayReason">
                              <Input />
                            </Form.Item>
                            <Row justify="end" style={{ marginBottom: -25 }} gutter={10}>
                              <Col>
                                <Button type="primary" htmlType="submit">
                                  발송지연 처리
                                </Button>
                              </Col>
                              <Col>
                                <Button
                                  onClick={() => {
                                    Modal.destroyAll();
                                  }}
                                >
                                  취소
                                </Button>
                              </Col>
                            </Row>
                          </Space>
                        </Form>
                      ),
                      okButtonProps: { style: { display: 'none' } },
                      centered: true,
                    });
                  }
                  // 상품준비 상품이 아닌 것이 있을 경우
                  else {
                    Modal.warn({
                      title: '발송지연 처리',
                      content: '발주확인 처리 된 내역만 발송지연 처리를 할 수 있습니다.',
                      centered: true,
                    });
                  }
                }
                // 선택된 값이 1개도 없을경우
                else {
                  Modal.warn({
                    title: '발송지연 처리',
                    content: '처리할 상품주문번호를 먼저 선택해주세요.',
                    centered: true,
                  });
                }
              }}
            >
              발송지연 처리
            </Button>
            <Button
              disabled={!checkOrderStatusCodeRowKeys()}
              onClick={() => {
                // 선택된 값이 1개 이상일 경우
                if (selectedRowKeys.length > 0) {
                  Modal.info({
                    title: '배송지 정보 수정',
                    width: 650,
                    content: <ChangeDelivery selectedRowKeys={selectedRowKeys} onSubmit={handleRefresh} />,
                    okButtonProps: { style: { display: 'none' } },
                    centered: true,
                  });
                }
                // 선택된 값이 1개도 없을경우
                else {
                  Modal.warn({
                    title: '배송지 정보 수정',
                    content: '처리할 상품주문번호를 먼저 선택해주세요.',
                    centered: true,
                  });
                }
              }}
            >
              고객 배송지 정보 수정
            </Button>
          </Space>
        </Descriptions.Item>
        <Descriptions.Item label="발송처리" labelStyle={{ width: 140, alignItems: 'center' }}>
          <Space>
            <Button
              disabled={!checkOrderStatusCodeRowKeys(ORDER_STATUS_TYPE.OPS003, ORDER_STATUS_TYPE.OPS004)}
              onClick={() => {
                // 선택된 값이 1개 이상일 경우
                if (selectedRowKeys.length > 0) {
                  // 상품준비, 발송지연 상품 체크
                  if (checkOrderStatusCodeRowKeys(ORDER_STATUS_TYPE.OPS003, ORDER_STATUS_TYPE.OPS004)) {
                    Modal.info({
                      title: '발송처리',
                      width: 650,
                      content: (
                        <DeliverySendForm
                          selectedRowKeys={selectedRowKeys}
                          onRefresh={handleRefresh}
                          codesTree={codesTree}
                        />
                      ),
                      okButtonProps: { style: { display: 'none' } },
                      centered: true,
                    });
                  }
                  // 결제완료, 발송지연 상품이 아닌 것이 있을 경우
                  else {
                    Modal.warn({
                      title: '발송처리',
                      content: '발주확인 처리 된 내역만 발송처리를 할 수 있습니다.',
                      centered: true,
                    });
                  }
                }
                // 선택된 값이 1개도 없을경우
                else {
                  Modal.warn({
                    title: '발송처리',
                    content: '처리할 상품주문번호를 먼저 선택해주세요.',
                    centered: true,
                  });
                }
              }}
            >
              발송 처리
            </Button>
            <Button
              disabled={
                !checkOrderStatusCodeRowKeys(
                  ORDER_STATUS_TYPE.OPS002,
                  ORDER_STATUS_TYPE.OPS003,
                  ORDER_STATUS_TYPE.OPS004,
                  ORDER_STATUS_TYPE.OPS005,
                  ORDER_STATUS_TYPE.OPS006,
                  ORDER_STATUS_TYPE.OPS007,
                )
              }
              onClick={() => {
                Modal.info({
                  title: '엑셀 일괄 발송처리',
                  content: <DeliverySendExcelForm codesTree={codesTree} onRefresh={handleRefresh} />,
                  okButtonProps: { style: { display: 'none' } },
                  centered: true,
                  width: 600,
                });
              }}
            >
              엑셀 일괄 발송처리
            </Button>
            <Button
              disabled={
                !checkOrderStatusCodeRowKeys(
                  ORDER_STATUS_TYPE.OPS002,
                  ORDER_STATUS_TYPE.OPS003,
                  ORDER_STATUS_TYPE.OPS004,
                  ORDER_STATUS_TYPE.OPS005,
                  ORDER_STATUS_TYPE.OPS006,
                  ORDER_STATUS_TYPE.OPS007,
                )
              }
              onClick={() => {
                if (
                  selectedRowKeys.length > 0 &&
                  checkOrderStatusCodeRowKeys(
                    ORDER_STATUS_TYPE.OPS002,
                    ORDER_STATUS_TYPE.OPS003,
                    ORDER_STATUS_TYPE.OPS004,
                    ORDER_STATUS_TYPE.OPS005,
                    ORDER_STATUS_TYPE.OPS006,
                    ORDER_STATUS_TYPE.OPS007,
                  )
                ) {
                  Modal.info({
                    title: '송장 수정',
                    width: 650,
                    content: (
                      <ModifyInvoice
                        selectedRowKeys={selectedRowKeys}
                        onRefresh={handleRefresh}
                        codesTree={codesTree}
                      />
                    ),
                    okButtonProps: { style: { display: 'none' } },
                    centered: true,
                  });
                } else {
                  Modal.warn({
                    title: '송장 수정',
                    content: '처리할 상품주문번호를 먼저 선택해주세요.',
                    centered: true,
                  });
                }
              }}
            >
              송장 수정
            </Button>
          </Space>
        </Descriptions.Item>
        <Descriptions.Item label="취소처리" labelStyle={{ width: 140, alignItems: 'center' }}>
          <Space>
            <Button
              disabled={!checkOrderStatusCodeRowKeys()}
              onClick={() => {
                // 선택된 값이 1개 이상일 경우
                if (selectedRowKeys.length > 0) {
                  Modal.info({
                    title: '판매취소 처리',
                    width: 650,
                    content: (
                      <Form
                        onFinish={async ({ cancelMessage, cancelReason }) => {
                          if (!cancelReason) {
                            return Modal.warn({
                              title: '판매취소 처리',
                              content: '판매취소 사유를 입력하세요.',
                              centered: true,
                            });
                          }
                          if (!cancelMessage) {
                            return Modal.warn({
                              title: '판매취소 처리',
                              content: '구매자에게 전하실 말씀을 입력하세요.',
                              centered: true,
                            });
                          }
                          try {
                            // 발송처리
                            await Promise.all(
                              selectedRowKeys.map((orderProductId) =>
                                restApi.put(`/partners/account-orders/${orderProductId}/cancel`, {
                                  cancelMessage,
                                  cancelReason,
                                }),
                              ),
                            );
                            Modal.destroyAll();
                            handleRefresh();
                            Modal.success({
                              title: '판매취소 처리',
                              content: '판매취소 처리되었습니다.',
                              centered: true,
                            });
                          } catch (error) {
                            // 발송지연처리 실패
                            handleError(error);
                            Modal.error({
                              title: '판매취소 처리',
                              content: '판매취소 처리 실패했습니다.',
                              centered: true,
                            });
                          }
                        }}
                      >
                        <Space direction="vertical" size="middle" style={{ width: '100%' }}>
                          <Form.Item label="판매취소 사유" name="cancelReason">
                            <Input />
                          </Form.Item>
                          <Form.Item label="구매자에게 전하실 말씀" name="cancelMessage">
                            <Input.TextArea />
                          </Form.Item>
                          <Row justify="end" style={{ marginBottom: -25 }} gutter={10}>
                            <Col>
                              <Button type="primary" htmlType="submit">
                                판매취소 처리
                              </Button>
                            </Col>
                            <Col>
                              <Button
                                onClick={() => {
                                  Modal.destroyAll();
                                }}
                              >
                                취소
                              </Button>
                            </Col>
                          </Row>
                        </Space>
                      </Form>
                    ),
                    okButtonProps: { style: { display: 'none' } },
                    centered: true,
                  });
                }
                // 선택된 값이 1개도 없을경우
                else {
                  Modal.warn({
                    title: '판매취소 처리',
                    content: '처리할 상품주문번호를 먼저 선택해주세요.',
                    centered: true,
                  });
                }
              }}
            >
              판매취소
            </Button>
            <Button
              disabled={!selectedRowKeys.length || !checkOrderStatusCodeRowKeys(ORDER_STATUS_TYPE.OPS008)}
              onClick={() => {
                // 선택된 값이 1개 이상일 경우
                if (selectedRowKeys.length > 0) {
                  // 선택된 값이 취소인것들만 있을 경우
                  if (checkOrderStatusCodeRowKeys(ORDER_STATUS_TYPE.OPS008)) {
                    Modal.confirm({
                      title: '취소 완료처리',
                      content: (
                        <>
                          취소 완료 처리 후에는 되돌릴 수 없으며, 결제된 내역은 자동 환불처리가 됩니다.
                          <br />
                          선택한 내역을 취소 완료처리를 하시겠습니까?
                        </>
                      ),
                      centered: true,
                      async onOk() {
                        try {
                          // 발송처리
                          await Promise.all(
                            selectedRowKeys.map((orderProductId) =>
                              restApi.put(`/partners/account-orders/${orderProductId}/confirm-cancel`),
                            ),
                          );
                          Modal.destroyAll();
                          handleRefresh();
                          Modal.success({
                            title: '취소 완료처리',
                            content: '취소 완료처리가 되었습니다.',
                            centered: true,
                          });
                        } catch (error) {
                          // 발송지연처리 실패
                          handleError(error);
                          Modal.error({
                            title: '취소 완료처리',
                            content: '취소 완료처리 실패했습니다.',
                            centered: true,
                          });
                        }
                      },
                    });
                  }
                  // 선택된 값이 취소가 아닌게 있을 경우
                  else {
                    Modal.warn({
                      title: '취소 완료처리',
                      content: '취소 처리 된 내역만 취소 완료처리를 할 수 있습니다.',
                      centered: true,
                    });
                  }
                }
                // 선택된 값이 1개도 없을경우
                else {
                  Modal.warn({
                    title: '취소 완료처리',
                    content: '처리할 상품주문번호를 먼저 선택해주세요.',
                    centered: true,
                  });
                }
              }}
            >
              취소 완료처리
            </Button>
          </Space>
        </Descriptions.Item>
      </Descriptions>
    </Space>
  );
}

export default SalesDelivery;
