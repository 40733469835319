import { Button, DatePicker, Divider, Form, Radio, Row, Space } from 'antd';
import React from 'react';
import { restApi } from '#apis/index';
import SearchForm from '../../../components/SearchForm';
import produce from 'immer';
import moment from 'moment';
import { StatisticsPeriod } from '#common/constants';
import Chart from 'react-apexcharts';
import { DAY } from '#common/define';

const initialParams = {
  selectDateType: 'DATE',
  startAt: moment().subtract(7, 'day').format('YYYY-MM-DD'),
  endAt: moment().format('YYYY-MM-DD'),
};
const PayersAndPaymentsPerDay = () => {
  const [params, setParams] = React.useState(initialParams);
  const [data, setData] = React.useState(null);

  const getServerData = React.useCallback(async (params) => {
    if (!params.startAt || !params.endAt) {
      return alert('날짜범위를 지정해주세요.');
    }
    try {
      const { data } = await restApi.get('/statistics/day/payer', {
        params,
      });

      const parsedData = {
        label: [],
        payCount: [],
        payerCount: [],
      };

      if (Array.isArray(data)) {
        data.forEach((item) => {
          let label = `${moment(item.date).format('MM.DD')}(${DAY[item.dayOfWeek]})`;
          if (params.selectDateType === 'MONTH') {
            label = `${moment(item.date).format('YYYY.MM')}`;
          } else if (params.selectDateType === 'WEEK') {
            label = [
              label,
              `~ ${moment(item.date).endOf('isoWeek').format('MM.DD')}(${moment(item.date)
                .endOf('isoWeek')
                .format('ddd')})`,
            ];
          }
          parsedData.label.push(label);
          parsedData.payCount.push(item.payCount);
          parsedData.payerCount.push(item.payerCount);
        });
      }

      setData(parsedData);
    } catch (error) {
      console.warn('error :', error);
      alert('에러가 발생했습니다. 잠시후 다시 시도해주세요.');
    }
  }, []);

  // console.log('data ::: ', data);

  React.useEffect(() => {
    getServerData(initialParams);
  }, [getServerData]);

  return (
    <Space style={{ width: '100%' }} direction="vertical">
      <SearchForm
        params={params}
        onInit={(params) => {
          getServerData(params);
          setParams(params);
        }}
        onReset={() => {
          setParams(initialParams);
          getServerData(initialParams);
          return initialParams;
        }}
      >
        <Row style={{ marginBottom: '10px' }}>
          <Form.Item label="조회단위">
            <Radio.Group
              onChange={(event) => {
                setParams(
                  produce((draft) => {
                    draft.selectDateType = event.target.value;
                  }),
                );
              }}
              value={params.selectDateType}
              options={StatisticsPeriod}
            />
          </Form.Item>
        </Row>
        <Row>
          <Form.Item label="조회기간">
            <Space>
              <DatePicker.RangePicker
                picker={
                  params.selectDateType === 'MONTH' ? 'month' : params.selectDateType === 'WEEK' ? 'week' : 'date'
                }
                value={[params.startAt ? moment(params.startAt) : null, params.endAt ? moment(params.endAt) : null]}
                onChange={([startAt, endAt]) => {
                  if (params.selectDateType === 'WEEK') {
                    startAt = startAt.startOf('isoWeek');
                    endAt = endAt.startOf('isoWeek');
                  } else if (params.selectDateType === 'MONTH') {
                    startAt = startAt.startOf('month');
                    endAt = endAt.startOf('month');
                  }
                  setParams(
                    produce((draft) => {
                      draft.startAt = startAt.format('YYYY-MM-DD');
                      draft.endAt = endAt.format('YYYY-MM-DD');
                    }),
                  );
                }}
                // disabledDate={
                //   params.selectDateType === 'DAY' &&
                //   ((current) => {
                //     if (params.startAt || params.endAt) {
                //       const tooEarly = params.startAt && current.diff(params.startAt, 'days') > 10;
                //       const tooLate = params.endAt && moment(params.endAt).diff(current, 'days') > 10;
                //       return !!tooEarly || !!tooLate;
                //     }
                //     return false;
                //   })
                // }
                onCalendarChange={([startAt, endAt]) => {
                  setParams(
                    produce((draft) => {
                      draft.startAt = startAt?.format('YYYY-MM-DD');
                      draft.endAt = endAt?.format('YYYY-MM-DD');
                    }),
                  );
                }}
                onOpenChange={(open) => {
                  if (open) {
                    setParams(
                      produce((draft) => {
                        draft.startAt = null;
                        draft.endAt = null;
                      }),
                    );
                  }
                }}
              />
              <Button type="primary" htmlType="submit">
                검색
              </Button>
              <Button htmlType="reset">초기화</Button>
            </Space>
          </Form.Item>
        </Row>
      </SearchForm>
      <Divider />
      <div>일별 결제자수 및 결제수</div>
      <Chart
        series={[
          {
            name: '결제자수',
            data: data?.payerCount || [],
          },
          {
            name: '결제수',
            data: data?.payCount || [],
          },
        ]}
        type="bar"
        height={600}
        width={'80%'}
        options={{
          plotOptions: {
            bar: {
              horizontal: false,
              columnWidth: '55%',
            },
          },
          dataLabels: {
            enabled: false,
          },
          stroke: {
            show: true,
            width: 2,
            colors: ['transparent'],
          },
          xaxis: {
            categories: data?.label || [],
          },
          // yaxis: {
          //   title: {
          //     text: '$ (thousands)',
          //   },
          // },
          fill: {
            opacity: 1,
          },
          // tooltip: {
          //   y: {
          //     formatter: function (val) {
          //       return '$ ' + val + ' thousands';
          //     },
          //   },
          // },
        }}
      />
    </Space>
  );
};

export default PayersAndPaymentsPerDay;
