import React from 'react';
import { Button, Space, Descriptions, Spin, Modal } from 'antd';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames/bind';
import styles from '../../Page.module.less';
import { restApi } from '#apis';
import { useReduxStore } from '#hooks/useReduxStore';
import formatComma from '#utils/formatComma';

const cx = classNames.bind(styles);

function PartnersStoreAccountInfoIndex() {
  const { map } = useReduxStore('codes');
  const navigate = useNavigate();
  const [partner, setPartner] = React.useState(null);

  React.useEffect(() => {
    restApi
      .get(`/partners/stores/seller`)
      .then((response) => {
        setPartner(response.data);
      })
      .catch((e) => {
        Modal.warning({
          title: '실패',
          content: '정보를 받아오는 데 실패했습니다',
          onOk: () => {
            navigate('/', {
              replace: true,
            });
          },
          onCancel: () => {
            navigate('/', {
              replace: true,
            });
          },
        });
        console.warn(e);
      });
  }, []);

  if (!partner) return <Spin />;

  return (
    <div>
      <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
        <Descriptions bordered>
          <Descriptions.Item span={3} labelStyle={{ width: 140 }} label="파트너사아이디">
            {partner.userId}
          </Descriptions.Item>
          <Descriptions.Item span={3} labelStyle={{ width: 140 }} label="파트너사이름">
            {partner.userName}
          </Descriptions.Item>
          <Descriptions.Item span={3} labelStyle={{ width: 140 }} label="휴대전화">
            {partner.phone}
          </Descriptions.Item>
          <Descriptions.Item span={3} labelStyle={{ width: 140 }} label="이메일">
            {partner.email}
          </Descriptions.Item>
        </Descriptions>

        <Descriptions bordered>
          <Descriptions.Item span={3} labelStyle={{ width: 140 }} label="활동내역" contentStyle={{ padding: 0 }}>
            <Descriptions bordered>
              <Descriptions.Item span={3} labelStyle={{ width: 140 }} label="판매상품">
                {partner.productCount && (
                  <>
                    {partner.productCount} 건{' '}
                    <Button
                      style={{ marginLeft: 20 }}
                      onClick={() => {
                        navigate('/goods/list');
                      }}
                    >
                      판매상품 목록
                    </Button>
                  </>
                )}
              </Descriptions.Item>
              <Descriptions.Item span={3} labelStyle={{ width: 140 }} label="정산금액">
                {typeof partner?.amount ? formatComma(partner.amount) : null}
              </Descriptions.Item>
              <Descriptions.Item span={3} labelStyle={{ width: 140 }} label="주문건수">
                {partner.requestAmount}
              </Descriptions.Item>
            </Descriptions>
          </Descriptions.Item>
        </Descriptions>

        <Descriptions bordered>
          <Descriptions.Item span={3} labelStyle={{ width: 140 }} label="택배사">
            {partner.deliveries.length > 0 ? partner.deliveries.map((value) => map?.[value]).join(', ') : ''}
          </Descriptions.Item>
        </Descriptions>

        <Descriptions bordered>
          <Descriptions.Item span={3} labelStyle={{ width: 140 }} label="대표자명">
            {partner.representative}
          </Descriptions.Item>
          <Descriptions.Item span={3} labelStyle={{ width: 140 }} label="상호명">
            {partner.businessName}
          </Descriptions.Item>
          <Descriptions.Item span={3} labelStyle={{ width: 140 }} label="통신판매업자번호">
            {partner.orderDealerNumber}
          </Descriptions.Item>
          <Descriptions.Item span={3} labelStyle={{ width: 140 }} label="사업자번호">
            {partner.businessNumber}
          </Descriptions.Item>
          <Descriptions.Item span={2} labelStyle={{ width: 140 }} label="사업자 주소">
            {`${partner.bizAddress} ${partner.addrDetail || ''}`}
          </Descriptions.Item>
          <Descriptions.Item span={1} labelStyle={{ width: 140 }} label="우편번호">
            {partner.bizZipcode}
          </Descriptions.Item>
          <Descriptions.Item span={3} labelStyle={{ width: 140 }} label="사업자 등록증">
            <a rel="noreferrer" target="_blank" href={partner.bizImg} download>
              {partner.bizImgFileName}
            </a>
          </Descriptions.Item>
          <Descriptions.Item span={3} labelStyle={{ width: 140 }} label="계좌번호">
            {map[partner.bankName]} {partner.accountNumber} / {partner.accountHolder} 판매사
          </Descriptions.Item>
        </Descriptions>
      </Space>
      <div className={cx({ buttonBox: true })}>
        <Button onClick={() => navigate('/store/account/modify')}>수정</Button>
      </div>
    </div>
  );
}

export default PartnersStoreAccountInfoIndex;
