import { restApi } from '#apis/index';
import handleError from '#utils/handleError';
import Postcode from '@actbase/react-daum-postcode';
import produce from 'immer';
import moment from 'moment';
import { useReduxStore } from '#hooks/useReduxStore';
import React from 'react';
import { Button, Modal, Input, Descriptions, Space, Select, Radio, Row, Col, DatePicker, Form, Typography } from 'antd';
import SearchForm from '#components/SearchForm';
import CommTable from '#components/table/CommTable';
import ShowOrderDetailBtn from '#components/Buttons/ShowOrderDetailBtn';
import useCheckOrderStatusCodeRowKeys from '#hooks/useCheckOrderStatusCodeRowKeys';
import ChangeInvoice from '#components/Modal/InnerContent/ChangeInvoice';

const DATE_TYPE = {
  REQUEST_AT: 'RequestAt',
  PAY_AT: 'PayAt',
};

const SEARCH_TYPE = {
  BUYER: 'Buyer',
  BUYER_ID: 'BuyerId',
  ORDER_NUMBER: 'OrderId',
  ORDER_PRODUCT_NUMBER: 'OrderProductId',
  PRODUCT_NUMBER: 'ProductId',
  RECIPIENT: 'Recipient',
};

const ChangePlaceToCollect = ({ changeIds, onSubmit = () => {} }) => {
  const formRef = React.useRef();
  return (
    <Form
      ref={formRef}
      onFinish={async ({
        collectAddr,
        collectAddrDetail,
        collectPhone,
        collectRecipient,
        collectSubPhone,
        collectZipCode,
      }) => {
        if (!collectRecipient) {
          return Modal.warn({
            title: '회수지 정보 수정',
            content: '수취인명을 입력하세요.',
            centered: true,
          });
        }
        if (!collectPhone) {
          return Modal.warn({
            title: '회수지 정보 수정',
            content: '휴대폰 번호를 입력하세요.',
            centered: true,
          });
        }
        if (!collectSubPhone) {
          return Modal.warn({
            title: '회수지 정보 수정',
            content: '전화번호를 입력하세요.',
            centered: true,
          });
        }
        if (!collectAddr) {
          return Modal.warn({
            title: '회수지 정보 수정',
            content: '회수지 주소를 입력하세요.',
            centered: true,
          });
        }
        try {
          // 회수지 정보 수정
          await Promise.all(
            changeIds.map((changeId) =>
              restApi.put(`/partners/account-orders/change-order/${changeId}/collect`, {
                collectAddr,
                collectAddrDetail,
                collectPhone,
                collectRecipient,
                collectSubPhone,
                collectZipCode,
              }),
            ),
          );

          Modal.destroyAll();
          onSubmit();
          Modal.success({
            title: '회수지 정보 수정',
            content: '회수지 정보가 변경되었습니다.',
            centered: true,
          });
        } catch (error) {
          // 회수지 정보 수정 실패
          handleError(error);
          Modal.error({
            title: '회수지 정보 수정',
            content: '회수지 정보 수정에 실패했습니다.',
            centered: true,
          });
        }
      }}
    >
      <Space direction="vertical" size="middle" style={{ width: '100%' }}>
        <Space direction="vertical" style={{ width: '100%' }}>
          <Form.Item label="수취인명" name="collectRecipient">
            <Input />
          </Form.Item>
          <Form.Item label="휴대폰 번호" name="collectPhone">
            <Input />
          </Form.Item>
          <Form.Item label="전화번호" name="collectSubPhone">
            <Input />
          </Form.Item>
          <Space>
            <Form.Item label="회수지 주소" name="collectZipCode">
              <Input style={{ width: 120 }} />
            </Form.Item>
            <Button
              type="primary"
              onClick={() => {
                const addressModal = Modal.info({
                  title: '주소변경',
                  content: (
                    <Postcode
                      style={{ marginLeft: -30 }}
                      onSelected={({ zonecode, address }) => {
                        formRef.current.setFieldsValue({
                          collectZipCode: zonecode,
                          collectAddr: address,
                        });
                        addressModal.destroy();
                      }}
                    />
                  ),
                  width: 600,
                  closable: true,
                  centered: true,
                  okButtonProps: { style: { display: 'none' } },
                });
              }}
            >
              우편번호 찾기
            </Button>
          </Space>
          <Form.Item name="collectAddr" label={'\t'}>
            <Input />
          </Form.Item>
          <Form.Item name="collectAddrDetail" label={'\t'}>
            <Input />
          </Form.Item>
        </Space>
        <Row justify="end" style={{ marginBottom: -25, alignItems: 'center' }} gutter={10}>
          <Col>
            <Button type="primary" htmlType="submit">
              변경
            </Button>
          </Col>
          <Col>
            <Button
              onClick={() => {
                Modal.destroyAll();
              }}
            >
              취소
            </Button>
          </Col>
        </Row>
      </Space>
    </Form>
  );
};

function SalesExchange() {
  const { map: codesMap, tree: codesTree } = useReduxStore('codes');

  const ORDER_STATUS_TYPE = React.useMemo(
    () =>
      codesTree?.OPS.items.reduce((p, v) => {
        p[v.code] = v.code;
        return p;
      }, {}),
    [codesTree],
  );

  const initialParams = React.useMemo(
    () => ({
      dateType: null,
      startAt: null,
      endAt: null,
      changeStateCode: null,
      searchType: null,
    }),
    [],
  );
  const [params, setParams] = React.useState(initialParams);
  const tableRef = React.useRef();
  const [selectedRowKeys, setSelectedRowKeys] = React.useState([]);

  const { checkOrderStatusCodeRowKeys } = useCheckOrderStatusCodeRowKeys(
    tableRef.current?.dataSource,
    selectedRowKeys,
    { keyword: 'changeId' },
  );

  const handleChangePagination = React.useCallback(() => {
    setSelectedRowKeys([]);
  }, []);

  const handleRefresh = React.useCallback(() => {
    setSelectedRowKeys([]);
    tableRef.current?.loadData();
  }, []);

  const handleShowDetail = React.useCallback(
    (changeId) => {
      restApi.get(`/partners/account-orders/change-order/${changeId}/collect`).then(({ data }) => {
        Modal.info({
          title: '수거 정보 수정',
          width: 650,
          content: (
            <Space direction="vertical" size="middle" style={{ width: '100%' }}>
              <Descriptions bordered column>
                <Descriptions.Item span={3} label="상품 회수지">
                  <div style={{ minWidth: 300 }}>
                    <div>
                      <Typography.Text>{data.collectRecipient}</Typography.Text>
                      <Button
                        style={{ marginLeft: 20 }}
                        onClick={() => {
                          Modal.info({
                            title: '수거지 정보 수정',
                            width: 650,
                            content: <ChangePlaceToCollect changeIds={[changeId]} onSubmit={handleRefresh} />,
                            okButtonProps: { style: { display: 'none' } },
                            centered: true,
                          });
                        }}
                        type="primary"
                      >
                        변경하기
                      </Button>
                    </div>
                    <p>{data.collectAddr}</p>
                  </div>
                </Descriptions.Item>
                <Descriptions.Item span={3} label="회수 요청사항">
                  <div style={{ minWidth: 300 }}>
                    <Typography.Text>그 외 장소</Typography.Text>
                    <Input style={{ marginTop: 5 }} value={`${data.collectRequirement}`} disabled />
                  </div>
                </Descriptions.Item>
              </Descriptions>
              <Row justify="end" style={{ marginBottom: -25, alignItems: 'center' }} gutter={10}>
                <Col>
                  <Button
                    onClick={() => {
                      Modal.destroyAll();
                    }}
                  >
                    취소
                  </Button>
                </Col>
              </Row>
            </Space>
          ),
          okButtonProps: { style: { display: 'none' } },
          centered: true,
        });
      });
    },
    [handleRefresh],
  );

  return (
    <Space direction="vertical" style={{ width: '100%' }}>
      <SearchForm
        params={params}
        onInit={setParams}
        onReset={() => {
          setParams(initialParams);
          return initialParams;
        }}
      >
        <Row gutter={10} style={{ marginBottom: 10, alignItems: 'center' }}>
          <Col>
            <Select
              value={params.dateType}
              placeholder="날짜 옵션"
              onChange={(value) => {
                setParams(
                  produce((draft) => {
                    draft.dateType = value;
                  }),
                );
              }}
              options={[
                { label: '결제일', value: DATE_TYPE.PAY_AT },
                { label: '요청일', value: DATE_TYPE.REQUEST_AT },
              ]}
              style={{ width: 150 }}
            />
          </Col>
          <Col>
            <DatePicker.RangePicker
              disabled={!params.dateType}
              value={[
                params.startAt ? moment(params.startAt) : undefined,
                params.endAt ? moment(params.endAt) : undefined,
              ]}
              onChange={([startAt, endAt]) => {
                setParams(
                  produce((draft) => {
                    draft.startAt = startAt.format('YYYY-MM-DD');
                    draft.endAt = endAt.format('YYYY-MM-DD');
                  }),
                );
              }}
            />
          </Col>
          <Col>
            <Radio.Group
              disabled={!params.dateType}
              value={moment(params.endAt).diff(params.startAt, 'day')}
              onChange={(event) => {
                setParams(
                  produce((draft) => {
                    draft.startAt = moment().add(-event.target.value, 'day').format('YYYY-MM-DD');
                    draft.endAt = moment().format('YYYY-MM-DD');
                  }),
                );
              }}
              options={[
                { label: '오늘', value: 0 },
                { label: '1주일', value: 7 },
                { label: '1개월', value: 30 },
                { label: '3개월', value: 90 },
              ]}
              buttonStyle="solid"
              optionType="button"
            />
          </Col>
        </Row>
        <Row gutter={10} style={{ alignItems: 'center' }}>
          <Col>
            <Select
              value={params.orderProductStateCode}
              onChange={(value) => {
                setParams(
                  produce((draft) => {
                    draft.orderProductStateCode = value;
                  }),
                );
              }}
              options={codesTree?.DM.items.map((value) => ({ label: value.label, value: value.code })) || []}
              placeholder="배송방법"
              allowClear
              style={{ width: 150 }}
            />
          </Col>
          <Col>
            <Select
              value={params.changeStateCode}
              onChange={(value) => {
                setParams(
                  produce((draft) => {
                    draft.changeStateCode = value;
                  }),
                );
              }}
              options={codesTree?.CHS.items.map((value) => ({ label: value.label, value: value.code }))}
              placeholder="처리상태"
              allowClear
              style={{ width: 150 }}
            />
          </Col>
          <Col>
            <Select
              value={params.searchType}
              onChange={(value) => {
                setParams(
                  produce((draft) => {
                    draft.searchType = value;
                  }),
                );
              }}
              placeholder="검색 옵션"
              options={[
                { label: '수취인명', value: SEARCH_TYPE.RECIPIENT },
                { label: '구매자명', value: SEARCH_TYPE.BUYER },
                { label: '구매자 ID', value: SEARCH_TYPE.BUYER_ID },
                { label: '주문번호', value: SEARCH_TYPE.ORDER_NUMBER },
                { label: '상품주문번호', value: SEARCH_TYPE.ORDER_PRODUCT_NUMBER },
                { label: '상품번호', value: SEARCH_TYPE.PRODUCT_NUMBER },
              ]}
              style={{ width: 150 }}
            />
          </Col>
          <Col>
            <Input
              value={params.keyword}
              onChange={(event) => {
                setParams(
                  produce((draft) => {
                    draft.keyword = event.target.value;
                  }),
                );
              }}
              placeholder="키워드를 입력하세요"
              style={{ width: 250 }}
            />
          </Col>
          <Col>
            <Space>
              <Button type="primary" htmlType="submit">
                검색
              </Button>
              <Button htmlType="reset">초기화</Button>
            </Space>
          </Col>
        </Row>
        <CommTable
          ref={tableRef}
          url="/partners/account-orders/change-order"
          rowSelection={{
            selectedRowKeys,
            onChange: setSelectedRowKeys,
            type: 'radio',
          }}
          scroll={{ x: 2000 }}
          rowKey={({ changeId }) => changeId}
          onChangePagination={handleChangePagination}
          columns={[
            {
              title: '주문번호',
              dataIndex: 'orderProductId',
              index: 'key',
              fixed: true,
              width: 100,
              render: (value) => <ShowOrderDetailBtn orderId={value} />,
            },
            {
              title: '파트너사',
              dataIndex: 'businessName',
              fixed: true,
              width: 100,
              render: (businessName) => businessName,
            },
            {
              title: '주문상태',
              dataIndex: 'orderProductStateCode',
              fixed: true,
              width: 100,
              render: (orderProductStateCode) => codesMap[orderProductStateCode],
            },
            {
              title: '교환 처리상태',
              dataIndex: 'changeStateCode',
              width: 100,
              render: (changeStateCode) => codesMap[changeStateCode],
            },
            {
              title: '결제일',
              dataIndex: 'payAt',
              width: 200,
              render: (payAt) => payAt,
            },
            {
              title: '교환요청일',
              dataIndex: 'requestAt',
              width: 200,
              render: (requestAt) => requestAt,
            },
            {
              title: '교환사유',
              dataIndex: 'reason',
              width: 100,
              render: (reason, obj) => (
                <Button
                  type="text"
                  onClick={() => {
                    Modal.info({
                      title: '교환 상세사유',
                      width: 650,
                      content: (
                        <div>
                          <Space direction="vertical" size="middle" style={{ width: '100%' }}>
                            <Descriptions>
                              <Descriptions.Item span={3} label="상품명">
                                {obj.productName}
                              </Descriptions.Item>
                              <Descriptions.Item label="상품주문번호" span={3}>
                                {obj.orderProductId}
                              </Descriptions.Item>
                              <Descriptions.Item label="사유" span={3}>
                                {reason}
                              </Descriptions.Item>
                            </Descriptions>
                          </Space>
                          <Row justify="end" style={{ marginBottom: -25 }} gutter={10}>
                            <Col>
                              <Button
                                onClick={() => {
                                  Modal.destroyAll();
                                }}
                              >
                                취소
                              </Button>
                            </Col>
                          </Row>
                        </div>
                      ),
                      okButtonProps: { style: { display: 'none' } },
                      centered: true,
                    });
                  }}
                >
                  {reason}
                </Button>
              ),
            },
            {
              title: '회수/환불 정보',
              dataIndex: 'changeId',
              width: 100,
              render: (value) => (
                <Button onClick={() => handleShowDetail(value)} type="text" style={{ fontSize: '12px' }}>
                  상세보기
                </Button>
              ),
            },
            // TODO 재배송 상태랑 교환 처리 상태랑 뭐가 다른지
            {
              title: '재배송 상태',
              dataIndex: 'count',
              width: 100,
            },
            // TODO :: 교환배송비 dataIndex를 뭐로 매칭해야 되는지
            {
              title: '교환배송비',
              dataIndex: 'buyerName',
              width: 100,
              render: (buyerName) => buyerName,
            },
            {
              title: '구매자명',
              dataIndex: 'buyerName',
              width: 100,
              render: (value) => value,
            },
            {
              title: '구매자ID',
              dataIndex: 'buyerUserId',
              width: 100,
              render: (value) => value,
            },
            {
              title: '수취인명',
              dataIndex: 'recipient',
              width: 100,
              render: (value) => value,
            },
            {
              title: '상품명',
              dataIndex: 'productName',
              width: 100,
              render: (value) => value,
            },
            {
              title: '상품종류',
              dataIndex: 'categoryCode',
              width: 100,
              render: (value) => value,
            },
            {
              title: '옵션정보',
              dataIndex: 'options',
              width: 100,
              render: (value) => value,
            },
            {
              title: '수량',
              dataIndex: 'count',
              width: 100,
              render: (value) => value,
            },
            {
              title: '옵션가격',
              dataIndex: 'optionAmountEach',
              width: 100,
              render: (value) => value,
            },
            {
              title: '상품가격',
              dataIndex: 'productAmountEach',
              width: 100,
              render: (value) => value,
            },
            {
              title: '상품별 할인액',
              dataIndex: 'productDiscountAmountEach',
              width: 100,
              render: (value) => value,
            },
            {
              title: '상품별 총 주문금액',
              dataIndex: 'totalAmountEach',
              width: 100,
              render: (value) => value,
            },
            {
              title: '배송비 형태',
              dataIndex: 'deliveryPaymentTypeCode',
              width: 100,
              render: (value) => codesMap[value],
            },
            {
              title: '배송비 유형',
              dataIndex: 'deliveryFreeTypeCode',
              width: 100,
              render: (value) => codesMap?.[value],
            },
            {
              title: '배송비 합계',
              dataIndex: 'deliveryTotalAmount',
              width: 100,
              render: (value) => value,
            },
            {
              title: '택배사',
              dataIndex: 'courierCode',
              width: 100,
              render: (value) => codesMap?.[value],
            },
            {
              title: '송장번호',
              dataIndex: 'invoiceNumber',
              width: 100,
              render: (value) => value,
            },
            {
              title: '수거택배사',
              dataIndex: 'collectCourierCode',
              width: 100,
              render: (value) => codesMap?.[value],
            },
            {
              title: '수거송장번호',
              dataIndex: 'collectInvoiceNumber',
              width: 100,
              render: (value) => value,
            },
            {
              title: '재배송방법',
              dataIndex: 'reDeliveryMethodCode',
              width: 100,
              render: (value) => codesMap?.[value],
            },
            {
              title: '재배송 택배사',
              dataIndex: 'reDeliveryCourierCode',
              width: 100,
              render: (value) => codesMap[value],
            },
            {
              title: '재배송 송장번호',
              dataIndex: 'reDeliveryInvoiceNumber',
              width: 100,
              render: (value) => value,
            },
            {
              title: '재배송 처리일',
              dataIndex: 'reDeliveryAt',
              width: 200,
              render: (value) => value,
            },
            {
              title: '구매자연락처',
              dataIndex: 'buyerPhone',
              width: 100,
              render: (value) => value,
            },
            {
              title: '수취인연락처1',
              dataIndex: 'phone',
              width: 100,
              render: (value) => value,
            },
            {
              title: '수취인연락처2',
              dataIndex: 'subPhone',
              width: 100,
              render: (value) => value,
            },
            {
              title: '회수지 주소',
              dataIndex: 'collectAddr',
              width: 250,
              render: (collectAddr, obj) => collectAddr + obj.collectAddrDetail,
            },
            {
              title: '교환철회사유',
              dataIndex: 'reason',
              width: 100,
              render: (value) => value,
            },
          ]}
        />
      </SearchForm>
      <Descriptions colon={false} bordered column={1}>
        <Descriptions.Item label="교환처리" labelStyle={{ width: 140, alignItems: 'center' }}>
          <Space>
            <Button
              disabled={!checkOrderStatusCodeRowKeys(ORDER_STATUS_TYPE.OPS009, ORDER_STATUS_TYPE.OPS012)}
              onClick={() => {
                if (selectedRowKeys.length > 0) {
                  if (checkOrderStatusCodeRowKeys(ORDER_STATUS_TYPE.OPS009, ORDER_STATUS_TYPE.OPS012)) {
                    Modal.confirm({
                      title: '수거 완료처리',
                      width: 650,
                      content: (
                        <Typography.Text>
                          수거 완료 처리 후에는 되돌릴 수 없습니다. 선택한 내역을 수거 완료처리를 하시겠습니까?
                        </Typography.Text>
                      ),
                      onOk: () => {
                        const promiseArr = [];
                        for (let changeId of selectedRowKeys) {
                          promiseArr.push(
                            restApi.put(`/partners/account-orders/change-order/${changeId}/collect-complete`),
                          );
                        }

                        Promise.all(promiseArr)
                          .then(() => {
                            handleRefresh();

                            Modal.destroyAll();
                            Modal.success({
                              title: '수거 완료처리',
                              content: '수거 완료처리 되었습니다.',
                              centered: true,
                              okText: '확인',
                            });
                          })
                          .catch((e) => {
                            handleError(e);
                            Modal.error({
                              title: '수거 완료처리',
                              content: '수거 완료처리 실패했습니다.',
                              centered: true,
                              okText: '확인',
                            });
                          });
                      },
                      centered: true,
                    });
                  } else {
                    Modal.warn({
                      title: '수거 완료처리',
                      content:
                        '선택하신 주문 건은 수거완료 처리가 불가합니다. 수거완료는 교환 처리상태가 “교환요청/수거중”인 경우에만 수거완료 처리가 가능합니다. 교환 처리상태를 확인해 주세요.',
                      centered: true,
                    });
                  }
                } else {
                  Modal.warn({
                    title: '수거 완료처리',
                    content: '처리할 상품주문번호를 먼저 선택해주세요.',
                    centered: true,
                  });
                }
              }}
            >
              수거 완료처리
            </Button>
            <Button
              disabled={!selectedRowKeys.length || !checkOrderStatusCodeRowKeys()}
              onClick={() => {
                if (selectedRowKeys.length > 0) {
                  Modal.info({
                    title: '교환 재배송처리',
                    width: 650,
                    content: (
                      <ChangeInvoice
                        modalTitle="교환 재배송처리"
                        changeIds={selectedRowKeys}
                        codesTree={codesTree}
                        onRefresh={handleRefresh}
                      />
                    ),
                    okButtonProps: { style: { display: 'none' } },
                    centered: true,
                  });
                } else {
                  Modal.warn({
                    title: '교환 재배송처리',
                    content: '처리할 상품주문번호를 먼저 선택해주세요.',
                    centered: true,
                  });
                }
              }}
            >
              교환 재배송처리
            </Button>
            <Button
              disabled={!selectedRowKeys.length || !checkOrderStatusCodeRowKeys(ORDER_STATUS_TYPE.OPS009)}
              onClick={() => {
                // 선택된 값이 1개 이상일 경우
                if (selectedRowKeys.length > 0) {
                  // 선택된 값이 배송완료 인것들만 있을 경우
                  if (checkOrderStatusCodeRowKeys(ORDER_STATUS_TYPE.OPS009)) {
                    Modal.info({
                      title: '교환 거부(철회) 사유',
                      width: 650,
                      content: (
                        <Form
                          onFinish={({ reason }) => {
                            if (!reason) {
                              return alert('사유를 입력해주세요.');
                            }

                            Promise.all(
                              selectedRowKeys.map((id) =>
                                restApi.put(`/partners/account-orders/change-order/${id}/deny`, { reason }),
                              ),
                            )
                              .then(() => {
                                handleRefresh();
                                Modal.success({
                                  title: '교환 거부(철회) 사유',
                                  content: '교환 거부(철회) 사유가  완료 되었습니다.',
                                  centered: true,
                                  okText: '확인',
                                });
                              })
                              .catch(() => {
                                Modal.error({
                                  title: '교환 거부(철회) 사유',
                                  content: '교환 거부(철회) 사유 실패했습니다.',
                                  centered: true,
                                  okText: '확인',
                                });
                              });
                          }}
                        >
                          <Space direction="vertical" size="middle" style={{ width: '100%' }}>
                            <Form.Item name="reason">
                              <Input.TextArea style={{ marginBlock: 10 }} />
                            </Form.Item>
                            <Typography.Paragraph>
                              * 교환 거부(철회)처리 후에는 되돌릴 수 없습니다.
                            </Typography.Paragraph>
                            <Row justify="end" style={{ marginBottom: -25 }} gutter={10}>
                              <Col>
                                <Button type="primary" htmlType="submit">
                                  확인
                                </Button>
                              </Col>
                              <Col>
                                <Button
                                  onClick={() => {
                                    Modal.destroyAll();
                                  }}
                                >
                                  취소
                                </Button>
                              </Col>
                            </Row>
                          </Space>
                        </Form>
                      ),
                      okButtonProps: { style: { display: 'none' } },
                      centered: true,
                    });
                  } else {
                    Modal.warn({
                      title: '교환 거부(철회) 사유',
                      content:
                        '선택하신 주문 건은 교환 거부(철회)처리가 불가합니다. 교환 거부처리는 처리상태가 “교환요청/수거중/수거완료”인 주문건만 처리 가능합니다. 교환 처리상태를 확인해 주세요.',
                      centered: true,
                    });
                  }
                }
                // 선택된 값이 1개도 없을경우
                else {
                  Modal.warn({
                    title: '교환 거부(철회) 사유',
                    content: '처리할 상품주문번호를 먼저 선택해주세요.',
                    centered: true,
                  });
                }
              }}
            >
              교환 거부(철회)처리
            </Button>
            <Button
              disabled={
                !selectedRowKeys.length ||
                !checkOrderStatusCodeRowKeys(
                  ORDER_STATUS_TYPE.OPS009,
                  ORDER_STATUS_TYPE.OPS012,
                  ORDER_STATUS_TYPE.OPS013,
                )
              }
              onClick={() => {
                if (selectedRowKeys.length > 0) {
                  if (
                    checkOrderStatusCodeRowKeys(
                      ORDER_STATUS_TYPE.OPS009,
                      ORDER_STATUS_TYPE.OPS012,
                      ORDER_STATUS_TYPE.OPS013,
                    )
                  ) {
                    Modal.confirm({
                      title: '반품 처리',
                      width: 650,
                      content: (
                        <Typography.Text>
                          반품으로 변경 시 결제내역이 취소처리가 됩니다. 선택한 내역을 반품으로 변경하시겠습니까?
                        </Typography.Text>
                      ),
                      onOk: () => {
                        const promiseArr = [];
                        for (let changeId of selectedRowKeys) {
                          promiseArr.push(restApi.put(`/partners/account-orders/change-order/${changeId}/return`));
                        }

                        Promise.all(promiseArr)
                          .then(() => {
                            handleRefresh();

                            Modal.destroyAll();
                            Modal.success({
                              title: '반품 처리',
                              content: '반품 처리 되었습니다.',
                              centered: true,
                              okText: '확인',
                            });
                          })
                          .catch((e) => {
                            handleError(e);
                            Modal.error({
                              title: '반품 처리',
                              content: '반품 처리 실패했습니다.',
                              centered: true,
                              okText: '확인',
                            });
                          });
                      },
                      okText: '확인',
                      cancelText: '취소',
                      centered: true,
                    });
                  } else {
                    Modal.warn({
                      title: '반품 처리',
                      content:
                        '선택하신 주문 건은 반품완료 처리가 불가합니다. 반품완료는 교환 처리상태가 “교환요청/반품중”인 경우에만 반품완료 처리가 가능합니다. 교환 처리상태를 확인해 주세요.',
                      centered: true,
                    });
                  }
                } else {
                  Modal.warn({
                    title: '반품 처리',
                    content: '처리할 상품주문번호를 먼저 선택해주세요.',
                    centered: true,
                  });
                }
              }}
            >
              반품으로 변경
            </Button>
          </Space>
        </Descriptions.Item>
        <Descriptions.Item label="정보수정" labelStyle={{ width: 140, alignItems: 'center' }}>
          <Space>
            <Button
              disabled={!selectedRowKeys.length || !checkOrderStatusCodeRowKeys()}
              onClick={() => {
                Modal.info({
                  title: '수거지 정보 수정',
                  width: 650,
                  content: <ChangePlaceToCollect changeIds={selectedRowKeys} onSubmit={handleRefresh} />,
                  okButtonProps: { style: { display: 'none' } },
                  centered: true,
                });
              }}
            >
              수거 정보 수정
            </Button>
            <Button
              disabled={!selectedRowKeys.length || !checkOrderStatusCodeRowKeys()}
              onClick={() => {
                if (selectedRowKeys.length > 0) {
                  Modal.info({
                    title: '재배송 송장 수정',
                    width: 650,
                    content: (
                      <ChangeInvoice
                        modalTitle="재배송 송장 수정"
                        changeIds={selectedRowKeys}
                        codesTree={codesTree}
                        onRefresh={handleRefresh}
                      />
                    ),
                    okButtonProps: { style: { display: 'none' } },
                    centered: true,
                  });
                } else {
                  Modal.warn({
                    title: '송장 수정',
                    content: '처리할 상품주문번호를 먼저 선택해주세요.',
                    centered: true,
                  });
                }
              }}
            >
              재배송 송장 수정
            </Button>
          </Space>
        </Descriptions.Item>
      </Descriptions>
    </Space>
  );
}

export default SalesExchange;
