import React from 'react';
import { Button, Space, Descriptions, Input, Modal } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import classNames from 'classnames/bind';
import styles from '../../Page.module.less';
import { restApi } from '#apis';
import handleError from '#utils/handleError';
import { useReduxStore } from '#hooks/useReduxStore';
import moment from 'moment';
import ShowOrderDetailBtn from '#components/Buttons/ShowOrderDetailBtn';
import ProductDetails from '#components/App/ProductDetails';

const cx = classNames.bind(styles);

function ReviewGoodsDetail() {
  const { map: codeMap } = useReduxStore('codes');
  const navigate = useNavigate();
  const { inquireId } = useParams();

  const [inquireInfo, setInquireInfo] = React.useState(null);
  const [answer, setAnswer] = React.useState('');
  const [saving, setSaving] = React.useState(false);

  const handleSave = async () => {
    if (saving) return;
    if (answer.length < 1) {
      Modal.warning({
        content: '답변을 입력해주세요.',
      });
      return;
    }
    try {
      setSaving(true);
      await restApi.put(`/partners/product-questions/${inquireId}`, {
        answer,
      });
      init();
      Modal.success({
        content: '저장을 완료했습니다.',
      });
      setSaving(false);
    } catch (e) {
      setSaving(false);
    }
  };

  const init = React.useCallback(() => {
    restApi
      .get(`/partners/product-questions/${inquireId}`)
      .then(({ data }) => {
        const { answer, ...restData } = data;
        if (answer) {
          setAnswer(answer);
        }
        setInquireInfo(restData);
      })
      .catch(handleError);
  }, [inquireId]);

  React.useEffect(() => {
    if (!inquireId) return;
    init();
  }, [inquireId, init]);

  return (
    <div>
      <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
        <Descriptions bordered>
          <Descriptions.Item span={3} labelStyle={{ width: 140 }} label="문의분야">
            {codeMap?.[inquireInfo?.typeCode]}
          </Descriptions.Item>
          <Descriptions.Item span={3} labelStyle={{ width: 140 }} label="상품명">
            {inquireInfo?.productName}
            {!!inquireInfo?.productId && (
              <Button
                onClick={() => {
                  Modal.info({
                    title: '상세설명',
                    width: 550,
                    content: <ProductDetails productId={inquireInfo.productId} />,
                    centered: true,
                    closable: true,
                  });
                }}
                style={{ marginLeft: 20 }}
              >
                상품정보
              </Button>
            )}
          </Descriptions.Item>
          {!!inquireInfo?.orderProductNumber && (
            <Descriptions.Item span={3} labelStyle={{ width: 140 }} label="주문번호">
              {inquireInfo?.orderProductNumber}
              {!!inquireInfo?.orderProductNumber && (
                <ShowOrderDetailBtn orderId={inquireInfo.orderProductNumber} title="주문 상세 보기" type="primary" />
              )}
            </Descriptions.Item>
          )}
          <Descriptions.Item span={3} labelStyle={{ width: 140 }} label="회원아이디">
            {inquireInfo?.userId}
          </Descriptions.Item>
          <Descriptions.Item span={3} labelStyle={{ width: 140 }} label="회원이름">
            {inquireInfo?.userName}
          </Descriptions.Item>
          <Descriptions.Item span={3} labelStyle={{ width: 140 }} label="문의내용">
            {inquireInfo?.contents}
          </Descriptions.Item>
          <Descriptions.Item span={3} labelStyle={{ width: 140 }} label="공개여부">
            {typeof inquireInfo?.isSecret === 'boolean' && (inquireInfo?.isSecret ? '비공개' : '공개')}
            {inquireInfo?.isSecret && inquireInfo?.adminName && inquireInfo?.secretAt && (
              <span>
                ({inquireInfo?.adminName} / {inquireInfo?.secretAt})
              </span>
            )}
          </Descriptions.Item>
        </Descriptions>
        <Descriptions bordered>
          <Descriptions.Item span={3} labelStyle={{ width: 140 }} label="답변내용">
            <Input
              placeholder="답변을 입력하세요."
              value={answer}
              onChange={(event) => setAnswer(event.target.value)}
            />
          </Descriptions.Item>
          <Descriptions.Item span={3} labelStyle={{ width: 140 }} label="답변일">
            {inquireInfo?.answerAt ? moment(inquireInfo?.answerAt).format('YYYY-MM-DD / HH:mm:ss') : ''}
          </Descriptions.Item>
          <Descriptions.Item span={3} labelStyle={{ width: 140 }} label="답변자">
            {inquireInfo?.adminId ? `${inquireInfo?.adminId} / ` : ''} {inquireInfo?.adminName}
          </Descriptions.Item>
        </Descriptions>
      </Space>
      <div className={cx({ buttonBox: true })}>
        <Button onClick={() => navigate(-1)}>목록</Button>
        <Button disabled={saving} onClick={handleSave}>
          저장
        </Button>
      </div>
    </div>
  );
}

export default ReviewGoodsDetail;
