import React from 'react';
import { Button, Descriptions, List, Modal, Space, Typography } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import classNames from 'classnames/bind';
import styles from '../../Page.module.less';
import parseImageUrl from '#utils/parseImageUrl';
import handleError from '#utils/handleError';
import formatComma from '#utils/formatComma';
import { restApi } from '#apis/index';
import StoretDetails from '../../../components/App/StoreDetails';
import { useReduxStore } from '../../../hooks/useReduxStore';

const cx = classNames.bind(styles);

function PartnersStoreDetailsIndex() {
  const navigate = useNavigate();
  const { principal } = useReduxStore('auth');
  const [store, setStore] = React.useState(null);

  const dateObj = React.useMemo(
    () => ({
      MON: '월요일',
      TUE: '화요일',
      WED: '수요일',
      THU: '목요일',
      FRI: '금요일',
      SAT: '토요일',
      SUN: '일요일',
    }),
    [],
  );

  React.useEffect(() => {
    restApi
      .get(`/partners/stores`)
      .then((response) => setStore(response.data))
      .catch(handleError);
  }, []);

  const sectionTitle = principal?.writeInfo ? '수정' : '등록';

  return (
    <div>
      <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
        <Descriptions bordered>
          <Descriptions.Item span={3} labelStyle={{ width: 175 }} label="상점명">
            {store?.storeName || '-'}
          </Descriptions.Item>
          <Descriptions.Item span={3} labelStyle={{ width: 175, verticalAlign: 'middle' }} label="상점로고">
            {typeof store?.logo === 'number' && (
              <img src={parseImageUrl(store?.logo)} style={{ width: 160, height: 160 }} alt="상점로고" />
            )}
            {store?.youtubeUrl && (
                <p>{store?.youtubeUrl}</p>
            )}
          </Descriptions.Item>
          <Descriptions.Item span={3} labelStyle={{ width: 175 }} label="상점주소">
            {store?.addrMap} {store?.addrDetail}
          </Descriptions.Item>
          <Descriptions.Item span={3} labelStyle={{ width: 175 }} label="전화번호">
            {store?.phone}
          </Descriptions.Item>
          <Descriptions.Item span={3} labelStyle={{ width: 175 }} label="운영시간/휴무일">
            <List
              dataSource={
                store
                  ? [
                      ...store?.openInfos?.map(
                        ({ closeAt, day, isOpen, openAt }) =>
                          `${dateObj[day]} ${isOpen ? `${openAt} ~ ${closeAt}` : '휴무'}`,
                      ),
                      ...(store?.holiday ? [`휴무일 ${store?.holiday}`] : []),
                    ]
                  : []
              }
              rowKey={(_, index) => index}
              renderItem={(item) => (
                <>
                  - {item}
                  <br />
                </>
              )}
            />
          </Descriptions.Item>
          <Descriptions.Item span={3} labelStyle={{ width: 175 }} label="상점소개">
            {store?.introduction}
          </Descriptions.Item>
          <Descriptions.Item span={3} labelStyle={{ width: 175, verticalAlign: 'middle' }} label="상점사진">
            <Space>
              {store?.storeImages?.map((value, index) => {
                if(value.imageId <0&&value.youtubeUrl) return (
                    <p>{value.youtubeUrl}</p>
                )
                return (
                  <img
                    style={{ maxWidth: 200, maxHeight: 200, width: 100, height: 100 }}
                    key={`Store-Image-${index}`}
                    src={parseImageUrl(value.imageId)}
                    alt="상점사진"
                  />
                );
              })}
            </Space>
          </Descriptions.Item>

          <Descriptions.Item span={3} labelStyle={{ width: 175 }} label="등록상품수">
            <Space>
              <Typography.Text>{formatComma(store?.productCount)}개</Typography.Text>
              <Link to={`/goods/list`}>
                <Button>상품보기</Button>
              </Link>
            </Space>
          </Descriptions.Item>
        </Descriptions>
      </Space>
      <div className={cx({ buttonBox: true })}>
        <Button
          type="primary"
          onClick={() => {
            if (!(typeof store?.storeId === 'number')) return;
            Modal.info({
              title: '상세설명',
              width: 550,
              content: <StoretDetails storeId={store.storeId} />,
              centered: true,
              closable: true,
            });
          }}
        >
          상세화면
        </Button>
        <Button onClick={() => navigate(`/store/modify`)} type="primary">
          {sectionTitle}
        </Button>
      </div>
    </div>
  );
}

export default PartnersStoreDetailsIndex;
