import { Space } from 'antd';
import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { restApi } from '#apis';
import Chart from 'react-apexcharts';

const AveragePaymentAmountByDayOfTheWeek = () => {
  const { data, isLoading, error } = useQuery(
    ['average_payment_amount_by_day_of_the_week'],
    async () => {
      const { data } = await restApi.get('/statistics/day-of-week/amount/avg');
      return data;
    },
    {
      refetchInterval: 1000,
    },
  );

  return (
    <Space style={{ width: '100%', maxWidth: 800 }} direction="vertical">
      <Chart
        options={{
          // chart: {
          //   id: 'basic-bar',
          // },
          xaxis: {
            categories: ['월', '화', '수', '목', '금', '토', '일'],
            labels: {
              show: true,
            },
          },
        }}
        series={[
          {
            name: '평균결제금액',
            data: [
              data?.monAmount,
              data?.tueAmount,
              data?.wedAmount,
              data?.thrAmount,
              data?.friAmount,
              data?.satAmount,
              data?.sunAmount,
            ],
          },
        ]}
        type="bar"
        width="800"
      />
    </Space>
  );
};

export default AveragePaymentAmountByDayOfTheWeek;
