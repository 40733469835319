import React from 'react';
import { Typography, Checkbox, Button, Modal, Space, Spin } from 'antd';
import classNames from 'classnames/bind';
import styles from './Step.module.less';
import { useNavigate } from 'react-router-dom';
import { restApi } from '../../../apis';

const cx = classNames.bind(styles);

const { Text, Title } = Typography;

function FirstStep({ handleMoveStep }) {
  const navigate = useNavigate();
  const [isAgree, setIsAgree] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const [agreement, setAgreement] = React.useState(null);
  const handleClickAgree = (event) => {
    setIsAgree(event.target.checked);
  };

  const handleGoToNext = () => {
    if (isAgree) {
      handleMoveStep(1);
      return;
    }

    Modal.warning({
      content: '이용약관에 동의해주세요.',
      centered: true,
    });
  };

  React.useEffect(() => {
    restApi
      .get('agreement/TCT001')
      .then(({ data }) => {
        setAgreement(data?.contents);
        setLoading(false);
      })
      .catch((e) => {
        Modal.error({
          content: '에러가 발생했습니다. 잠시후 시도해주세요.',
          onOk: () => {
            navigate('/', {
              replace: true,
            });
          },
          okText: '확인',
        });
      });
  }, []);

  if (loading) return <Spin />;

  return (
    <div className={cx({ wrapper: true })}>
      <Space direction="vertical" align="stretch" style={{ width: '100%' }}>
        <Title style={{ textAlign: 'center' }} level={5}>
          파트너 신청 약관
        </Title>
        {!!agreement && (
          <div
            style={{
              borderStyle: 'solid',
              borderColor: '#eee',
              borderWidth: 1,
              borderRadius: 5,
              padding: 15,
              marginInline: 'auto',
              maxHeight: 500,
              overflowY: 'scroll',
              width:'100%',
              maxWidth:500,
              height:300,
            }}
          >
            <div style={{ fontSize:13, lineHeight:2, }} dangerouslySetInnerHTML={{ __html: agreement }}></div>
          </div>
        )}

        <div>
          <Checkbox checked={isAgree} onChange={handleClickAgree}>
            이용약관에 동의합니다.
          </Checkbox>
        </div>
        <div className={cx({ bottomBtnWrapper: true })} style={{ display: 'flex' }}>
          <Button
            onClick={() => {
              navigate('/login');
            }}
            className={cx({ defaultBtn: true })}
          >
            로그인 화면 가기
          </Button>
          <Button className={cx({ defaultBtn: true })} type="primary" onClick={handleGoToNext}>
            다음 단계
          </Button>
        </div>
      </Space>
    </div>
  );
}

export default FirstStep;
