import { restApi } from '#apis/index';
import handleError from '#utils/handleError';
import produce from 'immer';
import React from 'react';
import { Button, Space, Select, Row, Col, Form, Table, DatePicker } from 'antd';
import useFetch from '#hooks/useFetch';
import moment from 'moment';

const AdStatusType = {
  submit: 'SUBMIT',
  on: 'ADON',
  off: 'ADOFF',
};

const DateType = {
  start: 'START',
  end: 'END',
  create: 'CREATE',
};

const URL = '/partners/advertisement/my';

function AdvertiseHistories() {
  const initialParams = React.useMemo(
    () => ({
      adStatusType: null,
      dateType: DateType.start,
      startAt: null,
      endAt: null,
      page: 0,
      limit: 10,
    }),
    [],
  );

  const [params, setParams] = React.useState(initialParams);
  const tableRef = React.useRef();
  const areaCodes = useFetch();
  const areaCodes2 = useFetch();

  const tableFetcher = useFetch();

  const fetchTableData = () => {
    tableFetcher.fetch(restApi.get(URL, { params })).catch(handleError);
  };

  // init 할때만 실행
  React.useEffect(() => {
    areaCodes.fetch(restApi.get('/area-codes/filter')).catch(handleError);
    fetchTableData();
  }, []);

  return (
    <Space direction="vertical" style={{ width: '100%' }}>
      <Form
        onFinish={fetchTableData}
        onReset={() => {
          setParams(initialParams);
          return initialParams;
        }}
      >
        <Row gutter={10} style={{ marginBottom: 10, alignItems: 'center', rowGap: 10 }}>
          <Col>
            <Select
              value={params.extra1}
              loading={areaCodes.loading || areaCodes2.loading}
              onChange={async (value) => {
                await areaCodes2.fetch(restApi.get(`/area-codes/filter/${value}`));
                setParams(
                  produce((draft) => {
                    draft.extra1 = value;
                    draft.extra2 = null;
                  }),
                );
              }}
              options={areaCodes.data?.map((area) => ({ label: area, value: area })) ?? []}
              style={{ width: 200 }}
              disabled={!(areaCodes.data?.length > 0)}
              placeholder="시/도"
              allowClear
            />
          </Col>
          <Col>
            <Select
              value={params.extra2}
              loading={areaCodes2.loading}
              onChange={(value) => {
                setParams(
                  produce((draft) => {
                    draft.extra2 = value;
                  }),
                );
              }}
              options={areaCodes2.data?.map((area) => ({ label: area, value: area })) ?? []}
              style={{ width: 200 }}
              disabled={!(areaCodes2.data?.length > 0)}
              placeholder="시/군/구"
              allowClear
            />
          </Col>
          <Col>
            <Select
              value={params.adStatusType}
              placeholder="광고 상태 옵션"
              onChange={(value) => {
                setParams(
                  produce((draft) => {
                    draft.adStatusType = value;
                  }),
                );
              }}
              options={[
                { label: '광고 중지', value: AdStatusType.off },
                { label: '광고 진행중', value: AdStatusType.on },
                { label: '광고 신청중', value: AdStatusType.submit },
              ]}
              style={{ width: 150 }}
            />
          </Col>
          <Col>
            <Space style={{ width: '100%' }}>
              <Select
                value={params.dateType}
                onChange={(value) => {
                  setParams(
                    produce((draft) => {
                      draft.dateType = value;
                    }),
                  );
                }}
                defaultValue="CREATED_AT"
                options={[
                  { label: '시작일', value: DateType.start },
                  { label: '종료일', value: DateType.end },
                  { label: '광고신청일', value: DateType.create },
                ]}
                style={{ width: 120 }}
              />
              <DatePicker.RangePicker
                value={[params.startAt ? moment(params.startAt) : null, params.endAt ? moment(params.endAt) : null]}
                onChange={([startAt, endAt]) => {
                  setParams(
                    produce((draft) => {
                      draft.startAt = startAt.format('YYYY-MM-DD');
                      draft.endAt = endAt.format('YYYY-MM-DD');
                    }),
                  );
                }}
              />
            </Space>
          </Col>

          <Col>
            <Space>
              <Button type="primary" htmlType="submit">
                검색
              </Button>
              <Button htmlType="reset">초기화</Button>
            </Space>
          </Col>
        </Row>
      </Form>
      <Table
        dataSource={tableFetcher?.data?.results || []}
        ref={tableRef}
        columns={[
          {
            title: '지역',
            dataIndex: 'extra1',
            fixed: true,
            width: 200,
            render: (value, row) => `${value} > ${row?.extra2 || ''}`,
          },
          {
            title: '시작일',
            dataIndex: 'startAt',
            fixed: true,
            width: 100,
            render: (value) => value,
          },
          {
            title: '기간',
            dataIndex: 'period',
            width: 200,
            render: (period) => period,
          },
          {
            title: '종료일',
            dataIndex: 'endAt',
            width: 200,
            render: (endAt) => endAt,
          },
          {
            title: '광고신청일',
            dataIndex: 'createdAt',
            width: 200,
            render: (createdAt) => createdAt,
          },
          {
            title: '상태',
            dataIndex: 'adStatusType',
            width: 100,
            render: (adStatusType) => {
              switch (adStatusType) {
                case AdStatusType.submit:
                  return '신청중';
                case AdStatusType.on:
                  return '광고중';
                case AdStatusType.off:
                  return '광고 중지';
                default:
                  return;
              }
            },
          },
        ]}
        scroll={{ x: 1000 }}
        pagination={{
          position: ['bottomCenter'],
          total: tableFetcher?.data?.total,
          pageSize: tableFetcher?.data?.limit ?? 10,
          current: tableFetcher?.data?.pageCur + 1,
          showSizeChanger: false,
          onChange: async (p) => {
            setParams(
              produce((draft) => {
                draft.page = p - 1;
              }),
            );
            tableFetcher.fetch(
              restApi.get(URL, {
                params: { ...params, page: p - 1 },
              }),
            );
          },
        }}
      />
    </Space>
  );
}

export default AdvertiseHistories;
