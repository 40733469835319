import React from 'react';
import { Button, Col, DatePicker, Divider, Input, Row, Select, Space } from 'antd';
import { Link } from 'react-router-dom';
import SearchForm from '#components/SearchForm';
import CommTable from '#components/table/CommTable';
import { useReduxStore } from '#hooks/useReduxStore';
import { restApi } from '#apis';
import produce from 'immer';
import moment from 'moment';
import useIndex from '#hooks/useIndex';

function InquireGoods() {
  const { tree, map } = useReduxStore('codes');
  const { getIndex } = useIndex();
  const [contactCode, setContactCode] = React.useState([]);

  const initailParams = React.useMemo(
    () => ({
      dateType: null,
      startAt: null,
      endAt: null,
      isSecret: null,
      statusCode: null,
      questionTypeCode: null,
      searchType: null,
      keyword: null,
    }),
    [],
  );

  const [params, setParams] = React.useState(initailParams);

  // 문의하기 코드 받아오기
  React.useEffect(() => {
    restApi
      .get('/cms/product-questions/type')
      .then((response) => {
        const formatArray = response.data?.map((value) => ({ label: value.label, value: value.code }));

        setContactCode(formatArray);
      })
      .catch((e) => {
        console.warn('error in /partners/product-question/type : ', e);
      });
  }, []);

  return (
    <div>
      <SearchForm
        params={params}
        onInit={setParams}
        onReset={() => {
          setParams(initailParams);
          return initailParams;
        }}
      >
        <Row gutter={[50, 15]}>
          <Col span={24}>
            <Space style={{ width: '100%' }}>
              <Select
                value={params.dateType}
                onChange={(value) => {
                  setParams(
                    produce((draft) => {
                      draft.dateType = value;
                    }),
                  );
                }}
                options={[
                  { label: '등록일', value: 'CREATED_AT' },
                  { label: '답변일', value: 'ANSWER_AT' },
                ]}
                placeholder="날짜옵션"
                style={{ width: 120 }}
              />
              <DatePicker.RangePicker
                disabled={!params.dateType}
                value={[params.startAt ? moment(params.startAt) : null, params.endAt ? moment(params.endAt) : null]}
                onChange={([startAt, endAt]) => {
                  setParams(
                    produce((draft) => {
                      draft.startAt = startAt.format('YYYY-MM-DD');
                      draft.endAt = endAt.format('YYYY-MM-DD');
                    }),
                  );
                }}
              />
              <Select
                value={params.isSecret}
                onChange={(value) => {
                  setParams(
                    produce((draft) => {
                      draft.isSecret = value;
                    }),
                  );
                }}
                options={[
                  { label: '공개', value: 'true' },
                  { label: '비공개', value: 'false' },
                ]}
                placeholder="공개여부"
                style={{ width: 120 }}
              />
              <Select
                value={params.statusCode}
                onChange={(value) => {
                  setParams(
                    produce((draft) => {
                      draft.statusCode = value;
                    }),
                  );
                }}
                options={tree?.AS.items.map((value) => ({ label: value.label, value: value.code })) || []}
                placeholder="답변여부"
                style={{ width: 120 }}
              />
            </Space>
          </Col>
          <Col span={24}>
            <Space style={{ width: '100%' }}>
              <Select
                placeholder="문의분야"
                style={{ width: 120 }}
                value={params.questionTypeCode}
                onChange={(value) => {
                  setParams(
                    produce((draft) => {
                      draft.questionTypeCode = value;
                    }),
                  );
                }}
                options={contactCode || []}
              />
              <Select
                placeholder="검색 옵션"
                style={{ width: 120 }}
                value={params.searchType}
                onChange={(value) => {
                  setParams(
                    produce((draft) => {
                      draft.searchType = value;
                    }),
                  );
                }}
                options={[
                  { label: '문의내용', value: 'CONTENT' },
                  { label: '상점명', value: 'STORE_NAME' },
                  { label: '상품명', value: 'PRODUCT_NAME' },
                  { label: '회원아이디', value: 'USER_ID' },
                  { label: '회원 이름', value: 'USER_NAME' },
                  { label: '상점 아이디', value: 'STORE_USERID' },
                  { label: '주문번호', value: 'ORDER_NO' },
                ]}
              />
              <Input
                placeholder="키워드를 입력하세요"
                style={{ width: 300 }}
                value={params.keyword}
                onChange={(event) => {
                  setParams(
                    produce((draft) => {
                      draft.keyword = event.target.value;
                    }),
                  );
                }}
              />
            </Space>
          </Col>
          <Col span={24}>
            <Row justify="end">
              <Col>
                <Space>
                  <Button type="primary" htmlType="submit">
                    검색
                  </Button>
                  <Button htmlType="reset">초기화</Button>
                </Space>
              </Col>
            </Row>
          </Col>
        </Row>
      </SearchForm>
      <Divider />
      <CommTable
        toggleObj={{ text: '주문 상품 문의글만', key: 'isProdOnly' }}
        url="/partners/product-questions"
        columns={[
          {
            title: '번호',
            dataIndex: 'orderNum',
            key: 'orderNum',
            width: 100,
            render: (_, __, index) => getIndex(index),
          },
          {
            title: '문의분야',
            dataIndex: 'typeCode',
            key: 'typeCode',
            width: 150,
            render: (typeCode) => {
              return map[typeCode];
            },
          },
          {
            title: '문의내용',
            dataIndex: 'contents',
            key: 'contents',
            width: 200,
            render: (contents, obj) => <Link to={`/review/goods/inquire/${obj.id}`}>{contents || '-'}</Link>,
          },
          {
            title: '상품명',
            dataIndex: 'productName',
            key: 'productName',
            width: 200,
            render: (productName) => productName || '-',
          },
          {
            title: '주문번호',
            dataIndex: 'orderProductNumber',
            width: 100,
            key: 'orderProductNumber',
            render: (orderProductNumber, obj) => orderProductNumber || '-',
          },
          {
            title: '회원아이디',
            dataIndex: 'userId',
            key: 'userId',
            width: 200,
            render: (userId) => userId || '-',
          },
          {
            title: '회원이름',
            dataIndex: 'userName',
            key: 'userName',
            width: 200,
            render: (userName) => userName || '-',
          },
          {
            title: '등록일',
            dataIndex: 'createdAt',
            key: 'createdAt',
            width: 200,
            render: (createdAt) => createdAt && moment(createdAt).format('YYYY-MM-DD HH:mm'),
          },
          {
            title: '공개여부',
            dataIndex: 'isSecret',
            key: 'isSecret',
            width: 100,
            render: (value) => (value ? '공개' : '비공개'),
          },
          {
            title: '답변여부',
            dataIndex: 'answerStateCode',
            key: 'answerStateCode',
            width: 200,
            render: (answerStateCode) => map[answerStateCode] || '-',
          },
          {
            title: '답변일',
            dataIndex: 'answerAt',
            key: 'answerAt',
            width: 200,
            render: (answerAt) => answerAt && moment(answerAt).format('YYYY-MM-DD HH:mm'),
          },
        ]}
        scroll={{ x: 1120 }}
      />
    </div>
  );
}

export default InquireGoods;
