import React from 'react';
import { Button, Form, Input, Modal, Space } from 'antd';
import { useNavigate, useLocation } from 'react-router-dom';
import handleError from '#utils/handleError';
import RegexObj from '#utils/regex';
import { restApi } from '../../../apis';
import qs from 'query-string';

const SecondStep = ({ genKey }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const [loading, setLoading] = React.useState(false);

  const [password, setPassword] = React.useState('');
  const [confirmPassword, setConfirmPassword] = React.useState('');

  const handleSubmitNewPassword = async () => {
    try {
      if (!password) {
        alert('새로운 비밀번호를 입력해주세요');
        return;
      }

      if (!RegexObj.password.test(password)) {
        return alert('비밀번호는 6~16자의 영문 대소문자, 숫자, 특수문자만 가능합니다. ');
      }

      setLoading(true);
      await restApi.post('/partners/accounts/change/pwd', {
        genKey,
        pwd: password,
      });

      setLoading(false);
      Modal.success({
        content: '비밀번호 변경에 성공했습니다.',
        onOk: () => {
          navigate('/login');
        },
        centered: true,
      });
    } catch (e) {
      handleError(e);
      setLoading(false);
      Modal.error({
        content: '비밀번호 변경에 실패했습니다. 관리자에 문의 해주세요.',
        centered: true,
      });
    }
  };

  const canClick = confirmPassword.length > 0 && password === confirmPassword;

  return (
    <>
      <h4>비밀번호 재설정</h4>
      <Space direction="vertical" align="center" style={{ width: '100%', marginTop: 30 }}>
        <Form
          name="basic"
          labelCol={{ span: 3 }}
          wrapperCol={{ span: 16 }}
          initialValues={{ remember: true }}
          autoComplete="off"
        >
          <Space size="middle" direction="vertical" style={{ width: '100%' }}>
            <Form.Item label="비밀번호" name="password" required>
              <Input.Password
                disabled={loading}
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
                placeholder="영문대소문자와 !@#$%^&*()의 특수문자로 이루어진 6~16 사이"
                value={password}
                style={{ width: 400, marginRight: 10 }}
              />
            </Form.Item>
            <Form.Item
              label="비밀번호 확인"
              name="passwordConfirm"
              rules={[
                { required: true, message: '비밀번호를 확인 해주세요.' },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('password') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error('비밀번호가 일치하지 않습니다.'));
                  },
                }),
              ]}
            >
              <Input.Password
                autoComplete="new-password"
                value={confirmPassword}
                disabled={loading}
                onChange={(e) => {
                  setConfirmPassword(e.target.value);
                }}
                style={{ width: 400, marginRight: 10 }}
              />
            </Form.Item>
          </Space>
        </Form>
      </Space>
      <div style={{ display: 'flex', marginTop: 50, alignItems: 'center' }}>
        <Button
          onClick={() => {
            navigate('/login');
          }}
          type="ghost"
          style={{ flex: 1 }}
        >
          로그인 화면
        </Button>
        <div style={{ width: 20 }} />
        <Button
          htmlType="submit"
          disabled={!canClick}
          onClick={handleSubmitNewPassword}
          type="primary"
          style={{ flex: 1 }}
        >
          비밀번호 재설정
        </Button>
      </div>
    </>
  );
};

export default SecondStep;
