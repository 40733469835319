import React from 'react';
import classNames from 'classnames/bind';
import styles from '../User.module.less';
import FirstStep from '#components/partners/find_pw/FirstStep';
import SecondStep from '#components/partners/find_pw/SecondStep';

const cx = classNames.bind(styles);

function PartnerFindPassword() {
  const [nowStep, setNowStep] = React.useState(0);
  const [genKey, setGenKey] = React.useState('');

  return (
    <div className={cx({ wrapper: true, finder: true })}>
      {nowStep === 0 && (
        <FirstStep
          onFinishFindPw={(genKey) => {
            setNowStep(1);
            setGenKey(genKey);
          }}
        />
      )}
      {nowStep === 1 && <SecondStep genKey={genKey} />}
    </div>
  );
}

export default PartnerFindPassword;
