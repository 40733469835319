import { useMemo } from 'react';
import {
  HomeOutlined,
  FundFilled,
  GiftOutlined,
  MessageOutlined,
  DollarCircleOutlined,
  ShareAltOutlined,
  SmileTwoTone,
  BarChartOutlined,
} from '@ant-design/icons';
import HomeIndex from '#pages';
import Join from '#pages/user/join';

// sales
import SalesTotalOrder from '#pages/sales/total_order';
import SalesDelivery from '#pages/sales/delivery';
import SalesExchange from '#pages/sales/exchange';
import SalesRefund from '#pages/sales/refund';
import SalesCancel from '#pages/sales/cancel';
import SalesRequestEstimate from '#pages/sales/request_estimate';
import SalesCalculate from '#pages/sales/calculate';
import RequestEstimateDetail from '#pages/sales/request_estimate/detail';

// goods
import GoodsItemsIndex from '#pages/goods/items';
import GoodsItemsCreate from '#pages/goods/items/create';
import GoodsItemsDetail from '#pages/goods/items/detail';
import GoodsDeliveryFee from '#pages/goods/deliveryfee';
import ChargeTemplateCreate from '#pages/goods/deliveryfee/create';

// store
import StoreDetailsIndex from '#pages/store/detail';
import StoreDetailsModify from '#pages/store/detail/modify';
import StoreAuthIndex from '#pages/store/auth';
import StoreAccountInfoIndex from '#pages/store/account';
import StoreAccountInfoModify from '#pages/store/account/modify';

// user
import Login from '#pages/user';
import FindId from '#pages/user/find_id';
import FindPassword from '#pages/user/find_pw';

// review
import ReviewManagement from '#pages/review/management';
import InquireStore from '#pages/review/store';
import InquireGoods from '#pages/review/goods';
import ReviewDetail from '#pages/review/management/detail';
import InquireStoreDetail from '#pages/review/store/detail';
import InquireGoodsDetail from '#pages/review/goods/detail';

// notice
import Notice from '#pages/notice';
import NoticeDetails from '#pages/notice/detail';

// advertise
import Advertise from '#pages/ad';
import CreateAdvertisement from '#pages/ad/create';

// statistics
import DailyPaymentAmount from '#pages/statistics/daily_payment_amount';
import PayersAndPaymentsPerDay from '#pages/statistics/payers_payments_per_day';
import PaymentAmountByDayOfTheWeek from '#pages/statistics/payment_amount_by_day_of_the_week';
import AveragePaymentAmountByDayOfTheWeek from '#pages/statistics/average_payment_amount_by_day_of_the_week';
import DailyRefundRate from '#pages/statistics/daily_refund_rate';

export const notLoginPages = [
  {
    path: '/login',
    title: '로그인',
    screen: Login,
  },
  {
    path: '/join',
    title: '회원가입',
    screen: Join,
  },

  {
    path: '/find_id',
    title: '아이디 찾기',
    screen: FindId,
  },
  {
    path: '/find_pw',
    title: '비밀번호 재설정',
    screen: FindPassword,
  },
];

export const pages = [
  {
    path: '/',
    title: '파트너스 메인',
    icon: FundFilled,
    screen: HomeIndex,
    isMenu: true,
  },
  {
    path: '/sales',
    title: '판매관리',
    icon: DollarCircleOutlined,
    screen: HomeIndex,
    isMenu: true,
  },
  {
    path: '/sales/total_order',
    title: '주문통합관리',
    screen: SalesTotalOrder,
    isMenu: true,
  },
  {
    path: '/sales/deliver',
    title: '발주/배송관리',
    screen: SalesDelivery,
    isMenu: true,
  },
  {
    path: '/sales/exchange',
    title: '교환신청',
    screen: SalesExchange,
    isMenu: true,
  },
  {
    path: '/sales/refund',
    title: '반품신청',
    screen: SalesRefund,
    isMenu: true,
  },
  {
    path: '/sales/cancel',
    title: '취소신청',
    screen: SalesCancel,
    isMenu: true,
  },
  {
    path: '/sales/request_estimate',
    title: '견적의뢰관리',
    screen: SalesRequestEstimate,
    isMenu: true,
  },
  {
    path: '/sales/request_estimate/:estimateId',
    title: '견적의뢰관리 상세',
    screen: RequestEstimateDetail,
  },
  {
    path: '/sales/calculate',
    title: '정산관리',
    screen: SalesCalculate,
    isMenu: true,
  },
  {
    path: '/goods',
    title: '상품관리',
    isMenu: true,
    icon: GiftOutlined,
  },
  {
    path: '/goods/list',
    title: '상품 조회/수정',
    isMenu: true,
    screen: GoodsItemsIndex,
  },
  {
    path: '/goods/create',
    title: '상품 등록',
    isMenu: true,
    screen: GoodsItemsCreate,
  },
  {
    path: '/goods/modify/:productId',
    title: '상품 등록/수정',
    screen: GoodsItemsCreate,
  },
  {
    path: '/goods/copy/:productId',
    title: '상품 복사',
    screen: GoodsItemsCreate,
  },
  {
    path: '/goods/detail/:productId',
    title: '상품 디테일',
    screen: GoodsItemsDetail,
  },
  {
    path: '/goods/delivery_template',
    title: '배송 템플릿 관리',
    isMenu: true,
    screen: GoodsDeliveryFee,
  },
  {
    path: '/goods/delivery_template/regist',
    title: '배송 템플릿',
    screen: ChargeTemplateCreate,
  },
  {
    path: '/goods/delivery_template/modify/:id',
    title: '배송 템플릿 수정',
    screen: ChargeTemplateCreate,
  },
  {
    path: '/advertise',
    title: '광고 신청',
    isMenu: true,
    icon: SmileTwoTone,
    screen: Advertise,
  },
  {
    path: '/advertise/create',
    title: '검색지역광고 등록',
    screen: CreateAdvertisement,
  },
  {
    path: '/review',
    title: '문의/리뷰관리',
    icon: MessageOutlined,
    isMenu: true,
  },
  {
    path: '/review/management',
    title: '리뷰관리',
    isMenu: true,
    screen: ReviewManagement,
  },
  {
    path: '/review/management/:reviewId',
    title: '리뷰 디테일',
    screen: ReviewDetail,
  },
  {
    path: '/review/store',
    title: '상점 문의',
    isMenu: true,
    screen: InquireStore,
  },
  {
    path: '/review/store/inquire/:inquireId',
    title: '상점 문의 디테일',
    screen: InquireStoreDetail,
  },

  {
    path: '/review/goods',
    title: '상품 문의',
    isMenu: true,
    screen: InquireGoods,
  },
  {
    path: '/review/goods/inquire/:inquireId',
    title: '상품 문의 디테일',
    screen: InquireGoodsDetail,
  },
  {
    path: '/store',
    title: '상점 관리',
    isMenu: true,
    icon: HomeOutlined,
  },
  {
    path: '/store/detail',
    title: '상점 상세 정보',
    isMenu: true,
    screen: StoreDetailsIndex,
  },
  {
    path: '/store/modify',
    title: '상점 수정',
    screen: StoreDetailsModify,
  },
  {
    path: '/store/account',
    title: '판매자 정보',
    isMenu: true,
    screen: StoreAccountInfoIndex,
  },
  {
    path: '/store/account/modify',
    title: '판매자 정보 수정',
    screen: StoreAccountInfoModify,
  },
  {
    path: '/store/auth',
    title: '판매권한 신청',
    isMenu: true,
    screen: StoreAuthIndex,
  },
  {
    path: '/notice',
    title: '공지사항',
    isMenu: true,
    icon: ShareAltOutlined,
    screen: Notice,
  },
  {
    path: '/notice/:noticeId',
    title: '공지사항 상세',
    screen: NoticeDetails,
  },
  {
    path: '/statistics',
    title: '통계',
    icon: BarChartOutlined,
    isMenu: true,
  },
  {
    path: '/statistics/daily_payment_amount',
    title: '일별 결제금액',
    isMenu: true,
    screen: DailyPaymentAmount,
  },
  {
    path: '/statistics/payers_payments_per_day',
    title: '일별 결제자수 및 결제수',
    isMenu: true,
    screen: PayersAndPaymentsPerDay,
  },
  {
    path: '/statistics/payment_amount_by_day_of_the_week',
    title: '요일별 결제금액',
    isMenu: true,
    screen: PaymentAmountByDayOfTheWeek,
  },
  {
    path: '/statistics/average_payment_amount_by_day_of_the_week',
    title: '요일별 평균 결제금액',
    isMenu: true,
    screen: AveragePaymentAmountByDayOfTheWeek,
  },
  {
    path: '/statistics/daily_refun_rate',
    title: '일별 환불율',
    isMenu: true,
    screen: DailyRefundRate,
  },
];

export const useRoutes = () => {
  const routes = useMemo(
    () =>
      pages
        ?.filter((v) => !!v.isMenu || !!v.isSubMenu)
        ?.reduce((output, page) => {
          const path = page.path?.substring(1);
          const parentPath = `/${path?.substring(0, path.indexOf('/'))}`;
          const parentIndex = path?.indexOf('/') >= 0 ? output.findIndex((v) => v.path === parentPath) : undefined;
          if (parentIndex === undefined) {
            output.push({
              path: page.path,
              title: page.title,
              icon: page?.icon,
            });
          } else {
            if (!output[parentIndex].children) output[parentIndex].children = [];
            output[parentIndex].children.push({
              path: page.path,
              title: page.title,
              group: page?.group,
            });
          }
          return output;
        }, []),
    [pages],
  );

  return { routes, pages, notLoginPages };
};

export default useRoutes;
